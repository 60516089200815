import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { setDefaultOptions } from "date-fns";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Locale } from "@/consts/locales";
import { useLocale } from "@/hooks/locale";
import { useSportApi } from "@/hooks/sport/use-sport-api";

const Root = () => {
  const { getAllSports } = useSportApi();
  const locale = useLocale();

  useEffect(() => {
    setDefaultOptions({ locale: Locale[locale] });
  }, [locale]);

  useEffect(() => {
    getAllSports();
  }, []);

  return (
    <main className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1 background-image">
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};

export default Root;
