import { Button } from "@/ui-kit/button";
import { FC } from "react";
import { Link } from "react-router-dom";

type EmptyListPlaceholderProps = {
  title: string;
  buttonTitle: string;
  link: string;
};

export const EmptyListPlaceholder: FC<EmptyListPlaceholderProps> = ({ title, buttonTitle, link }) => {
  return (
    <div className="w-full my-20 text-center">
      <p className="h5 text-black">{title}</p>
      <Button className="mt-8" variant="main" asChild>
        <Link to={link}>{buttonTitle}</Link>
      </Button>
    </div>
  );
};
