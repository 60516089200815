import { EventControllerApi, FavoriteControllerApi, Meta } from "@/api/generated";
import { addFavoriteEventFx, getFavoriteEventsFx, removeFavoriteEventFx } from '@/hooks/favorite/effects';
import { $httpClient } from '@/hooks/http-client/store';
import { $sports } from "@/hooks/sport/store";
import { RequestPayload } from '@/types/common';
import { EventListItemCard, EventSearchParams, EventSearchRequestParams } from "@/types/event";
import { SortablePageRequestParams } from '@/types/page';
import { createEvent, createStore, sample } from "effector";
import { createGate } from 'effector-react';
import { searchEventsFx } from "./effects";

const EVENT_LIST_INITIAL_STATE: EventListItemCard[] = [];
const EVENT_LIST_PAGE_META_INITIAL_STATE: Meta = { totalCount: 0, pageNumber: 1, pageSize: 10 };
const EVENT_SEARCH_PARAMS_INITIAL_STATE: EventSearchRequestParams = {
  sportIds: [],
  latitude: 0,
  longitude: 0,
};

const favoriteEventsGate = createGate('favoriteEventsGate');

const $events = createStore<EventListItemCard[]>(EVENT_LIST_INITIAL_STATE);
const $eventsPageMeta = createStore<Meta>(EVENT_LIST_PAGE_META_INITIAL_STATE);
const $eventsLoading = createStore<boolean>(false);
const $eventsSearchParams = createStore<EventSearchParams>(EVENT_SEARCH_PARAMS_INITIAL_STATE);

const setPageEvent = createEvent<number>();
const setPageSizeEvent = createEvent<number>();
const setSearchParams = createEvent<EventSearchParams>();
const resetPageMetaEvent = createEvent();

$eventsPageMeta.on(setPageEvent, (state, pageNumber) => ({ ...state, pageNumber }));
$eventsPageMeta.on(setPageSizeEvent, (state, pageSize) => ({ ...state, pageSize }));
$eventsPageMeta.on(resetPageMetaEvent, () => EVENT_LIST_PAGE_META_INITIAL_STATE);
$eventsSearchParams.on(setSearchParams, (_, params) => params);

$eventsLoading.on(searchEventsFx.pending, (_, pending) => pending);

sample({
  clock: searchEventsFx.doneData,
  source: $sports,
  fn: (sports, data) =>
    data.data.map((event) => ({
      ...event,
      sport: sports.get(event.sportId)?.name || "",
      isInFavorite: event.isInFavorite || false,
    })),
  target: $events,
});

sample({
  clock: searchEventsFx.doneData,
  fn: (data) => data.meta,
  target: $eventsPageMeta,
});

sample({
  clock: [addFavoriteEventFx.done, removeFavoriteEventFx.done],
  source: { events: $events, onFavoritePage: favoriteEventsGate.status },
  filter: ({ onFavoritePage }) => !onFavoritePage,
  fn: ({ events }, { params }) =>
    events.map(
      (event) => event.id === params.payload.eventId ?
        { ...event, isInFavorite: !event.isInFavorite } :
        event),
  target: $events,
})

sample({
  clock: getFavoriteEventsFx.doneData,
  source: $sports,
  fn: (sports, data) =>
    data.map((event) => ({
      ...event,
      sport: sports.get(event.sportId)?.name || "",
      isInFavorite: true,
    })),
  target: $events,
});

// this part outplayed me, think how to make it better
sample({
  clock: [
    setPageEvent,
    setPageSizeEvent,
    setSearchParams,
  ],
  source: { searchParams: $eventsSearchParams, pageMeta: $eventsPageMeta, httpClient: $httpClient, onFavoritePage: favoriteEventsGate.status },
  filter: ({ onFavoritePage }) => !onFavoritePage,
  fn: ({ searchParams, pageMeta, httpClient }) => {
    const params: EventSearchRequestParams = {
      latitude: searchParams.location?.latitude,
      longitude: searchParams.location?.longitude,
      sportIds: searchParams.sportIds,
      name: searchParams.name,
      distance: searchParams.distance,
      date: searchParams.date,
    };
    const controller = new EventControllerApi(undefined, undefined, httpClient);

    const requestPayload: RequestPayload<EventControllerApi, SortablePageRequestParams<EventSearchRequestParams>> = {
      controller, payload: { searchParams: params, page: pageMeta.pageNumber, size: pageMeta.pageSize, sort: [] }
    };

    return requestPayload;
  },
  target: searchEventsFx,
});

sample({
  clock: [
    setPageEvent,
    setPageSizeEvent,
    setSearchParams,
    addFavoriteEventFx.doneData,
    removeFavoriteEventFx.doneData
  ],
  source: { searchParams: $eventsSearchParams, pageMeta: $eventsPageMeta, httpClient: $httpClient, onFavoritePage: favoriteEventsGate.status },
  filter: ({ onFavoritePage }) => onFavoritePage,
  fn: ({ pageMeta, httpClient }) => {
    const controller = new FavoriteControllerApi(undefined, undefined, httpClient);

    const requestPayload: RequestPayload<FavoriteControllerApi, SortablePageRequestParams<void>> = {
      controller, payload: { page: pageMeta.pageNumber, size: pageMeta.pageSize, sort: [], searchParams: undefined }
    };

    return requestPayload;
  },
  target: getFavoriteEventsFx,
});

export {
  $events,
  $eventsLoading,
  $eventsPageMeta,
  $eventsSearchParams,
  favoriteEventsGate,
  resetPageMetaEvent,
  setPageEvent,
  setPageSizeEvent,
  setSearchParams
};

