import { AthletesList } from "@/components/list/athletes-list";
import { EmptyListPlaceholder } from "@/components/list/empty-list-placeholder";
import { Pagination } from "@/components/pagination";
import {
  useAthletes,
  useAthletesPageMeta,
  useAthletesSetPageMeta,
  useFavoriteAthletesGate,
} from "@/hooks/athlete/list";
import { useResetAthletePageMeta } from "@/hooks/athlete/list/use-reset-page-meta";
import { useFavoriteApi } from "@/hooks/favorite";
import { useEffect } from "react";

export const FavoriteAthletes = () => {
  const { data, isLoading } = useAthletes();
  const { getFavoriteAthletes } = useFavoriteApi();
  const { pageNumber, pagesNum, pageSize } = useAthletesPageMeta();
  const { setPage } = useAthletesSetPageMeta();
  const resetPageMeta = useResetAthletePageMeta();
  const isNoData = data.length === 0 && pageNumber === 1;

  useFavoriteAthletesGate();

  useEffect(() => {
    getFavoriteAthletes({ page: pageNumber, size: pageSize, searchParams: undefined });

    return () => resetPageMeta();
  }, []);

  return (
    <>
      {isNoData ? (
        <EmptyListPlaceholder title="В избранном пусто" buttonTitle="Добавить" link="/athletes" />
      ) : (
        <AthletesList data={data} isLoading={isLoading} />
      )}
      {pagesNum > 1 && <Pagination total={pagesNum} onChange={setPage} current={pageNumber} />}
    </>
  );
};
