import { SportControllerApi } from "@/api/generated";
import { useUnit } from "effector-react";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { getAllSportsFx } from "./effects";

export const useSportApi = () => {
  const httpClient = useHttpClient();
  const controller = new SportControllerApi(undefined, undefined, httpClient);

  const getAllSportsEffect = useUnit(getAllSportsFx);

  const getAllSports = async () => await getAllSportsEffect({ controller });

  return {
    getAllSports,
  };
};
