import { SPORT_LABEL } from "@/consts/sports";
import { Dictionary } from "@/types/dictionary";
import { SportLabelKey } from "@/types/sport";
import { createStore } from "effector";
import { getAllSportsFx } from "./effects";

const $sports = createStore<Dictionary>(new Map());
const $sportsLoading = createStore<boolean>(false);

$sports.on(getAllSportsFx.doneData, (_, sports) => {
  const sportsMap = new Map(
    sports.map((sport) => [
      sport.id,
      { label: SPORT_LABEL[sport.name as SportLabelKey], name: sport.name, value: sport.id },
    ]),
  );

  return sportsMap;
});

$sportsLoading.on(getAllSportsFx, () => true);
$sportsLoading.on(getAllSportsFx.done, () => false);

export { $sports, $sportsLoading };
