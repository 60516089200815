import { AccountControllerApi } from "@/api/generated";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { Athlete } from "@/types/athlete";
import { LoginFormData } from "@/types/login";
import { Organization } from "@/types/organization";
import { RegisterFormData } from "@/types/register";
import { useUnit } from "effector-react";
import { checkEmailFx, loginFx, logoutFx, meFx, registerAthleteFx, registerOrganizationFx } from "./effects";
import { useAccount } from "./use-account";
import { useSetAccount } from "./use-set-account";

export const useAccountApi = () => {
  const httpClient = useHttpClient();
  const controller = new AccountControllerApi(undefined, undefined, httpClient);

  const { data: account } = useAccount();
  const setAccount = useSetAccount();
  const loginEffect = useUnit(loginFx);
  const logoutEffect = useUnit(logoutFx);
  const checkEmailEffect = useUnit(checkEmailFx);
  const registerAthleteEffect = useUnit(registerAthleteFx);
  const registerOrganizationEffect = useUnit(registerOrganizationFx);
  const meEffect = useUnit(meFx);

  const login = async (values: LoginFormData) => await loginEffect({ controller, payload: values });

  const logout = async () => await logoutEffect();

  const alreadyExist = async (values: RegisterFormData) => {
    const alreadyExist = await checkEmailEffect({ controller, payload: { email: values.email } });

    setAccount({ ...account, ...values });

    return alreadyExist;
  };

  const registerAthlete = async (athleteData: Athlete & RegisterFormData) =>
    await registerAthleteEffect({
      controller,
      payload: {
        ...athleteData,
        avatar: {
          data: athleteData.avatar,
        }
      },
    });

  const registerOrganization = async (athleteData: Organization & RegisterFormData) =>
    await registerOrganizationEffect({
      controller,
      payload: {
        ...athleteData,
        avatar: {
          data: athleteData.avatar,
        }
      },
    });

  const me = async () => await meEffect({ controller });

  return {
    login,
    logout,
    alreadyExist,
    registerAthlete,
    registerOrganization,
    me,
  };
};
