import { RHFSelect } from '@/components/rhf/select';
import { ComponentProps } from 'react';

const FORMAT_OPTIONS: ComponentProps<typeof RHFSelect>["options"] = [
    { value: "online", label: "Онлайн" },
    { value: "offline", label: "Оффлайн" },
];

const TRAINING_OPTIONS: ComponentProps<typeof RHFSelect>["options"] = [
    { value: "training", label: "Тренировка" },
    { value: "run", label: "Гонка" },
];

export { FORMAT_OPTIONS, TRAINING_OPTIONS };
