import { AthleteSummary, AthleteTeamControllerApi, GetAvatarCategoryEnum } from "@/api/generated";
import { getAvatarByCategoryAndIdUrl } from "@/hooks/avatar";
import { AthleteRequestParams } from "@/types/athlete";
import { WithAvatar } from "@/types/avatar";
import { RequestPayload } from "@/types/common";
import { SortablePageRequestParams } from '@/types/page';
import { createEffect } from "effector";

type AddAthleteToTeam = (params: RequestPayload<AthleteTeamControllerApi, AthleteRequestParams>) => Promise<void>;
type RemoveAthleteFromTeam = (params: RequestPayload<AthleteTeamControllerApi, AthleteRequestParams>) => Promise<void>;
type GetAthleteTeam = (params: RequestPayload<AthleteTeamControllerApi, SortablePageRequestParams<void>>) => Promise<WithAvatar<AthleteSummary>[]>;

const addAthleteToTeamFx = createEffect<AddAthleteToTeam>(async ({ controller, payload }) => {
  try {
    const response = await controller.addAthleteToTeam(payload.athleteId);

    return response.data;
  } catch (error) {
    console.log("Error in addAthleteToTeamFx", error);
    throw error;
  }
});

const removeAthleteFromTeamFx = createEffect<RemoveAthleteFromTeam>(async ({ controller, payload }) => {
  try {
    const response = await controller.deleteAthleteFromTeam(payload.athleteId);

    return response.data;
  } catch (error) {
    console.log("Error in removeAthleteFromTeamFx", error);
    throw error;
  }
});

const getAthleteTeamFx = createEffect<GetAthleteTeam>(async ({ controller, payload }) => {
  try {
    const response = await controller.getAthleteTeam(
      payload.page,
      payload.size,
      payload.sort
    );

    const dataWithAvatar = await Promise.all(
      response.data.map(async (athlete) => ({
        ...athlete,
        avatar: await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
      })),
    );

    return dataWithAvatar;
  } catch (error) {
    console.log("Error in getAthleteTeamFx", error);
    throw error;
  }
});

export { addAthleteToTeamFx, getAthleteTeamFx, removeAthleteFromTeamFx };
