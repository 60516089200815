import { Card, CardContent } from "@/ui-kit/card";
import { EventsSearchWithFiltersForm } from "@/components/forms/events-search-with-filters";

export const SearchWithFiltersCard = () => {
  return (
    <Card className="transition-all">
      <CardContent>
        <EventsSearchWithFiltersForm />
      </CardContent>
    </Card>
  );
};
