import { OrganizationModerationStatusEnum, OrganizationRoleEnum } from "@/api/generated";
import { OrganizationSummaryCard } from "@/types/organization";
import { createStore, sample } from "effector";
import { $sports } from "../sport/store";
import { getOrganizationFx, getOrganizationMeFx } from "./effects";

const ORGANIZATION_INITIAL_STATE: OrganizationSummaryCard = {
  id: 0,
  name: "",
  avatar: "",
  description: "",
  role: OrganizationRoleEnum.Organization,
  location: {
    name: "",
    position: {
      latitude: 0,
      longitude: 0,
    },
  },
  email: "",
  emailConfirmed: false,
  rating: 0,
  sports: [],
  moderationStatus: OrganizationModerationStatusEnum.Open
};

const $organization = createStore<OrganizationSummaryCard>(ORGANIZATION_INITIAL_STATE);
const $organizationLoading = createStore<boolean>(false);

$organizationLoading.on(getOrganizationFx, () => true);
$organizationLoading.on(getOrganizationFx.done, () => false);

// TODO add avatar
sample({
  clock: getOrganizationMeFx.doneData,
  source: $sports,
  fn: (sports, data) =>
    ({
      ...data,
      sports: data.sportIds.map((sportId) => sports.get(sportId)?.name || ""),
      role: OrganizationRoleEnum.Organization,
      rating: 4,
      achievements: ["Bronze", "Silver", "Gold", "Regular"],
      avatar: "",
    }) as OrganizationSummaryCard,
});

export { $organization, $organizationLoading };
