import { EventModerationStatusEnum } from "@/api/generated";
import { DATE_FORMAT } from "@/consts/date";
import { EventSummaryCard } from "@/types/event";
import { formatDate } from "date-fns";
import { createStore, sample } from "effector";
import { $sports } from "../sport/store";
import { deleteEventFx, getEventFx } from "./effects";

const EVENT_INITIAL_STATE: EventSummaryCard = {
  id: 0,
  avatar: "",
  time: "",
  formattedDate: "",
  sport: "",
  sportId: 0,
  location: {
    name: "",
    position: {
      latitude: 0,
      longitude: 0,
    },
  },
  name: "",
  date: "",
  isPublic: false,
  moderationStatus: EventModerationStatusEnum.Open,
};

const $event = createStore<EventSummaryCard>(EVENT_INITIAL_STATE);
const $eventLoading = createStore<boolean>(false);

$eventLoading.on(getEventFx.pending, (_, pending) => pending);
$eventLoading.on(deleteEventFx.pending, (_, pending) => pending);

sample({
  clock: getEventFx.doneData,
  source: $sports,
  fn: (sports, data) => {
    const date = new Date(data.date);

    return {
      ...data,
      formattedDate: formatDate(date, DATE_FORMAT),
      time: formatDate(date, "HH:mm"),
      sport: sports.get(data.sportId)?.name || "",
    };
  },
  target: $event,
});

sample({
  clock: deleteEventFx.doneData,
  fn: () => EVENT_INITIAL_STATE,
  target: $event,
})

export { $event, $eventLoading };
