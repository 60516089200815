import { Carousel, CarouselContent, CarouselDots, CarouselItem } from "@/ui-kit/carousel";
import { ReviewCard } from "@/components/card/review-card";
import { FC } from "react";

type Review = {
  title: string;
  review: string;
};

type ReviewCardProps = {
  data: Review[];
  color?: "green" | "blue";
};

export const ReviewCarousel: FC<ReviewCardProps> = ({ data, color }: ReviewCardProps) => {
  console.log("COLOR: ", color);
  return (
    <Carousel className="mt-4 mb-4 relative w-full">
      <CarouselContent>
        {data.map((review, index) => (
          <CarouselItem key={index} className="basis-full lg:basis-1/2">
            <ReviewCard title={review.title} review={review.review} color={color} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots color={color} />
    </Carousel>
  );
};
