import * as React from "react";
import { format, formatISO, Locale } from "date-fns";

import { cn } from "@/utils/tailwind";
import { Calendar } from "@/ui-kit/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/ui-kit/popover";
import { Input } from "@/ui-kit/input";
import { ChangeEventHandler, FC, useEffect, useState } from "react";
import { CloseIconSmall } from "@/icons/close-small";
import { Button } from "@/ui-kit/button";
import { UTCDate } from "@date-fns/utc";

type DatePickerProps = {
  placeholder?: string;
  triggerClassName?: string;
  defaultValue?: string;
  locale?: Locale;
  onChange?: (value?: string) => void;
};

export const DatePicker: FC<DatePickerProps> = ({ placeholder, triggerClassName, defaultValue, locale, onChange }) => {
  const [date, setDate] = useState<Date>();
  const [timeValue, setTimeValue] = React.useState<string>("00:00");

  useEffect(() => {
    if (defaultValue) {
      const defaultDate = new Date(defaultValue);
      const time = format(defaultDate, "HH:mm");

      setDate(defaultDate);
      setTimeValue(time);
    }
  }, [defaultValue]);

  const handleDaySelect: (date?: Date) => void = (date: Date | undefined) => {
    if (timeValue && date) {
      const [hours, minutes] = timeValue.split(":").map((str) => parseInt(str, 10));

      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
      setDate(newDate);
      onChange?.(formatISO(new UTCDate(newDate)));
    } else {
      setDate(date);
      onChange?.(date ? formatISO(new UTCDate(date)) : date);
    }
  };

  const handleTimeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const time = event.target.value;
    if (date) {
      const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10));
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
      setDate(newDate);
      onChange?.(formatISO(new UTCDate(newDate)));
    } else {
      setTimeValue(time);
    }
  };

  return (
    <Popover>
      <PopoverTrigger className="w-full" asChild>
        <div className={cn("relative", triggerClassName)}>
          <Input
            placeholder={placeholder}
            className="text-left"
            defaultValue={date ? format(date, "PPP") : ""}
            readOnly
          />
          {date && (
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-2 top-1/2 -translate-y-1/2"
              onClick={(e) => {
                e.preventDefault();
                handleDaySelect();
              }}
            >
              <CloseIconSmall />
            </Button>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(date) => handleDaySelect(date)}
          initialFocus
          locale={locale}
        />
        <Input
          type="time"
          onChange={handleTimeChange}
          defaultValue={timeValue}
          className="border-none block px-[1.25rem] py-4"
        />
      </PopoverContent>
    </Popover>
  );
};
