import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";
import { AthletesList } from "@/components/list/athletes-list";
import { Pagination } from "@/components/pagination";
import { useAthleteApi, useAthletes, useAthletesPageMeta } from "@/hooks/athlete";
import { useAthletesSetPageMeta } from "@/hooks/athlete/list";
import { useEffect } from "react";

const Athletes = () => {
  const { data, isLoading } = useAthletes();
  const { getAthletes } = useAthleteApi();
  const { totalCount, pageNumber, pagesNum, pageSize } = useAthletesPageMeta();
  const { setPage } = useAthletesSetPageMeta();

  useEffect(() => {
    getAthletes({ page: pageNumber, size: pageSize, searchParams: {} });
  }, []);

  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <div className="content-padding-64 md:content-padding-56 mt-12">
        <AthletesList title="Спортсмены" total={totalCount} data={data} isLoading={isLoading} />
        {pagesNum > 1 && <Pagination total={pagesNum} onChange={setPage} current={pageNumber} />}
      </div>
    </div>
  );
};

export default Athletes;
