import { Checkbox } from "@/ui-kit/checkbox";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { FC, forwardRef, useId } from "react";

export type CheckboxWithTextProps = CheckboxPrimitive.CheckboxProps;

export const CheckboxWithText: FC<CheckboxWithTextProps> = forwardRef<HTMLButtonElement, CheckboxWithTextProps>(
  ({ children, onCheckedChange, ...other }, ref) => {
    const id = useId();

    return (
      <div className="justify-start items-center flex space-x-2 my-2">
        <Checkbox id={id} onCheckedChange={onCheckedChange} ref={ref} {...other} />
        <div className="flex mx-2 leading-none">
          <label htmlFor={id} className="ft1 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            {children}
          </label>
        </div>
      </div>
    );
  },
);
