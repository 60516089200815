import { EventControllerApi, GetAvatarCategoryEnum } from "@/api/generated";
import { getAvatarByCategoryAndIdUrl } from "@/hooks/avatar";
import { RequestPayload } from "@/types/common";
import { EventSearchRequestParams, PaginatedResultEventSummaryWithAvatar } from "@/types/event";
import { SortablePageRequestParams } from "@/types/page";
import { createEffect } from "effector";

type SearchEvents = (
  params: RequestPayload<EventControllerApi, SortablePageRequestParams<EventSearchRequestParams>>,
) => Promise<PaginatedResultEventSummaryWithAvatar>;

const searchEventsFx = createEffect<SearchEvents>(async ({ controller, payload }) => {
  try {
    const response = await controller.searchEvents(
      payload.page,
      payload.size,
      payload.sort,
      payload.searchParams.name,
      payload.searchParams.latitude,
      payload.searchParams.longitude,
      payload.searchParams.sportIds,
      payload.searchParams.date,
      payload.searchParams.distance,
    );

    console.log('response>: ', response)

    const dataWithAvatar = await Promise.all(
      response.data.data.map(async (event) => ({
        ...event,
        avatar: await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, event.id),
      })),
    );

    return {
      ...response.data,
      data: dataWithAvatar,
    };
  } catch (error) {
    console.log("Error in getAthletesFx", error);
    throw error;
  }
});

export { searchEventsFx };
