import { AthletesList } from "@/components/list/athletes-list";
import { EmptyListPlaceholder } from "@/components/list/empty-list-placeholder";
import { Pagination } from "@/components/pagination";
import { useAthletes, useAthletesPageMeta } from "@/hooks/athlete";
import { useAthletesSetPageMeta } from "@/hooks/athlete/list";
import { useResetAthletePageMeta } from "@/hooks/athlete/list/use-reset-page-meta";
import { useAthleteTeamApi } from "@/hooks/athlete/team";
import { useEffect } from "react";

export const MyTeam = () => {
  const { data, isLoading } = useAthletes();
  const { getAthleteTeam } = useAthleteTeamApi();
  const { pageNumber, pagesNum, pageSize } = useAthletesPageMeta();
  const { setPage } = useAthletesSetPageMeta();
  const resetPageMeta = useResetAthletePageMeta();
  const isNoData = data.length === 0 && pageNumber === 1;

  useEffect(() => {
    getAthleteTeam({ page: pageNumber, size: pageSize, searchParams: undefined });

    return () => resetPageMeta();
  }, []);

  return (
    <>
      {isNoData ? (
        <EmptyListPlaceholder title="Вы не состоите ни в одной команде" buttonTitle="Поиск друзей" link="/athletes" />
      ) : (
        <AthletesList data={data} isLoading={isLoading} />
      )}
      {pagesNum > 1 && <Pagination total={pagesNum} onChange={setPage} current={pageNumber} />}
    </>
  );
};
