import { EditEventForm } from "@/components/forms/edit-event";
import { EventSummaryCard } from "@/types/event";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { Skeleton } from "@/ui-kit/skeleton";
import { FC, useMemo } from "react";

type EditEventCardProps = {
  data: EventSummaryCard;
  isLoading: boolean;
};

export const EditEventCard: FC<EditEventCardProps> = ({ data, isLoading }) => {
  const external = useMemo(() => Boolean(data?.externalEventUrl || data?.externalRegistrationUrl), [data]);

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать событие</CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="flex flex-col">
            {external && (
              <>
                <Skeleton className="w-full h-10 mb-2" />
                <Skeleton className="w-full h-10 mb-2" />
              </>
            )}
            <Skeleton className="h-24 w-20 m-auto" />
            <div className="flex gap-4 mt-2">
              <Skeleton className="flex-1 h-10" />
              <Skeleton className="flex-1 h-10" />
            </div>
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
          </div>
        ) : (
          <EditEventForm external={external} defaultValues={{ ...data, location: data.location.position }} />
        )}
      </CardContent>
    </Card>
  );
};
