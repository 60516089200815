import TrainerImage from "@/images/trainer.png";
import AthleteImage from "@/images/athlete.png";
import OrganizerImage from "@/images/organizer.png";
import { AccountRoleCard } from "./account-role-card";
import { AccountRole } from "@/types/account-role";
import { useAccountRole } from "@/hooks/account";

const pros = ["Приемущества", "Приемущества", "Приемущества", "Приемущества"];

export const RoleProfileEditCard = () => {
  const accountRole = useAccountRole();
  const onClickHandler = (name: AccountRole) => {
    console.log(name);
  };

  return (
    <div className="flex flex-col">
      <div className="text-white py-10 m-auto">
        <h2 className="h2">Тип пользователя:</h2>
      </div>
      <div className="flex gap-8 flex-wrap mb-32">
        <AccountRoleCard
          primary={accountRole === AccountRole.TRAINER}
          title="Тренер"
          image={TrainerImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.TRAINER)}
        />
        <AccountRoleCard
          primary={accountRole === AccountRole.ATHLETE}
          title="Спортсмен"
          image={AthleteImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.ATHLETE)}
        />
        <AccountRoleCard
          primary={accountRole === AccountRole.ORGANIZATION}
          title="Организатор"
          image={OrganizerImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.ORGANIZATION)}
        />
      </div>
    </div>
  );
};
