import { useAccountExtended } from "@/hooks/account";
import { AthleteSummaryCard } from "@/types/athlete";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { EditPrivacyProfileForm } from "../forms/edit-privacy-profile-form";

export const PrivacyProfileEditCard = () => {
  const { data: account } = useAccountExtended();

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] my-8 mx-auto rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать профиль</CardTitle>
      </CardHeader>
      <CardContent>
        {account ? (
          <EditPrivacyProfileForm defaultValues={account as AthleteSummaryCard} />
        ) : (
          <div>Данные не загружены</div>
        )}
      </CardContent>
    </Card>
  );
};
