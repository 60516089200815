import {
  AccountControllerApi,
  AccountLike,
  Athlete,
  CreateAthleteRequest,
  CreateOrganizationRequest,
  EmailExistsRequest,
  LoginRequest,
  LoginResponse,
  Organization,
} from "@/api/generated";
import { RequestBase, RequestPayload } from "@/types/common";
import { createEffect } from "effector";

type Login = (params: RequestPayload<AccountControllerApi, LoginRequest>) => Promise<LoginResponse["accessToken"]>;
type Logout = () => void;
type CheckEmail = (params: RequestPayload<AccountControllerApi, EmailExistsRequest>) => Promise<boolean>;
type RegisterAthlete = (params: RequestPayload<AccountControllerApi, CreateAthleteRequest>) => Promise<Athlete>;
type RegisterOrganization = (
  params: RequestPayload<AccountControllerApi, CreateOrganizationRequest>,
) => Promise<Organization>;
type Me = (params: RequestBase<AccountControllerApi>) => Promise<AccountLike>;

const loginFx = createEffect<Login>(async ({ controller, payload }) => {
  try {
    const response = await controller.login(payload);
    const { accessToken } = response.data;

    if (!accessToken) {
      throw new Error("Неверный логин или пароль");
    }

    localStorage.setItem("accessToken", accessToken);

    return response.data.accessToken;
  } catch (error) {
    console.log("Error in loginFx", error);
    throw error;
  }
});

const logoutFx = createEffect<Logout>(() => {
  localStorage.removeItem("accessToken");
});

const checkEmailFx = createEffect<CheckEmail>(async ({ controller, payload }) => {
  try {
    const response = await controller.existsByEmail({ email: payload.email });

    return response.data;
  } catch (error) {
    console.log("Error in checkEmailFx", error);
    throw error;
  }
});

const registerAthleteFx = createEffect<RegisterAthlete>(async ({ controller, payload }) => {
  try {
    const response = await controller.registerAthlete(payload);

    return response.data;
  } catch (error) {
    console.log("Error in checkEmailFx", error);
    throw error;
  }
});

const registerOrganizationFx = createEffect<RegisterOrganization>(async ({ controller, payload }) => {
  try {
    const response = await controller.registerOrganization(payload);
    return response.data;
  } catch (error) {
    console.log("Error in checkEmailFx", error);
    throw error;
  }
});

const meFx = createEffect<Me>(async ({ controller }) => {
  try {
    const response = await controller.accountMe();
    return response.data;
  } catch (error) {
    console.log("Error in meFx", error);
    throw error;
  }
});

export { checkEmailFx, loginFx, logoutFx, meFx, registerAthleteFx, registerOrganizationFx };
