import { Dropdown } from "@/components/dropdown";
import { Input } from "@/components/rhf/input";
import { LocationInput } from "@/components/rhf/location-input";
import { useLocationApi } from "@/hooks/location/use-location-api";
import { useLocationName } from "@/hooks/location/use-location-name";
import { useOrganizationApi } from "@/hooks/organization/use-organization-api";
import { useSports } from "@/hooks/sport/use-sports";
import { organizationSchema } from "@/schemas/organization.schema";
import { Organization, OrganizationSummaryCard } from "@/types/organization";
import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";

type EditOrganizationProfileAboutFormProps = {
  defaultValues?: OrganizationSummaryCard;
};

export const EditOrganizationAboutProfileForm: FC<EditOrganizationProfileAboutFormProps> = ({ defaultValues }) => {
  const { updateOrganization } = useOrganizationApi();
  const { getLocationName } = useLocationApi();

  const form = useForm<Organization>({
    resolver: zodResolver(organizationSchema),
    defaultValues: {
      ...defaultValues,
      location: defaultValues?.location.position,
    },
  });
  const sportDictionary = useSports();

  const location = form.watch("location");

  useEffect(() => {
    if (location) {
      getLocationName(location);
    }
  }, [location]);

  const { data: locationName, isLoading } = useLocationName();

  const onSubmit = async (values: Organization) => {
    try {
      updateOrganization({
        ...defaultValues,
        ...values,
      });
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  console.log("sportDictionary: ", sportDictionary);

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Dropdown
          name="sports"
          isMulti
          placeholder="Вид спорта (добавить еще)"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        <LocationInput
          name="location"
          valueLabel={locationName}
          isLoading={isLoading}
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
        />
        <Input name="description" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="О себе" />
        {form.formState.errors.root && (
          <FormMessage className="self-center">{form.formState.errors.root.message}</FormMessage>
        )}
        <div className="flex justify-center gap-2.5 py-4">
          <Button type="submit">Сохранить</Button>
          <Button variant="outlineMain" type="reset">
            Отмена
          </Button>
        </div>
      </form>
    </Form>
  );
};
