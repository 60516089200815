import { AthleteControllerApi } from "@/api/generated";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { Athlete, AthleteSearchParams, AthleteSearchRequestParams } from "@/types/athlete";
import { SortablePageRequestParams } from "@/types/page";
import { useUnit } from "effector-react";
import { getAthleteFx, getAthleteMeFx, updateAthleteFx } from "./effects";
import { searchAthletesFx } from "./list/effects";

export const useAthleteApi = () => {
  const httpClient = useHttpClient();
  const controller = new AthleteControllerApi(undefined, undefined, httpClient);

  const getAthleteEffect = useUnit(getAthleteFx);
  const getAthleteMeEffect = useUnit(getAthleteMeFx);
  const updateAthleteEffect = useUnit(updateAthleteFx);
  const searchAthletesEffect = useUnit(searchAthletesFx);

  const getAthlete = async (athleteId: number) => await getAthleteEffect({ controller, payload: { athleteId } });

  const getAthleteMe = async () => await getAthleteMeEffect({ controller });

  const updateAthlete = async (athleteData: Athlete) =>
    await updateAthleteEffect({
      controller,
      payload: {
        ...athleteData,
        avatar: {
          ...(athleteData.avatar ? { data: athleteData.avatar } : {}),
          remove: athleteData.avatar === null
        }
      }
    });

  const getAthletes = async (params: SortablePageRequestParams<AthleteSearchParams>) => {
    const payload: AthleteSearchRequestParams = {
      latitude: params.searchParams.location?.latitude,
      longitude: params.searchParams.location?.longitude,
      sportIds: params.searchParams.sportIds,
      name: params.searchParams.name,
    };

    return await searchAthletesEffect({
      controller,
      payload: { page: params.page, size: params.size, sort: params.sort, searchParams: payload },
    });
  };

  return {
    getAthlete,
    getAthleteMe,
    updateAthlete,
    getAthletes,
  };
};
