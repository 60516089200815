import { AthleteTeamControllerApi } from "@/api/generated";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { SortablePageRequestParams } from '@/types/page';
import { useUnit } from "effector-react";
import { addAthleteToTeamFx, getAthleteTeamFx, removeAthleteFromTeamFx } from "./effects";

export const useAthleteTeamApi = () => {
  const httpClient = useHttpClient();
  const controller = new AthleteTeamControllerApi(undefined, undefined, httpClient);

  const addAthleteToTeamEffect = useUnit(addAthleteToTeamFx);
  const removeAthleteFromTeamEffect = useUnit(removeAthleteFromTeamFx);
  const getAthleteTeamEffect = useUnit(getAthleteTeamFx);

  const addAthleteToTeam = async (athleteId: number) =>
    await addAthleteToTeamEffect({ controller, payload: { athleteId } });

  const removeAthleteFromTeam = async (athleteId: number) =>
    await removeAthleteFromTeamEffect({ controller, payload: { athleteId } });

  const getAthleteTeam = async (params: SortablePageRequestParams<void>) => await getAthleteTeamEffect({ controller, payload: params });

  return {
    addAthleteToTeam,
    removeAthleteFromTeam,
    getAthleteTeam,
  };
};
