import { Badge } from "@/ui-kit/badge";
import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { FC } from "react";

type AtheleteSummaryAboutProps = {
  isCoach: boolean;
  description?: string;
  tags?: string[];
};

export const AthleteSummaryAbout: FC<AtheleteSummaryAboutProps> = ({ isCoach, description, tags }) => {
  const tagsList = tags?.map((tag, index) => <span key={`${tag}${index}`}>#{tag}</span>);

  const isLongDescription = (description?.length || 0) > 200;

  return (
    <div className="flex flex-col gap-2 mt-8 md:mt-0 ">
      <h6 className="h6">О себе</h6>
      <Badge variant="secondary">{isCoach ? "тренер" : "cпортсмен"}</Badge>
      {/* Move to separate component */}
      <p className="tt mt-2">
        {isLongDescription
          ? `${description?.slice(0, 200)}...`
          : description || "Спортсмен пока ничего не написал о себе"}
      </p>
      {tagsList && <p className="b2 flex flex-wrap gap-2">{tagsList}</p>}
      {isLongDescription && (
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="link" className="p-0 self-start">
              Развернуть
            </Button>
          </DialogTrigger>
          <DialogContent className="w-full md:w-[600px] md:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Описание спортсмена</DialogTitle>
              <DialogClose />
            </DialogHeader>
            <p className="tt">{description}</p>
            {tagsList && <p className="b2 flex flex-wrap gap-2">{tagsList}</p>}
            <DialogFooter>
              <DialogClose asChild>
                <Button>Закрыть</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
