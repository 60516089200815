import { cn } from "@/utils/tailwind";
import React from "react";

export interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
  direction?: "horizontal" | "vertical";
  lineClassName?: string;
  childrenWrapperClassName?: string;
}

const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  ({ className, children, direction = "horizontal", lineClassName, childrenWrapperClassName, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn("relative flex", direction === "horizontal" ? "py-4 flex" : "px-4 flex-col", className)}
        {...props}
      >
        <div className={cn("absolute inset-0 flex items-center", direction === "horizontal" ? "" : "flex-col")}>
          <div
            className={cn(
              "border-gray-200",
              direction === "horizontal" ? "w-full border-t pt-px" : "h-full border-l pl-px",
              lineClassName,
            )}
          ></div>
        </div>
        <div className="relative flex justify-center m-auto">
          <span
            className={cn(
              "bg-white px-4 ft1 text-gray-200 m-auto",
              direction === "horizontal" ? "" : "",
              childrenWrapperClassName,
            )}
          >
            {children}
          </span>
        </div>
      </div>
    );
  },
);
Divider.displayName = "Divider";

export { Divider };
