import React from "react";

export const SwimmingIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <circle cx="14" cy="14" r="13.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M20 12.4993C20 13.329 19.328 14 18.5 14C17.671 14 17 13.329 17 12.4993C17 11.6713 17.671 11 18.5 11C19.328 11 20 11.6713 20 12.4993Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 18C22 18 20.0393 17.1368 17.534 17.1368C15.0275 17.1368 12.8639 17.7669 10.0989 17.7669C6.7896 17.7669 5 17 5 17"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17L12.839 13.6336L12.2 12.2632C12.2 12.2632 12.0613 11.7035 11.5819 11.8583C11.1031 12.0123 8.7887 13.1617 8.40034 13.3059C7.4161 13.672 6.96267 12.679 7.91507 12.1422C9.14829 11.4454 11.4647 10.3387 11.8168 10.1565C12.262 9.92488 12.6808 9.8828 13.0579 10.5067C13.6055 11.4143 15.3082 13.9043 15.6709 14.5209C16.0336 15.1375 16.2298 15.6581 15.5363 16.3032"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
