import { Select } from "@/components/select";
import { ReactHookFormField } from "@/types/rhf";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui-kit/form";
import { ComponentProps, FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

interface RHFSelectProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "name">, ReactHookFormField {
  onValueChange?: (val: string) => void;
  options: ComponentProps<typeof Select>["options"];
}

export const RHFSelect: FC<RHFSelectProps> = ({
  name,
  disabled,
  itemClassName,
  label,
  placeholder = "",
  labelClassName,
  messageClassName,
  controlElementClassName,
  onValueChange,
  options,
}) => {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field: { onChange, name: _, value, ...other } }) => (
        <FormItem className={itemClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            <Select
              className={controlElementClassName}
              placeholder={placeholder}
              onValueChange={(val) => {
                onValueChange?.(val);
                onChange(val);
              }}
              options={options}
            />
          </FormControl>
          <FormMessage className={messageClassName} />
        </FormItem>
      )}
    />
  );
};
