import { EventModerationStatusEnum } from "@/api/generated";
import ButtonWithDialog from "@/components/button-with-dialog";
import { ReviewCarousel } from "@/components/carousel/review-carousel";
import { EventSummary, EventSummaryOnModeration } from "@/components/event-summary";
import dataMock from "@/consts/carousel-mock";
import { useAccount } from "@/hooks/account";
import { useEvent, useEventApi } from "@/hooks/event";
import { InstagramIcon } from "@/icons/instagram";
import { TelegramIcon } from "@/icons/telegram";
import { router } from "@/router";
import { EventSummaryCard } from "@/types/event";
import { Button } from "@/ui-kit/button";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

type EventOnModerationProps = {
  data: EventSummaryCard;
  isLoading: boolean;
  isUserRelated: boolean;
};

const EventOnModeration: FC<EventOnModerationProps> = ({ data, isLoading, isUserRelated }) => {
  const { deleteEvent } = useEventApi();

  const onDeleteHandler = async (eventId: number) => {
    await deleteEvent(eventId);
    router.navigate("/events");
  };

  return (
    <div className="bg-white w-full content-padding-64">
      <EventSummaryOnModeration data={data} isLoading={isLoading} isUserRalated={isUserRelated} />
      <section className="flex flex-col">
        <div className="mt-5 flex gap-4">
          <p className="tt">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, natus. Ipsa deleniti odio accusamus beatae
            fuga fugiat nihil quia sit repellendus eveniet fugit officia, nemo doloribus? Maiores ducimus perferendis
            quas?
          </p>
        </div>
        {isUserRelated && (
          <div className="my-8 text-center">
            <ButtonWithDialog
              buttonVariant="destructive"
              buttonContent="Удалить событие"
              dialogTitle="Вы уверены что хотите удалить событие?"
              dialogDescription="Это действие нельзя будет отменить"
              actionElement={[
                <Button variant="destructive" disabled={isLoading} onClick={() => onDeleteHandler(data.id)}>
                  Удалить событие
                </Button>,
              ]}
            />
          </div>
        )}
      </section>
    </div>
  );
};

const Event = () => {
  const { id } = useParams();
  const { data, isLoading } = useEvent();
  const { getEvent } = useEventApi();
  const { data: accountData, isLoading: accountLoading } = useAccount();
  const isModerationPassed = data.moderationStatus === EventModerationStatusEnum.Success;
  // const isUserRelatedEvent = accountData.id === data.creatorId;
  const isUserRelatedEvent = false;

  useEffect(() => {
    if (id) {
      getEvent(parseInt(id));
    }
  }, [id]);

  console.log("isLoading: ", isLoading);

  return isModerationPassed ? (
    <div className="bg-white w-full content-padding-64">
      <EventSummary data={data} isLoading={isLoading} />
      <section className="flex flex-col">
        <div className="mt-8">
          <Button variant="outline">Событие с сайта</Button>
        </div>
        <div className="mt-5 flex gap-4">
          <p className="tt">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, natus. Ipsa deleniti odio accusamus beatae
            fuga fugiat nihil quia sit repellendus eveniet fugit officia, nemo doloribus? Maiores ducimus perferendis
            quas?
          </p>
          <div className="flex flex-col items-center px-6">
            <span className="h5">Поделиться</span>
            <div className="flex gap-2">
              <InstagramIcon />
              <TelegramIcon />
            </div>
          </div>
        </div>
        <div className="my-8 flex flex-wrap gap-4">
          <Button>Пригласить</Button>
          <Button variant="outlineMain">Событие на карте</Button>
        </div>
      </section>
      {isModerationPassed && (
        <section className="flex flex-col my-14">
          <h4 className="h4">Отзывы</h4>
          <div className="flex flex-wrap lg:flex-nowrap">
            <ReviewCarousel data={dataMock} />
            <Button variant="main" className="my-auto mx-8 h-auto">
              Добавить отзыв
            </Button>
          </div>
        </section>
      )}
    </div>
  ) : (
    <EventOnModeration data={data} isLoading={isLoading} isUserRelated={isUserRelatedEvent} />
  );
};

export default Event;
