import { AthleteSummary } from "@/components/athlete-summary";
import { OrganizationSummary } from "@/components/organization-summary";
import { useAccountExtended } from "@/hooks/account";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { OrganizationSummaryCard } from "@/types/organization";

const ProfileMain = () => {
  const { data } = useAccountExtended();

  const isOrganization = data?.role === AccountRole.ORGANIZATION;

  return isOrganization ? (
    <OrganizationSummary isOwner data={data as OrganizationSummaryCard} />
  ) : (
    <AthleteSummary data={data as AthleteSummaryCard} />
  );
};

export default ProfileMain;
