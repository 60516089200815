import { Dropdown } from "@/components/dropdown";
import { Input } from "@/components/rhf/input";
import { LocationInput } from "@/components/rhf/location-input";
import { DEFAULT_LOCATION } from "@/consts/location";
import { useAccount } from "@/hooks/account";
import { useAccountApi } from "@/hooks/account/use-account-api";
import { useLocationApi } from "@/hooks/location/use-location-api";
import { useLocationName } from "@/hooks/location/use-location-name";
import { useSports } from "@/hooks/sport/use-sports";
import { Image } from "@/icons/image";
import { organizationSchema } from "@/schemas/organization.schema";
import { Organization } from "@/types/organization";
import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const CreateOrganizationForm = () => {
  const navigate = useNavigate();
  const { registerOrganization } = useAccountApi();
  const { getLocationName } = useLocationApi();
  const { data: account } = useAccount();
  const sportDictionary = useSports();
  const form = useForm<Organization>({
    resolver: zodResolver(organizationSchema),
    defaultValues: account,
  });

  const location = form.watch("location");

  useEffect(() => {
    if (location) {
      getLocationName(location);
    }
  }, [location]);

  const { data: locationName, isLoading } = useLocationName();

  const onSubmit = async (values: Organization) => {
    try {
      await registerOrganization({ ...account, ...values });
      return navigate("/login");
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  console.log("form errors: ", form.formState.errors);

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex relative">
          {form.getValues().avatar && (
            <Image
              src={form.getValues().avatar}
              className="absolute left-2/4 -translate-x-2/4 w-16 z-0 top-2/4 -translate-y-2/4"
              alt="Avatar"
            />
          )}
          <Input
            name="avatar"
            type="file"
            itemClassName="m-auto z-10 relative"
            labelClassName="font-bold"
            placeholder="Аватар"
            onValueChange={(file) => {
              console.log("avatar prop updated with: ", file);
            }}
          />
        </div>
        <Input name="name" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="Название" />
        <Dropdown
          name="sports"
          isMulti
          placeholder="Вид спорта"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        <LocationInput
          name="location"
          valueLabel={locationName}
          isLoading={isLoading}
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
          defaultMapCenterCoords={DEFAULT_LOCATION}
        />
        <Input name="description" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="О себе" />
        {form.formState.errors.root && (
          <FormMessage className="self-center">{form.formState.errors.root.message}</FormMessage>
        )}
        <div className="flex justify-center py-4">
          <Button type="submit">Зарегистрироваться</Button>
        </div>
      </form>
    </Form>
  );
};
