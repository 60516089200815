import { Checkbox } from "@/components/rhf/checkbox";
import { Input } from "@/components/rhf/input";
import { ERROR_MESSAGE } from "@/consts/errors";
import { useAccount } from "@/hooks/account";
import { useAccountApi } from "@/hooks/account/use-account-api";
import { LockIcon } from "@/icons/lock";
import { MessageIcon } from "@/icons/message";
import { registerSchema } from "@/schemas/register.schema";
import { RegisterFormData } from "@/types/register";
import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, HTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

interface RegisterFormProps extends HTMLAttributes<HTMLElement> {
  onSubmitCallback?: () => void;
  error?: string;
}

const RegisterForm: FC<RegisterFormProps> = ({ onSubmitCallback }) => {
  const { alreadyExist } = useAccountApi();
  const { data: account } = useAccount();
  const form = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
    defaultValues: account,
  });

  const onSubmit = async (values: RegisterFormData) => {
    const emailAlreadyExist = await alreadyExist(values);

    if (emailAlreadyExist) {
      form.setError("email", {
        message: ERROR_MESSAGE.emailAlreadyExist,
      });
      return;
    }

    onSubmitCallback && onSubmitCallback();
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          name="email"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Email"
          startAdornment={<MessageIcon className="text-primary" />}
        />
        <Input
          withVisibilityToggle
          name="password"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Введите пароль"
          startAdornment={<LockIcon className="text-primary" />}
        />
        <ul className="ft1 text-[10px] list-disc px-8">
          <li>8 и более символов</li>
          <li>Содержит как строчные, так и прописные буквы A-Z</li>
          <li>Содержит хотя бы одну цифру</li>
          <li>Содержит специальные символы</li>
        </ul>
        <div className="flex justify-between ft1">
          <Checkbox name="approved">
            <span>
              Даю согласие на обработку{" "}
              <Button variant="link" size="link" asChild>
                <Link to="/tos" className="underline">
                  Персональных данных
                </Link>
              </Button>
            </span>
          </Checkbox>
        </div>
        {form.formState.errors.root && (
          <FormMessage className="self-center">{form.formState.errors.root.message}</FormMessage>
        )}
        <div className="flex justify-center mt-2">
          <Button type="submit">Далее</Button>
        </div>
      </form>
    </Form>
  );
};

export default RegisterForm;
