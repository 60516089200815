import { Locale } from "@/consts/locales";
import { useLocale } from "@/hooks/locale";
import { ReactHookFormField } from "@/types/rhf";
import { DatePicker as UIDatePicker } from "@/ui-kit/date-picker";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui-kit/form";
import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

interface DatePickerProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "name">, ReactHookFormField {
  onValueChange?: (value?: string) => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  name,
  itemClassName,
  label,
  labelClassName,
  controlElementClassName,
  placeholder = "",
  disabled,
  messageClassName,
  onValueChange,
}) => {
  const { control } = useFormContext();
  const locale = useLocale();

  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field: { onChange, value, ...other } }) => (
        <FormItem className={itemClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            <UIDatePicker
              placeholder={placeholder}
              defaultValue={value}
              triggerClassName={controlElementClassName}
              locale={Locale[locale]}
              onChange={(date) => {
                console.log("date: ", date);
                onChange(date);
                onValueChange && onValueChange(date);
              }}
            />
          </FormControl>
          <FormMessage className={messageClassName} />
        </FormItem>
      )}
    />
  );
};

export type { DatePickerProps };
