import React from "react";

// hard to make it parametrised, for now left it as it is in disign
export const RunningShadowIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g filter="url(#filter0_d_762_209)">
        <circle cx="20" cy="20" r="14" fill="#FDFDFD" />
      </g>
      <path
        d="M23 13.4997C23 14.328 23.6713 15 24.4993 15C25.329 15 26 14.328 26 13.4997C26 12.672 25.329 12 24.4993 12C23.6713 12 23 12.672 23 13.4997Z"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6745 21L16.2539 23.0921C16.2539 23.0921 13.8856 23.26 13.0383 23.3104C12.39 23.349 12 23.5957 12 24.0237C12 24.4517 12.3283 24.6923 13.2631 24.7686C14.0511 24.8328 16.7443 25 16.7443 25C16.7474 25 16.7505 25 16.7526 25C17.0674 25 17.3971 24.83 17.6135 24.5046L19 22.4884"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5488 22.5956L20.923 20.8655L23.5948 16.7503C23.7477 16.5309 23.8542 15.9447 23.6264 15.797L22.1256 14.8283C22.021 14.742 21.8959 14.6758 21.7514 14.6454L18.7499 14.0179C18.5183 13.9718 18.2783 14.0153 18.0828 14.1419L15.6149 15.9747C15.2095 16.3122 14.8208 16.7342 15.0881 17.1138C15.2597 17.3592 15.8454 17.2195 16.1527 17.04L18.743 15.7173L20.4484 16.0745L18.1486 19.3607C17.6211 20.2387 17.4458 20.9159 18.3947 21.693L21.6631 23.7713L20.2966 27.015C19.7813 28.2038 19.8737 28.8134 20.2267 28.9571C20.5796 29.1009 21.0987 28.9356 21.8324 27.6421L23.9101 23.8243C24.1139 23.3847 23.9618 22.8684 23.5488 22.5956Z"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 17.9996L23.6203 19.4641C23.7356 19.7191 23.9977 19.9667 24.2797 20C24.2797 20 26.2497 19.9583 26.7874 19.9292C27.3525 19.8991 27.9855 19.7947 27.9994 19.414C28.0201 18.8683 27.4963 18.828 26.9875 18.7919L25.0003 18.6259L23.8225 16"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_762_209"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_209" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_209" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
);
