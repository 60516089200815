import { useUnit } from "effector-react";
import { setPageEvent, setPageSizeEvent } from "./store";

export const useEventsSetPageMeta = () => {
  const setPageSize = useUnit(setPageSizeEvent);
  const setPage = useUnit(setPageEvent);

  return {
    setPageSize,
    setPage,
  };
};
