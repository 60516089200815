import React from "react";

export const SkiingIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <circle cx="14" cy="14" r="13.25" stroke="#2F2F2F" strokeWidth="1.5" />
      <path
        d="M17 6.49933C17 7.32767 17.6713 8 18.4993 8C19.3283 8 20 7.32767 20 6.49933C20 5.672 19.3283 5 18.4993 5C17.6713 5 17 5.672 17 6.49933Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96262 18.1735C9.96262 18.1735 11.9188 15.9045 12.2668 15.4733C12.6143 15.042 12.5037 14.686 12.0317 14.3565C11.5748 14.0376 10.7979 13.6188 10.1109 13.1754C9.43114 12.7363 8.36778 11.3903 9.49066 9.96214C9.49066 9.96214 11.8731 6.59713 14.9227 7.04021C15.6477 7.14517 16.6984 7.67072 16.6984 8.67612C16.6984 9.68152 15.8105 11.6141 15.8105 11.6141C15.8105 11.6141 17.9814 12.788 18.4326 13.1258C19.3773 13.8323 19.1147 14.4704 17.8505 13.9737C15.7982 13.1686 14.5264 12.5567 14.3724 12.1247C14.1957 11.6284 14.3543 10.6012 14.3543 10.6012L12.3946 11.8608C13.1915 12.3857 13.8935 12.8523 14.097 13.0008C14.6385 13.3961 15.9273 14.4425 14.5701 15.9938C13.2414 17.5126 11.8178 19 11.8178 19"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 15L17.4097 22.7553C17.4097 22.7553 19.1002 23.5569 20 22.2583"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 11L7 8" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
);
