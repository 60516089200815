import { EventCard } from "@/components/card/event-card";
import { Select } from "@/components/select";
import { useFavoriteApi } from "@/hooks/favorite";
import { EventListItemCard } from "@/types/event";
import { ComponentProps, FC } from "react";
import { SkeletonList } from "./skeleton-list";

type EventListProps = {
  total?: number;
  data: EventListItemCard[];
  isLoading: boolean;
  filterOptions: ComponentProps<typeof Select>["options"];
  onFilterChange: (value: string) => void;
};

export const EventList: FC<EventListProps> = ({ total, data, isLoading, filterOptions, onFilterChange }) => {
  const { addFavoriteEvent, removeFavoriteEvent } = useFavoriteApi();

  const events = isLoading ? (
    <SkeletonList />
  ) : (
    data.map((event, index) => (
      <EventCard
        key={index}
        {...event}
        location={event.location.name || ""}
        buttonText="Подробнее"
        className="mx-1 mt-2"
        onLikeHandler={() =>
          event.isInFavorite ? removeFavoriteEvent({ eventId: event.id }) : addFavoriteEvent({ eventId: event.id })
        }
      />
    ))
  );

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          <h4 className="h4">События</h4>
          {total && <span className="ct text-gray-100 mb-[0.3rem]">Найдено: {total}</span>}
        </div>
        <Select
          placeholder="Фильтр"
          onValueChange={onFilterChange}
          defaultValue={filterOptions?.[0].value || ""}
          className="mt-2"
          options={filterOptions}
        />
      </div>
      <div className="flex flex-wrap my-8 min-h-96">{events}</div>
    </div>
  );
};
