import { useAccountExtended } from "@/hooks/account";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { OrganizationSummaryCard } from "@/types/organization";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { EditAthleteAboutProfileForm } from "../forms/edit-athlete-about-profile-form";
import { EditOrganizationAboutProfileForm } from "../forms/edit-organization-about-profile-form";

export const AboutProfileEditCard = () => {
  const { data: account, isLoading } = useAccountExtended();

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] my-8 mx-auto rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать профиль</CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div>Данные не загружены</div>
        ) : account.role === AccountRole.ORGANIZATION ? (
          <EditOrganizationAboutProfileForm defaultValues={account as OrganizationSummaryCard} />
        ) : (
          <EditAthleteAboutProfileForm defaultValues={account as AthleteSummaryCard} />
        )}
      </CardContent>
    </Card>
  );
};
