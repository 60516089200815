import {
  GetAvatarCategoryEnum,
  OrganizationControllerApi,
  Organization as ResponseOrganization,
} from "@/api/generated";
import { RequestBase, RequestPayload } from "@/types/common";
import { Organization, OrganizationRequestParams } from "@/types/organization";
import { createEffect } from "effector";
import { getAvatarByCategoryAndIdUrl } from "../avatar";
import { WithAvatar } from "@/types/avatar";

type GetOrganization = (
  params: RequestPayload<OrganizationControllerApi, OrganizationRequestParams>,
) => Promise<WithAvatar<ResponseOrganization>>;
type GetOrganizationMe = (params: RequestBase<OrganizationControllerApi>) => Promise<WithAvatar<ResponseOrganization>>;
type UpdateOrganization = (
  params: RequestPayload<OrganizationControllerApi, Organization>,
) => Promise<WithAvatar<ResponseOrganization>>;

const getOrganizationFx = createEffect<GetOrganization>(async ({ controller, payload }) => {
  try {
    const response = await controller.getOrganization(payload.organizationId);
    const avatar = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, response.data.id);

    return { ...response.data, avatar };
  } catch (error) {
    console.log("Error in getOrganizationFx", error);
    throw error;
  }
});

const getOrganizationMeFx = createEffect<GetOrganizationMe>(async ({ controller }) => {
  try {
    const response = await controller.organizationMe();
    const avatar = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, response.data.id);

    return { ...response.data, avatar };
  } catch (error) {
    console.log("Error in getOrganizationMeFx", error);
    throw error;
  }
});

const updateOrganizationFx = createEffect<UpdateOrganization>(async ({ controller, payload }) => {
  try {
    const response = await controller.updateOrganization({
      ...payload,
      avatar: {
        ...(payload.avatar ? { data: payload.avatar } : {}),
        remove: payload.avatar === null
      }
    });
    const avatar = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, response.data.id);

    return { ...response.data, avatar };
  } catch (error) {
    console.log("Error in updateOrganizationFx", error);
    throw error;
  }
});

export { getOrganizationFx, getOrganizationMeFx, updateOrganizationFx };
