import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAccountLoggedIn } from "./hooks/account";

const ProtectedRoute: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const loggedIn = useAccountLoggedIn();
  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children as ReactElement;
};

export default ProtectedRoute;
