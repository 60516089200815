import { ReactNode } from "react";
import { LazyLoadTypes } from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: "ondemand" as LazyLoadTypes,
  autoplay: true,
  autoplaySpeed: 50000,
  cssEase: "linear",
  arrows: false,
  dotsClass: "absolute !bottom-[10%] !right-[10%] !md:bottom-1/4 !md:right-1/4 !md:w-32",
  appendDots: (dots: ReactNode[]) => {
    const dotList = dots.map((dot, i) => (
      <div key={i} className="check">
        {dot}
      </div>
    ));
    return (
      <div>
        <ul className="flex gap-1"> {dotList} </ul>
      </div>
    );
  },
  customPaging: (i: number) => {
    return (
      <div className="w-3 h-3 transition-colors rounded-full border border-white bg-transparent [.slick-active_&]:bg-white">
        {" "}
      </div>
    );
  },
};

export { settings };
