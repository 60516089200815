import React from "react";

export const SearchIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g clipPath="url(#clip0_821_6136)">
        <path
          d="M10.3996 19.5C15.2597 19.5 19.1996 15.4985 19.1996 10.5625C19.1996 5.62645 15.2597 1.625 10.3996 1.625C5.5395 1.625 1.59961 5.62645 1.59961 10.5625C1.59961 15.4985 5.5395 19.5 10.3996 19.5Z"
          stroke="#858585"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4 24.375L16 17.875"
          stroke="#858585"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_821_6136">
          <rect width="24" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
);
