import React from "react";
import { Step, Stepper } from "@/ui-kit/stepper";
import { RegisterCard } from "@/components/card/register-card";
import { AccountRoleCards } from "@/components/card/account-role-cards";
import { AthleteOrOrganizationCard } from "@/components/card/athlete-or-organization-card";

const steps = [
  { id: "registerForm", content: <RegisterCard /> },
  { id: "roleSelection", content: <AccountRoleCards /> },
  { id: "athleteOrOrganizationForm", content: <AthleteOrOrganizationCard /> },
];
const Register = () => {
  return (
    <div className="m-auto flex flex-col mt-12 px-4">
      <Stepper
        styles={{
          "horizontal-step-container": "text-primary-foreground",
          "step-button-container": "p-8",
        }}
        size="lg"
        initialStep={0}
        steps={steps}
        onClickStep={(step, setStep) => setStep(step)}
      >
        {steps.map(({ id, content }) => (
          <Step key={id}>
            <div className="min-w-[35vw] flex justify-center items-center">{content}</div>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default Register;
