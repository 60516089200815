import { createEvent, createStore } from "effector";
import { getLocationNameFx } from "./effects";

const $locationName = createStore<string>("");
const $locationNameLoading = createStore<boolean>(false);
const resetLocationName = createEvent();

$locationName.on(resetLocationName, () => "");

$locationName.watch(console.log);

$locationName.on(getLocationNameFx.doneData, (state, location) => location);
$locationNameLoading.on(getLocationNameFx, () => true);
$locationNameLoading.on(getLocationNameFx.done, () => false);

export { $locationName, $locationNameLoading, resetLocationName };
