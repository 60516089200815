import { CheckboxWithText, CheckboxWithTextProps } from "@/components/checkbox-with-text";
import { ReactHookFormField } from "@/types/rhf";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui-kit/form";
import { CheckedState } from "@radix-ui/react-checkbox";
import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

interface CheckboxProps extends Omit<InputHTMLAttributes<CheckboxWithTextProps>, "name">, ReactHookFormField {
  onValueChange?: (event: CheckedState) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  disabled,
  itemClassName,
  label,
  labelClassName,
  messageClassName,
  children,
  onValueChange,
  checked,
}) => {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field: { onChange, name: _, value, ...other } }) => (
        <FormItem className={itemClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            <CheckboxWithText
              onCheckedChange={(e) => {
                onValueChange?.(e);
                onChange(e);
              }}
              {...other}
              checked={checked !== undefined ? checked : value}
            >
              {children}
            </CheckboxWithText>
          </FormControl>

          <FormMessage className={messageClassName} />
        </FormItem>
      )}
    />
  );
};

export type { CheckboxProps };
