import { AthletesCarousel } from "@/components/carousel/athletes-carousel";
import { EventsCarousel } from "@/components/carousel/events-carousel";
import { settings } from "@/consts/slider-settings";
import { useAthleteApi } from "@/hooks/athlete";
import { useEventApi } from "@/hooks/event";
import { CloudIcon } from "@/icons/cloud";
import { FriendsIcon } from "@/icons/friends";
import { FutureIcon } from "@/icons/future";
import { Image } from "@/icons/image";
import { LogoImage } from "@/icons/logo";
import { MakeIcon } from "@/icons/make";
import { SportIcon } from "@/icons/sport";
import OrganizerImage from "@/images/organizer.png";
import Slide1Img from "@/images/slide-1.png";
import Slide2Img from "@/images/slide-2.png";
import Slide3Img from "@/images/slide-3.png";
import UserWoman from "@/images/user-woman.png";
import { Button } from "@/ui-kit/button";
import { Divider } from "@/ui-kit/divider";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Main = () => {
  const { getEvents } = useEventApi();
  const { getAthletes } = useAthleteApi();

  useEffect(() => {
    getEvents({ page: 1, size: 10, searchParams: {} });
    getAthletes({ page: 1, size: 10, searchParams: {} });
  }, []);

  return (
    <div className="w-full">
      <Slider {...settings}>
        <div className="relative w-96 md:w-auto">
          <div className="absolute w-full top-[25%] -translate-y-[25%] md:top-1/2 md:-translate-y-1/2">
            <div className="flex flex-col flex-wrap">
              <div className="flex p-4 pb-0 md:justify-around justify-between">
                <MakeIcon className="text-white w-36 md:w-auto" />
                <SportIcon className="text-white -mt-12 mr-2 md:-m-12 w-36 md:w-auto" />
              </div>
              <div className="w-full flex flex-col md:w-1/5 p-6 md:p-2 md:ml-[18%]">
                <span className="tt text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a,
                  mattis tellus.{" "}
                </span>
                <div>
                  <Button className="mt-4">Рассказать о нас</Button>
                </div>
              </div>
            </div>
          </div>
          <Image src={Slide1Img} alt="Slide 1" className="object-cover h-[50vh] md:h-auto md:w-full" />
        </div>
        <div className="relative w-96 md:w-auto">
          <div className="absolute w-full top-[10%] -translate-y-[10%] md:top-1/2 md:-translate-y-1/2">
            <div className="flex flex-col flex-wrap">
              <div className="flex p-4 md:justify-around justify-between">
                <MakeIcon className="text-white w-36 md:w-auto" />
                <FriendsIcon className="text-white -mt-8 mr-2 md:-m-12 w-36 md:w-auto" />
              </div>
            </div>
            <div className="w-full flex flex-col md:w-1/5 p-6 md:p-2 md:ml-[17%]">
              <span className="tt text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
                tellus.{" "}
              </span>
              <div>
                <Button className="mt-4">Рассказать о нас</Button>
              </div>
            </div>
          </div>
          <Image src={Slide2Img} alt="Slide 2" className="object-cover h-[50vh] md:h-auto md:w-full" />
        </div>
        <div className="relative w-96 md:w-auto">
          <div className="absolute w-full top-[15%] -translate-y-[15%] md:top-1/2 md:-translate-y-1/2">
            <div className="flex p-4 md:pl-80 md:justify-start justify-between md:gap-24">
              <MakeIcon className="text-white w-36 md:w-auto" />
              <FutureIcon className="text-white -mt-8 mr-2 md:-m-12 w-36 md:w-auto" />
            </div>
            <div className="w-full flex flex-col md:w-1/5 p-6 md:p-2 pl-44 md:pl-4 pt-0 md:ml-[16%]">
              <span className="tt text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
                tellus.{" "}
              </span>
              <div>
                <Button className="mt-4">Рассказать о нас</Button>
              </div>
            </div>
          </div>
          <Image src={Slide3Img} alt="Slide 3" className="object-left object-cover h-[50vh] md:h-auto md:w-full" />
        </div>
      </Slider>
      <div className="bg-white flex flex-col -mt-2 py-16 md:py-32 md:px-60">
        <div className="flex">
          <Divider direction="vertical" className="h-52" lineClassName="border-0 border-r-[#0B00BE] border-r-[3px]" />
          <div>
            <span className="tt">Наша миссия</span>
            <p className="text-black pt-2 pr-4 md:pr-0 h5 md:h4 text-left indent-12 md:indent-60">
              Добро пожаловать на спортивную платформу <LogoImage className="inline-block" /> которая сочетает в себе,
              Разнообразие событий и широкую базу профессионалов в различных направлениях. Вы можете быть спортсменом,
              тренером или организацией, которая устраивает спортивные мероприятия
            </p>
          </div>
        </div>
        <div className="text-center mt-28">
          <h4 className="h2 m-auto">Поиск события</h4>
          <div className="w-[90%] md:w-full mx-8 lg:mx-0 flex flex-row gap-3 flex-wrap justify-center tt items-center mt-16">
            <div className="self-stretch flex bg-green-300 rounded-2xl px-5 py-8 xl:flex-[1_1_30%] xl:w-[30%]">
              <div className="text-left">
                <span className="tt text-white text-left inline-block w-3/4">
                  ВОЗМОЖНОСТЬ ОРГАНИЗОВАТЬ И ПРИНЯТЬ УЧАСТИЕ В РАЗЛИЧНЫХ СОРЕВНОВАНИЯХ, МАРАФОНАХ, ТУРНИРАХ И ДРУГИХ
                  СПОРТИВНЫХ МЕРОПРИЯТИЯХ
                </span>
                <Button variant="link" className="pl-0 text-white" asChild>
                  <Link to="/events">Поиск события</Link>
                </Button>
              </div>
              <h2 className="h2 !font-normal text-white [writing-mode:vertical-lr] rotate-180">НОВЫЕ СОБЫТИЯ</h2>
            </div>
            <EventsCarousel className="w-[60%] flex-[2_1_60%]" />
          </div>
        </div>
        <div className="mt-8 md:mt-16 relative overflow-hidden">
          <CloudIcon className="w-[200%] md:w-full -ml-[50%] md:ml-0" />
          <div className="absolute top-1/2 -translate-y-1/2 left-[15%] md:left-[15%] -translate-x-[10%] md:p-24 flex justify-center items-center md:gap-32">
            <Image src={OrganizerImage} alt="Организатор" className="w-28 md:w-48 h-auto max-w-96 max-h-96" />
            <div className="flex flex-col justify-between px-4 mt-[20%] md:mt-0">
              <h4 className="md:h2 h5 text-white">Создание события</h4>
              <p className="md:mt-10 mt-4 tt md:h4 md:w-[84%] mr-4 text-white text-justify md:indent-60">
                ПРОБУДИ В СЕБЕ ОРГАНИЗАТОРСКИЕ СПОСОБНОСТИ! СОЗДАВАЙ СОБЫТИЯ И ОБЪЕДИНЯЙ ЛЮДЕЙ{" "}
              </p>
              <div className="mt-16 md:mt-6">
                <Button>Создать событие</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h4 className="h2 m-auto">Поиск людей</h4>
          <div className="w-[90%] md:w-full mx-8 lg:mx-0 flex flex-row gap-3 flex-wrap justify-center tt items-center mt-16">
            <AthletesCarousel className="w-[60%] flex-[2_1_60%]" />
            <div className="self-stretch flex bg-green-300 rounded-2xl px-5 py-8 xl:flex-[1_1_30%] xl:w-[30%]">
              <h2 className="h2 !font-normal text-white [writing-mode:vertical-lr]">ПОЛЬЗОВАТЕЛИ</h2>
              <div className="text-right">
                <span className="tt text-white text-right inline-block w-4/6">
                  ВЫ СМОЖЕТЕ ОБЩАТЬСЯ С ЕДИНОМЫШЛЕННИКАМИ, ДЕЛИТЬСЯ ОПЫТОМ, НАХОДИТЬ МОТИВАЦИЮ И ПОЛУЧАТЬ ЦЕННЫЕ СОВЕТЫ
                  ОТ ОПЫТНЫХ СПОРТСМЕНОВ И ТРЕНЕРОВ
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 relative overflow-hidden">
          <CloudIcon className="w-[200%] md:w-full -ml-[50%] md:ml-0" />
          <div className="absolute top-1/2 -translate-y-1/2 left-[15%] md:left-[15%] -translate-x-[10%] md:p-24 flex justify-center items-center md:gap-24">
            <div className="flex flex-col justify-between px-4 mt-[20%] md:mt-0 w-[69%]">
              <h4 className="md:h2 h5 text-white">Регистрация в системе</h4>
              <p className="md:mt-10 mt-4 w-[75%] tt md:h4 md:w-[88%] mr-4-6 text-white text-justify md:indent-60">
                ПРОБУДИ В СЕБЕ ОРГАНИЗАТОРСКИЕ СПОСОБНОСТИ! СОЗДАВАЙ СОБЫТИЯ И ОБЪЕДИНЯЙ ЛЮДЕЙ{" "}
              </p>
              <div className="mt-16 md:mt-6">
                <Button>Зарегистрироваться</Button>
              </div>
            </div>
            <Image src={UserWoman} alt="Девушка пользователь" className="w-28 md:w-48 h-auto max-w-96 max-h-96" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
