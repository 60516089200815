import { LocationMarker } from "@/components/location-marker";
import { LocationIcon } from "@/icons/location";
import { Coords } from "@/types/map";
import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { Input } from "@/ui-kit/input";
import { cn } from "@/utils/tailwind";
import { DialogDescription } from "@radix-ui/react-dialog";
import { LoaderCircle } from "lucide-react";
import { FC, MouseEventHandler, useEffect, useId, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import LocationSearchControl from "./location-search-control";
import "/node_modules/leaflet-geosearch/dist/geosearch.css";

type LocationInputProps = {
  onValueChange?: (value: Coords | null) => void;
  defaultMapCenterCoords?: Coords;
  defaultValue?: Coords;
  triggerClassName?: string;
  placeholder?: string;
  label?: string;
  isLoading?: boolean;
};

export const LocationInput: FC<LocationInputProps> = ({
  onValueChange,
  defaultMapCenterCoords,
  defaultValue,
  triggerClassName,
  placeholder,
  label,
  isLoading = false,
}) => {
  const mapCenterCoords = useRef<Coords>(defaultMapCenterCoords || defaultValue || { latitude: 0, longitude: 0 });
  const lastSavedMarkerCoords = useRef<Coords | null>(defaultValue || null);
  const [markerCoords, setMarkerCoords] = useState<Coords | null>(lastSavedMarkerCoords.current);
  const [inputValue, setInputValue] = useState(
    defaultValue ? `${defaultValue.latitude}, ${defaultValue.longitude}` : "",
  );
  const [positionRequested, setPositionRequested] = useState(false);
  const [open, setOpen] = useState(false);
  const uuid = useId();

  useEffect(() => {
    if (defaultValue) {
      mapCenterCoords.current = defaultValue;
    }
  }, [defaultValue]);

  const requestGeolocation: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {
            coords: { latitude, longitude },
          } = position;
          if (!positionRequested) {
            mapCenterCoords.current = { latitude, longitude };
            lastSavedMarkerCoords.current = { latitude, longitude };
            setMarkerCoords({ latitude, longitude });
            setPositionRequested(true);
          }
          setOpen(true);
        },
        () => {
          setOpen(true);
        },
      );
    }
  };

  const onSaveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    lastSavedMarkerCoords.current = markerCoords;
    setInputValue(markerCoords ? `${markerCoords.latitude}, ${markerCoords.longitude}` : "");
    onValueChange && onValueChange(markerCoords);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setMarkerCoords(lastSavedMarkerCoords.current);
    }
    setOpen(open);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild onClick={requestGeolocation}>
        <div key={`location-input-${label}`}>
          <Input
            key={uuid}
            placeholder={placeholder}
            defaultValue={label || inputValue}
            className={cn(triggerClassName, { "opacity-50": isLoading })}
            startAdornment={
              isLoading ? (
                <LoaderCircle className="absolute left-1 top-1.5 h-4 w-4 animate-spin" />
              ) : (
                <LocationIcon className="left-3 !top-1.5 w-4 h-4" />
              )
            }
            readOnly
          />
        </div>
      </DialogTrigger>
      <DialogContent className="w-full md:w-[600px] md:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Пожалуйста укажите местоположение</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <div className="w-full">
          <MapContainer
            center={[mapCenterCoords.current.latitude, mapCenterCoords.current.longitude]}
            zoom={13}
            scrollWheelZoom
            className="w-full h-96"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <LocationSearchControl />
            <LocationMarker coords={markerCoords} onPositionChange={setMarkerCoords} />
          </MapContainer>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Отменить</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="main" disabled={!markerCoords} onClick={onSaveClick}>
              Сохранить
            </Button>
          </DialogClose>
        </DialogFooter>
        <DialogDescription />
      </DialogContent>
    </Dialog>
  );
};
