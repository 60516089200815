import { AthleteCard } from "@/components/card/athlete-card";
import { Select } from "@/components/select";
import { useFavoriteApi } from "@/hooks/favorite";
import { AthleteListItemCard } from "@/types/athlete";
import { FC } from "react";
import { SkeletonList } from "./skeleton-list";

type AthletesListProps = {
  title?: string;
  data: AthleteListItemCard[];
  isLoading: boolean;
  total?: number;
};

export const AthletesList: FC<AthletesListProps> = ({ total, data, isLoading, title }) => {
  const { addFavoriteAthlete, removeFavoriteAthlete } = useFavoriteApi();

  const athletes = isLoading ? (
    <SkeletonList />
  ) : (
    data.map((athlete, index) => (
      <AthleteCard
        key={index}
        {...athlete}
        location={athlete.location.name || ""}
        buttonText="Подробнее"
        onLikeHandler={() =>
          athlete.isInFavorite
            ? removeFavoriteAthlete({ athleteId: athlete.id })
            : addFavoriteAthlete({ athleteId: athlete.id })
        }
      />
    ))
  );

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          {title && <h4 className="h4">{title}</h4>}
          {total && <span className="ct text-gray-100 mb-[0.3rem]">Найдено: {total}</span>}
        </div>
        <Select
          placeholder="Фильтр"
          onValueChange={(selected) => {
            console.log("filter: ", selected);
          }}
          defaultValue=""
          className="mt-2"
          options={[{ value: "all", label: "Все" }]}
        />
      </div>
      <div className="flex flex-wrap gap-2 justify-center lg:justify-start my-8 min-h-96">{athletes}</div>
    </div>
  );
};
