import { CalendarStarIcon } from "@/icons/calendar-star";
import { EditIcon } from "@/icons/edit";
import { Image } from "@/icons/image";
import { InstagramIcon } from "@/icons/instagram";
import { LikeIcon } from "@/icons/like";
import { LocationIcon } from "@/icons/location";
import { PadlockLockedIcon } from "@/icons/padlock-locked";
import { TelegramIcon } from "@/icons/telegram";
import { TimeIcon } from "@/icons/time";
import { router } from "@/router";
import { EventSummaryCard } from "@/types/event";
import { Sport } from "@/types/sport";
import { Button } from "@/ui-kit/button";
import { Divider } from "@/ui-kit/divider";
import { Skeleton } from "@/ui-kit/skeleton";
import { SPORT_ICON_SHADOW_MAP } from "@/utils/icons";
import { FC } from "react";

type EventSummaryProps = {
  data: EventSummaryCard;
  isUserRalated?: boolean;
  isLoading?: boolean;
};

const EventSummarySkeleton = () => {
  return (
    <>
      <div className="flex-[2_1_0] min-w-60 sm:min-w-md max-w-md min-h-60 sm:min-h-md max-h-md">
        <Skeleton className="h-full w-full" />
      </div>
      <section className="flex flex-col flex-[3_1_0] bg-green-100 rounded-xxl px-7 py-6 min-w-60 max-w-full">
        <div className="flex items-center">
          <PadlockLockedIcon />
          <Skeleton className="h-10 w-48 ml-2" />
        </div>
        <div className="ml-[0.2rem] mt-6">
          <div className="flex">
            <CalendarStarIcon />
            <Skeleton className="h-6 w-56 ml-3" />
          </div>
          <div className="flex mt-3">
            <TimeIcon />
            <Skeleton className="h-6 w-56 ml-3" />
          </div>
          <div className="flex mt-3">
            <LocationIcon className="w-6 h-6" />
            <Skeleton className="h-6 w-56 ml-3" />
          </div>
        </div>
        <section className="flex flex-wrap items-center gap-4 mt-8">
          <Skeleton className="h-6 w-56 ml-3" />
        </section>
        <section className="flex flex-wrap justify-start mt-8 sm:mt-auto">
          <div className="flex flex-col text-green-300">
            <Skeleton className="h-5 w-56" />
            <Skeleton className="h-6 w-56" />
          </div>

          <div className="flex">
            <Divider direction="vertical" lineClassName="border-green-300" />
            <div className="flex flex-col">
              <Skeleton className="h-5 w-56" />
              <Skeleton className="h-5 w-56" />
            </div>
          </div>
          <div className="flex">
            <Divider direction="vertical" lineClassName="border-green-300" />
            <div className="flex flex-col">
              <Skeleton className="h-6 w-56 ml-3" />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export const EventSummaryOnModeration: FC<EventSummaryProps> = ({ data, isLoading, isUserRalated }) => {
  const SportIcon = SPORT_ICON_SHADOW_MAP[data?.sport as Sport] || null;
  return (
    <div className="flex gap-8 mt-8 flex-col sm:flex-row flex-wrap">
      {isLoading ? (
        <EventSummarySkeleton />
      ) : (
        <>
          <div className="relative flex-[2_1_0] min-w-60 sm:min-w-md max-w-md min-h-60 sm:min-h-md max-h-md">
            <Image src={data?.avatar} alt="Event Card" className="rounded-xxl opacity-50" />
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-2 top-0"
              onClick={() => router.navigate(`/events/${data?.id}/edit`)}
            >
              <EditIcon className="min-w-6 min-h-6" />
            </Button>
          </div>
          <section className="flex flex-col flex-[3_1_0] bg-green-100/50 rounded-xxl px-7 py-6 min-w-60 max-w-full">
            <div className="flex items-center opacity-50">
              <PadlockLockedIcon />
              <h4 className="h4 ml-2">{data?.name}</h4>
            </div>
            <div className="ml-[0.2rem] mt-6 opacity-50">
              <div className="flex">
                <CalendarStarIcon />
                <span className="ct2 ml-2 break-all">{data?.formattedDate}</span>
              </div>
              <div className="flex mt-3 opacity-50">
                <TimeIcon />
                <span className="ct2 ml-2 break-all">{data?.time}</span>
              </div>
              <div className="flex mt-3 opacity-50">
                <LocationIcon className="w-6 h-6" />
                <span className="ct2 ml-2 break-all">{data?.location.name}</span>
              </div>
            </div>
            <section className="flex flex-wrap items-center my-8">
              <Button variant="destructive" className="h5" disabled>
                На модерации
              </Button>
            </section>
            <section className="flex flex-wrap justify-start mt-8 sm:mt-auto opacity-50">
              <div className="flex flex-col text-green-300">
                <span className="h5">Время до мероприятия</span>
                <span className="description">23:20:05</span>
              </div>

              <div className="flex">
                <Divider direction="vertical" lineClassName="border-green-300" />
                <div className="flex flex-col">
                  <span className="h5">Вид спорта</span>
                  <span>{SportIcon && <SportIcon />}</span>
                </div>
              </div>
              <div className="flex">
                <Divider direction="vertical" lineClassName="border-green-300" />
                <div className="flex flex-col">
                  <span className="h5">Соцсети</span>
                  <div className="flex gap-2 mt-[5px]">
                    <InstagramIcon />
                    <TelegramIcon />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </div>
  );
};

export const EventSummary: FC<EventSummaryProps> = ({ data, isLoading = false }) => {
  const SportIcon = SPORT_ICON_SHADOW_MAP[data?.sport as Sport] || null;
  return (
    <div className="flex gap-8 mt-8 flex-col sm:flex-row flex-wrap">
      {isLoading ? (
        <EventSummarySkeleton />
      ) : (
        <>
          <div className="flex-[2_1_0] min-w-60 sm:min-w-md max-w-md min-h-60 sm:min-h-md max-h-md">
            <Image src={data?.avatar} alt="Event Card" className="rounded-xxl" />
          </div>
          <section className="flex flex-col flex-[3_1_0] bg-green-100 rounded-xxl px-7 py-6 min-w-60 max-w-full">
            <div className="flex items-center">
              <PadlockLockedIcon />
              <h4 className="h4 ml-2">{data?.name}</h4>
            </div>
            <div className="ml-[0.2rem] mt-6">
              <div className="flex">
                <CalendarStarIcon />
                <span className="ct2 ml-2 break-all">{data?.formattedDate}</span>
              </div>
              <div className="flex mt-3">
                <TimeIcon />
                <span className="ct2 ml-2 break-all">{data?.time}</span>
              </div>
              <div className="flex mt-3">
                <LocationIcon className="w-6 h-6" />
                <span className="ct2 ml-2 break-all">{data?.location.name}</span>
              </div>
            </div>
            <section className="flex flex-wrap items-center gap-4 mt-8">
              <Button>Записаться</Button>
              <Button variant="ghost" size="icon">
                <LikeIcon />
              </Button>
            </section>
            <section className="flex flex-wrap justify-start mt-8 sm:mt-auto">
              <div className="flex flex-col text-green-300">
                <span className="h5">Время до мероприятия</span>
                <span className="description">23:20:05</span>
              </div>

              <div className="flex">
                <Divider direction="vertical" lineClassName="border-green-300" />
                <div className="flex flex-col">
                  <span className="h5">Вид спорта</span>
                  <span>{SportIcon && <SportIcon />}</span>
                </div>
              </div>
              <div className="flex">
                <Divider direction="vertical" lineClassName="border-green-300" />
                <div className="flex flex-col">
                  <span className="h5">Соцсети</span>
                  <div className="flex gap-2 mt-[5px]">
                    <InstagramIcon />
                    <TelegramIcon />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </div>
  );
};
