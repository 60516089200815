import React from "react";

// hard to make it parametrised, for now left it as it is in disign
export const RunningIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <circle cx="14" cy="14" r="13.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M17 7.49967C17 8.328 17.6713 9 18.4993 9C19.329 9 20 8.328 20 7.49967C20 6.672 19.329 6 18.4993 6C17.6713 6 17 6.672 17 7.49967Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6745 15L10.2539 17.0921C10.2539 17.0921 7.88558 17.26 7.03829 17.3104C6.39001 17.349 6 17.5957 6 18.0237C6 18.4517 6.32832 18.6923 7.2631 18.7686C8.05114 18.8328 10.7443 19 10.7443 19C10.7474 19 10.7505 19 10.7526 19C11.0674 19 11.3971 18.83 11.6135 18.5046L13 16.4884"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5488 16.5956L14.923 14.8655L17.5948 10.7503C17.7477 10.5309 17.8542 9.94472 17.6264 9.79698L16.1256 8.82826C16.021 8.74196 15.8959 8.67577 15.7514 8.64541L12.7499 8.01789C12.5183 7.97181 12.2783 8.01533 12.0828 8.14186L9.61489 9.97471C9.20947 10.3122 8.82078 10.7342 9.08815 11.1138C9.25967 11.3592 9.84537 11.2195 10.1527 11.04L12.743 9.71726L14.4484 10.0745L12.1486 13.3607C11.6211 14.2387 11.4458 14.9159 12.3947 15.693L15.6631 17.7713L14.2966 21.015C13.7813 22.2038 13.8737 22.8134 14.2267 22.9571C14.5796 23.1009 15.0987 22.9356 15.8324 21.6421L17.9101 17.8243C18.1139 17.3847 17.9618 16.8684 17.5488 16.5956Z"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 11.9996L17.6203 13.4641C17.7356 13.7191 17.9977 13.9667 18.2797 14C18.2797 14 20.2497 13.9583 20.7874 13.9292C21.3525 13.8991 21.9855 13.7947 21.9994 13.414C22.0201 12.8683 21.4963 12.828 20.9875 12.7919L19.0003 12.6259L17.8225 10"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
