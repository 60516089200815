import React from "react";

export const FacebookIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 1.2002C7.38577 1.2002 1.2002 7.38577 1.2002 15.0002C1.2002 22.6146 7.38577 28.8002 15.0002 28.8002C22.6146 28.8002 28.8002 22.6146 28.8002 15.0002C28.8002 7.38577 22.6146 1.2002 15.0002 1.2002ZM15.0002 2.4002C21.9661 2.4002 27.6002 8.0343 27.6002 15.0002C27.6002 21.9661 21.9661 27.6002 15.0002 27.6002C8.0343 27.6002 2.4002 21.9661 2.4002 15.0002C2.4002 8.0343 8.0343 2.4002 15.0002 2.4002Z"
        fill="currentColor"
      />
      <path
        d="M18.9191 11.5185H16.2222V9.74967C16.2222 9.08539 16.6624 8.93052 16.9725 8.93052C17.2819 8.93052 18.8758 8.93052 18.8758 8.93052V6.01023L16.2546 6C13.3449 6 12.6827 8.17805 12.6827 9.57187V11.5185H11V14.5277H12.6827C12.6827 18.3895 12.6827 23.0427 12.6827 23.0427H16.2222C16.2222 23.0427 16.2222 18.3437 16.2222 14.5277H18.6105L18.9191 11.5185Z"
        stroke="currentColor"
        fill="transparent"
      />
    </svg>
  ),
);
