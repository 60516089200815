import { Button } from "@/ui-kit/button";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/ui-kit/card";
import { FC, memo } from "react";
import { Image } from "@/icons/image";

type UserCardProps = {
  title: string;
  image: string;
  description: string;
  pros: string[];
  primary?: boolean;
  onClickHandler?: (name: string) => void;
};

export const AccountRoleCard: FC<UserCardProps> = memo(
  ({ title, image, description, pros, primary, onClickHandler }) => {
    return (
      <Card className="m-auto rounded-2xl">
        <CardHeader>
          <CardTitle>
            <span className="h4">{title}</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <Image src={image} alt={title} className={primary ? "max-w-64 max-h-64" : "max-w-48 max-h-48"} />
          <h6 className="h6 mt-6">{description}</h6>
          <ol className="list-[auto] mt-4">
            {pros.map((pro, index) => (
              <li key={index} className="f2">
                {pro}
              </li>
            ))}
          </ol>
        </CardContent>
        <CardFooter>
          <Button variant={primary ? "main" : "outlineMain"} onClick={() => onClickHandler && onClickHandler(title)}>
            Выбрать
          </Button>
        </CardFooter>
      </Card>
    );
  },
);
