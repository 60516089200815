import { AthleteSummary, EventSummary, FavoriteControllerApi, GetAvatarCategoryEnum } from "@/api/generated";
import { AthleteRequestParams } from '@/types/athlete';
import { WithAvatar } from '@/types/avatar';
import { RequestPayload } from "@/types/common";
import { EventRequestParams } from '@/types/event';
import { SortablePageRequestParams } from '@/types/page';
import { createEffect } from "effector";
import { getAvatarByCategoryAndIdUrl } from '../avatar';

type AddFavoriteEvent = (
    params: RequestPayload<FavoriteControllerApi, EventRequestParams>,
) => Promise<void>;

const addFavoriteEventFx = createEffect<AddFavoriteEvent>(async ({ controller, payload }) => {
    try {
        await controller.addFavoriteEvent(payload.eventId);
    } catch (error) {
        console.log("Error in AddFavoriteEventFx", error);
        throw error;
    }
});

type RemoveFavoriteEvent = (
    params: RequestPayload<FavoriteControllerApi, EventRequestParams>,
) => Promise<void>;

const removeFavoriteEventFx = createEffect<RemoveFavoriteEvent>(async ({ controller, payload }) => {
    try {
        await controller.deleteFavoriteEvent(payload.eventId);
    } catch (error) {
        console.log("Error in RemoveFavoriteEventFx", error);
        throw error;
    }
});

type GetFavoriteEvents = (
    params: RequestPayload<FavoriteControllerApi, SortablePageRequestParams<void>>,
) => Promise<WithAvatar<EventSummary>[]>;

const getFavoriteEventsFx = createEffect<GetFavoriteEvents>(async ({ controller, payload }) => {
    try {

        const response = await controller.getFavoriteEvents(
            payload.page,
            payload.size,
            payload.sort
        );

        const dataWithAvatar = await Promise.all(
            response.data.map(async (event) => ({
                ...event,
                avatar: await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, event.id),
            })),
        );

        return dataWithAvatar;
    } catch (error) {
        console.log("Error in GetFavoriteEventsFx", error);
        throw error;
    }
});

type AddFavoriteAthlete = (
    params: RequestPayload<FavoriteControllerApi, AthleteRequestParams>,
) => Promise<void>;

const addFavoriteAthleteFx = createEffect<AddFavoriteAthlete>(async ({ controller, payload }) => {
    try {
        await controller.addFavoriteAthlete(payload.athleteId);
    } catch (error) {
        console.log("Error in AddFavoriteAthleteFx", error);
        throw error;
    }
});

type RemoveFavoriteAthlete = (
    params: RequestPayload<FavoriteControllerApi, AthleteRequestParams>,
) => Promise<void>;

const removeFavoriteAthleteFx = createEffect<RemoveFavoriteAthlete>(async ({ controller, payload }) => {
    try {
        await controller.deleteFavoriteAthlete(payload.athleteId);
    } catch (error) {
        console.log("Error in RemoveFavoriteAthleteFx", error);
        throw error;
    }
});

type GetFavoriteAthletes = (
    params: RequestPayload<FavoriteControllerApi, SortablePageRequestParams<void>>,
) => Promise<WithAvatar<AthleteSummary>[]>;

const getFavoriteAthletesFx = createEffect<GetFavoriteAthletes>(async ({ controller, payload }) => {
    try {
        const response = await controller.getFavoriteAthletes(
            payload.page,
            payload.size,
            payload.sort
        );

        const dataWithAvatar = await Promise.all(
            response.data.map(async (athlete) => ({
                ...athlete,
                avatar: await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
            })),
        );


        return dataWithAvatar;
    } catch (error) {
        console.log("Error in GetFavoriteAthletesFx", error);
        throw error;
    }
});

export {
    addFavoriteAthleteFx,
    addFavoriteEventFx,
    getFavoriteAthletesFx,
    getFavoriteEventsFx,
    removeFavoriteAthleteFx,
    removeFavoriteEventFx
};

