import { useAthletes } from "@/hooks/athlete";
import { useFavoriteApi } from "@/hooks/favorite";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/ui-kit/carousel";
import { Skeleton } from "@/ui-kit/skeleton";
import { cn } from "@/utils/tailwind";
import { FC, useMemo } from "react";
import { AthleteCard } from "../card/athlete-card";

type AthletesCarouselProps = {
  className?: string;
};

export const AthletesCarousel: FC<AthletesCarouselProps> = ({ className }) => {
  const { data, isLoading } = useAthletes();

  const { addFavoriteAthlete } = useFavoriteApi();

  const carouselItems = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <CarouselItem key={index} className="basis-full flex justify-center sm:basis-1/2 xl:basis-1/3">
              <Skeleton
                key={index}
                className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
              />
            </CarouselItem>
          ))
        : data.map((athlete, index) => (
            <CarouselItem key={index} className="basis-full flex justify-center md:basis-1/2 xl:basis-1/3">
              <AthleteCard
                {...athlete}
                location={athlete.location.name || ""}
                buttonText="Подробнее"
                className="!shadow-none w-full"
                onLikeHandler={() => addFavoriteAthlete({ athleteId: athlete.id })}
              />
            </CarouselItem>
          )),
    [data, isLoading],
  );

  return (
    <Carousel className={cn("w-full relative mb-10", className)}>
      <CarouselContent>{carouselItems}</CarouselContent>
      <div className="absolute -bottom-7 right-[50%] lg:right-[6%]">
        <CarouselPrevious className="bg-black border-none text-white disabled:bg-gray-100 hover:bg-none hover:bg-gray-200" />
        <CarouselNext className="bg-black border-none text-white -right-8 disabled:bg-gray-100 hover:bg-none hover:bg-gray-200" />
      </div>
    </Carousel>
  );
};
