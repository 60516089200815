import { FavoriteControllerApi } from '@/api/generated';
import { AthleteRequestParams } from '@/types/athlete';
import { EventRequestParams } from '@/types/event';
import { SortablePageRequestParams } from '@/types/page';
import { useUnit } from 'effector-react';
import { useHttpClient } from '../http-client';
import { addFavoriteAthleteFx, addFavoriteEventFx, getFavoriteAthletesFx, getFavoriteEventsFx, removeFavoriteAthleteFx, removeFavoriteEventFx } from './effects';

export const useFavoriteApi = () => {
    const httpClient = useHttpClient();
    const controller = new FavoriteControllerApi(undefined, undefined, httpClient);

    const getFavoriteEventsEffect = useUnit(getFavoriteEventsFx);
    const addFavoriteEventEffect = useUnit(addFavoriteEventFx);
    const removeFavoriteEventEffect = useUnit(removeFavoriteEventFx);
    const addFavoriteAthleteEffect = useUnit(addFavoriteAthleteFx);
    const removeFavoriteAthleteEffect = useUnit(removeFavoriteAthleteFx);
    const getFavoriteAthletesEffect = useUnit(getFavoriteAthletesFx);

    const getFavoriteEvents = async (params: SortablePageRequestParams<void>) =>
        await getFavoriteEventsEffect({ controller, payload: params });
    const addFavoriteEvent = async (params: EventRequestParams) =>
        await addFavoriteEventEffect({ controller, payload: params });
    const removeFavoriteEvent = async (params: EventRequestParams) =>
        await removeFavoriteEventEffect({ controller, payload: params });
    const addFavoriteAthlete = async (params: AthleteRequestParams) =>
        await addFavoriteAthleteEffect({ controller, payload: params });
    const removeFavoriteAthlete = async (params: AthleteRequestParams) =>
        await removeFavoriteAthleteEffect({ controller, payload: params });
    const getFavoriteAthletes = async (params: SortablePageRequestParams<void>) =>
        await getFavoriteAthletesEffect({ controller, payload: params });

    return {
        getFavoriteEvents,
        addFavoriteEvent,
        removeFavoriteEvent,
        addFavoriteAthlete,
        removeFavoriteAthlete,
        getFavoriteAthletes,
    };
}