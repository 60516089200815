import React from "react";

export const ArrowIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_2283_5287)">
        <path
          d="M8.65083 9.57812L14 14.9273L19.3492 9.57812L21 11.229L14 18.229L7 11.229L8.65083 9.57812Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2283_5287">
          <rect width="28" height="28" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
);
