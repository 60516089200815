import { Link } from "react-router-dom";

import { useAccountAbbr, useAccountExtended, useAccountLoggedIn, useAccountRole } from "@/hooks/account";
import { useAccountApi } from "@/hooks/account/use-account-api";
import { useAthleteApi } from "@/hooks/athlete";
import { useOrganizationApi } from "@/hooks/organization";
import { LogoImage } from "@/icons/logo";
import { PlusIcon } from "@/icons/plus";
import { AccountRole } from "@/types/account-role";
import { Avatar, AvatarFallback, AvatarImage } from "@/ui-kit/avatar";
import { Button } from "@/ui-kit/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/ui-kit/dropdown";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/ui-kit/tooltip";
import { useEffect } from "react";

export const Header = () => {
  const { logout } = useAccountApi();
  const { getAthleteMe } = useAthleteApi();
  const { getOrganizationMe } = useOrganizationApi();
  const loggedIn = useAccountLoggedIn();
  const accountRole = useAccountRole();
  const abbr = useAccountAbbr();
  const { data: account } = useAccountExtended();

  useEffect(() => {
    if (loggedIn) {
      accountRole === AccountRole.ATHLETE ? getAthleteMe() : getOrganizationMe();
    }
  }, [accountRole, loggedIn]);

  return (
    <header className="content-padding-64 py-6 flex justify-between items-center flex-wrap shadow-header z-10">
      <Link to="/">
        <LogoImage />
      </Link>
      <div className="flex gap-4">
        <Button variant="ghost" size="sm" asChild>
          <Link to="/events">События</Link>
        </Button>
        <Button variant="ghost" size="sm" asChild>
          <Link to="/athletes">Люди</Link>
        </Button>
        <Button variant="outline" size="sm">
          Расскажи о нас
        </Button>
      </div>
      <div className="flex gap-4">
        {account && loggedIn ? (
          <>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant={"ghost"} size={"icon"} asChild>
                    <Link to="/events/create">
                      <PlusIcon />
                    </Link>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Добавить событие</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="cursor-pointer">
                  <AvatarImage src={account.avatar} />
                  <AvatarFallback>{abbr}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuItem asChild>
                  <Link to="/profile" className="cursor-pointer">
                    <span>Мой профиль</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild className="cursor-pointer">
                  <Link to="/" onClick={logout}>
                    <span>Выйти</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ) : (
          <Button variant="outline" size="sm" asChild>
            <Link to="/login">Войти</Link>
          </Button>
        )}
        <Button variant="ghost" size="sm">
          EN
        </Button>
      </div>
    </header>
  );
};
