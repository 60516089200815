import React from "react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/ui-kit/card";
import { Button } from "@/ui-kit/button";
import { Link } from "react-router-dom";
import { Divider } from "@/ui-kit/divider";
import { AuthProviders } from "@/components/auth-providers";
import LoginForm from "@/components/forms/login";

const Login = () => {
  return (
    <div className="m-auto flex items-center justify-center">
      <Card className="min-w-[300px] w-screen max-w-[500px] my-8">
        <CardHeader>
          <CardTitle>Вход</CardTitle>
        </CardHeader>
        <CardContent>
          <AuthProviders />
          <Divider>
            <span>или</span>
          </Divider>
          <LoginForm />
        </CardContent>
        <CardFooter>
          <span>Ещё нет аккаунта?</span>
          <Button variant="link" size="link" asChild>
            <Link to="/register">Зарегистрироваться</Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Login;
