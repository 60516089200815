import { ReviewCarousel } from "@/components/carousel/review-carousel";
import { AthleteSendMessageDialog } from "@/components/dialog";
import { Rating } from "@/components/rating";
import dataMock from "@/consts/carousel-mock";
import { Image } from "@/icons/image";
import { InstagramIcon } from "@/icons/instagram";
import { LikeIcon } from "@/icons/like";
import { TelegramIcon } from "@/icons/telegram";
import { OrganizationSummaryCard } from "@/types/organization";
import { Sport } from "@/types/sport";
import { Button } from "@/ui-kit/button";
import { Divider } from "@/ui-kit/divider";
import { Skeleton } from "@/ui-kit/skeleton";
import { SPORT_ICON_MAP } from "@/utils/icons";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Achievements } from "./achivements";
import { OrganizationSummaryAbout } from "./organization-summary-about";

const OrganizationSummarySkeleton = () => {
  return (
    <>
      <div className="flex-[1_1_0] flex flex-col min-w-60 sm:min-w-md max-w-md">
        <div className="min-h-80 sm:min-h-md max-h-md">
          <Skeleton className="h-full w-full" />
        </div>
        <div className="mt-2.5 flex flex-col items-center">
          <Skeleton className="h-6 w-48" />
          <div className="mt-6 w-full text-center">
            <Skeleton className="h-12 w-56" />
          </div>
        </div>
      </div>
      <section className="flex-[4_1_0] min-w-60 max-w-full">
        <div className="flex flex-wrap bg-blue-100 rounded-xxl px-7 py-6 min-h-80 sm:min-h-md max-h-md">
          <div className="flex flex-col flex-[1_1_50%]">
            <div className="flex items-center">
              <Skeleton className="h-10 w-48" />
            </div>
            <Skeleton className="h-6 w-56" />
            <div className="flex flex-col mt-8">
              <Skeleton className="h-12 w-56" />
            </div>
            <div className="flex flex-col mt-8">
              <Skeleton className="h-12 w-56" />
            </div>
          </div>
          <section className="flex flex-[1_1_50%] justify-start">
            <Divider direction="vertical" lineClassName="border-primary border-l-2" className="hidden md:block" />
            <Skeleton className="w-full" />
          </section>
        </div>
        <section className="flex flex-col ml-6 mt-8">
          <Skeleton className="h-24 w-full" />
        </section>
        <section className="flex flex-col mt-8 mb-16">
          <Skeleton className="h-48 w-full" />
        </section>
      </section>
    </>
  );
};

type OrganizationSummaryProps = {
  data?: OrganizationSummaryCard;
  isLoading?: boolean;
  isOwner?: boolean;
};

export const OrganizationSummary: FC<OrganizationSummaryProps> = ({ data, isLoading = false, isOwner = false }) => {
  const sportIcons = data?.sports.map((sport) => SPORT_ICON_MAP[sport as Sport] || null);

  return (
    <div className="flex gap-2 mt-8 flex-col sm:flex-row flex-wrap">
      {isLoading || !data ? (
        <OrganizationSummarySkeleton />
      ) : (
        <>
          <div className="flex-[1_1_0] flex flex-col min-w-60 sm:min-w-md max-w-md">
            <div className="min-h-80 sm:min-h-md max-h-md">
              <Image src={data?.avatar} alt="Event Card" className="rounded-xxl w-full h-full object-cover" />
            </div>
            <div className="mt-2.5 flex flex-col items-center">
              <Rating value={data?.rating || 0} />
              <div className="mt-6 w-full text-center">
                {isOwner ? (
                  <Button variant="outlineMain" className="bt1 w-full" size="md">
                    <Link to="/profile/edit">Редактировать профиль</Link>
                  </Button>
                ) : (
                  <>
                    <AthleteSendMessageDialog />
                    <Button variant="link" className="text-black bt3">
                      Добавить в команду
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <section className="flex-[4_1_0] min-w-60 max-w-full">
            <div className="flex flex-wrap bg-blue-100 rounded-xxl px-7 py-6 min-h-80 sm:min-h-md max-h-md">
              <div className="flex flex-col flex-[1_1_50%]">
                <div className="flex items-center">
                  <h4 className="h4">{data.name}</h4>
                  <Button variant="ghost" size="icon" className="ml-2">
                    <LikeIcon />
                  </Button>
                </div>
                <span className="ct ml-1 break-all">{data?.location?.name}</span>
                <div className="flex flex-col mt-8">
                  <span className="h5">Виды спорта</span>
                  <div className="flex gap-2 mt-2">
                    {sportIcons?.map((Icon, index) => Icon && <Icon key={index} />)}
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <span className="h5">Соцсети</span>
                  <div className="flex gap-2 mt-2">
                    <InstagramIcon />
                    <TelegramIcon />
                  </div>
                </div>
              </div>
              <section className="flex flex-[1_1_50%] justify-start">
                <Divider direction="vertical" lineClassName="border-primary border-l-2" className="hidden md:block" />
                <OrganizationSummaryAbout description={data?.description} tags={data?.tags} />
              </section>
            </div>
            <section className="flex flex-col ml-6 mt-8">
              <h4 className="h4">Достижения</h4>
              <Achievements className="mt-4" achievements={data?.achievements || []} />
            </section>
            <section className="flex flex-col mt-8 mb-16">
              <h4 className="h4 ml-6">Отзывы</h4>
              <div className="flex flex-col">
                <ReviewCarousel data={dataMock} color="blue" />
                {!isOwner && (
                  <Button variant="main" className="mx-auto my-8 h-auto">
                    Добавить отзыв
                  </Button>
                )}
              </div>
            </section>
          </section>
        </>
      )}
    </div>
  );
};
