import React from "react";

export const MessageIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      stroke="#0B00BE"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M1 1L5.29289 5.29289C5.68342 5.68342 6.31658 5.68342 6.70711 5.29289L11 1" stroke="currentColor" />
      <rect x="0.5" y="0.5" width="11" height="8" rx="1.5" stroke="currentColor" />
    </svg>
  ),
);
