import { FacebookIcon } from "@/icons/facebook";
import { InstagramIcon } from "@/icons/instagram";
import { LetsIcon } from "@/icons/lets";
import { TaglineIcon } from "@/icons/tagline";
import { TelegramIcon } from "@/icons/telegram";
import { Button } from "@/ui-kit/button";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="content-padding-64 bg-primary text-white ft1">
      <div className="pt-8 pb-4 flex justify-between flex-wrap">
        <div>
          <h5 className="h5">Помогите нам стать лучше!</h5>
          <Button className="mt-4">Написать</Button>
        </div>
        <div>
          <span className="font-semibold">Меню</span>
          <div className="flex gap-4 mt-2">
            <Link to="/events">События</Link>
            <Link to="/people">Люди</Link>
            <Link to="/places">Места</Link>
            <Link to="/clubs">Клубы</Link>
          </div>
        </div>
        <div>
          <span className="font-semibold">Соцсети</span>
          <div className="flex gap-4 mt-2">
            <InstagramIcon />
            <TelegramIcon />
            <FacebookIcon />
          </div>
        </div>
        <div>
          <LetsIcon />
          <TaglineIcon className="mt-2" />
        </div>
      </div>
      <div className="flex justify-between mt-8 mb-4">
        <div>
          <span>Let's 2024</span>
        </div>
        <div className="flex gap-4">
          <span>Политика конфиденциальности</span>
          <span>Cookie</span>
        </div>
      </div>
    </footer>
  );
};
