import React from "react";

// hard do make it parametrised, for now left it as it is in disign
export const SwimmingShadowIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g filter="url(#filter0_d_762_226)">
        <circle cx="20" cy="20" r="14" fill="#FDFDFD" />
      </g>
      <path
        d="M26 18.4993C26 19.329 25.328 20 24.5 20C23.671 20 23 19.329 23 18.4993C23 17.6713 23.671 17 24.5 17C25.328 17 26 17.6713 26 18.4993Z"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 24C28 24 26.0393 23.1368 23.534 23.1368C21.0275 23.1368 18.8639 23.7669 16.0989 23.7669C12.7896 23.7669 11 23 11 23"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 23L18.839 19.6336L18.2 18.2632C18.2 18.2632 18.0613 17.7035 17.5819 17.8583C17.1031 18.0123 14.7887 19.1617 14.4003 19.3059C13.4161 19.672 12.9627 18.679 13.9151 18.1422C15.1483 17.4454 17.4647 16.3387 17.8168 16.1565C18.262 15.9249 18.6808 15.8828 19.0579 16.5067C19.6055 17.4143 21.3082 19.9043 21.6709 20.5209C22.0336 21.1375 22.2298 21.6581 21.5363 22.3032"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_762_226"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_226" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_226" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
);
