import { LinkIcon } from "@/icons/link";
import { Button } from "@/ui-kit/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/ui-kit/dialog";
import { DialogProps } from "@radix-ui/react-dialog";
import { FC } from "react";
import { Link } from "react-router-dom";

type EventCreatedDialogProps = DialogProps & {
  eventId: number | null;
};

export const EventCreatedDialog: FC<EventCreatedDialogProps> = ({ open, onOpenChange, eventId }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="px-24 pt-16 pb-12 max-w-2xl gap-2">
        <DialogHeader className="text-center sm:text-center">
          <DialogTitle className="h3">Ваше событие создано!</DialogTitle>
          <DialogDescription className="tt">В данный момент событие находится на модерации</DialogDescription>
        </DialogHeader>
        <div className="flex gap-1 justify-start">
          <span className="h5 flex items-center gap-1 justify-start">
            <LinkIcon />
            Ссылка:
          </span>
          <Button variant="link" asChild className="px-1">
            {eventId && <Link to={`/events/${eventId}`}>{`${window.location.origin}/events/${eventId}`}</Link>}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
