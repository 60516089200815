import { Tabs as UIKitTabs, TabsContent, TabsList, TabsTrigger } from "@/ui-kit/tabs";
import { FC } from "react";

type Tab = {
  value: string;
  label: string;
  content: React.ReactNode;
};

type TabsProps = {
  defaultValue: string;
  className?: string;
  triggerClassName?: string;
  listClassName?: string;
  tabs: Tab[];
};

export const Tabs: FC<TabsProps> = ({ defaultValue, className, triggerClassName, listClassName, tabs }) => {
  return (
    <UIKitTabs defaultValue={defaultValue} className={className}>
      <TabsList className={listClassName}>
        {tabs.map((tab) => (
          <TabsTrigger key={tab.value} value={tab.value} className={triggerClassName}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </UIKitTabs>
  );
};
