import React from "react";

export const CloseIconSmall = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="white"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_765_1829)">
        <path d="M1 1L7.1875 7.1875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 7.1875L7.1875 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_765_1829">
          <rect width="8" height="8" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
);
