import { z } from "zod";
import { cutBase64Header } from "@/schemas/utils";

const athleteSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  sportIds: z.array(z.number()),
  description: z.string(),
  avatar: z.string().transform(cutBase64Header).optional(),
  isCoach: z.boolean().default(false),
});

const athleteMainSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  avatar: z.string().transform(cutBase64Header).optional(),
});

const athleteSearchSchema = z.object({
  name: z.string().optional(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .optional(),
  sportIds: z.array(z.number()).optional(),
});

export { athleteSchema, athleteMainSchema, athleteSearchSchema };
