import { LocationControllerApi, Position } from "@/api/generated";
import { RequestPayload } from "@/types/common";
import { createEffect } from "effector";

type GetLocationName = (params: RequestPayload<LocationControllerApi, Position>) => Promise<string>;

const getLocationNameFx = createEffect<GetLocationName>(async ({ controller, payload }) => {
  try {
    const response = await controller.reverse(payload);

    return response.data.name || "";
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export { getLocationNameFx };
