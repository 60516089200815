import React from "react";

export const LetsIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="116"
      height="31"
      viewBox="0 0 116 31"
      fill="#FDFDFD"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.706 30.2502C91.3786 30.2502 89.1718 23.3812 89.1718 20.0798H96.6307C96.6307 20.0798 96.6307 25.0294 102.706 25.0294C106.847 25.0294 107.953 23.1048 107.953 21.4567C107.953 18.8821 104.645 18.2525 100.849 17.5257C95.7305 16.5481 89.7171 15.4015 89.7171 9.09043C89.7171 4.14085 94.4136 0.0204725 102.145 0.0204725C113.194 0.0204725 114.851 6.3418 114.851 9.09043H107.392C107.392 9.09043 107.392 5.24133 102.145 5.24133C99.1049 5.24133 97.4486 6.78199 97.4486 8.53763C97.4486 10.539 100.432 11.1481 103.987 11.8698C109.218 12.9344 115.679 14.2499 115.679 20.6326C115.679 26.1299 110.983 30.2502 102.695 30.2502H102.706ZM50.4835 0.839431V6.88436H59.321V21.728C59.321 28.3257 62.9115 29.6974 68.1584 29.6974C71.4712 29.6974 72.8549 29.1498 72.8549 29.1498V23.3761C72.8549 23.3761 72.0267 23.6525 70.3704 23.6525C67.8858 23.6525 67.0576 22.8284 67.0576 20.3562V6.88948H76.4506V0.844549H50.4835V0.839431ZM7.73663 23.3812H21.8261V29.4262H0V0.839431H7.73663V23.3761V23.3812ZM42.2839 11.8391C41.785 8.32265 40.2418 5.79412 36.43 5.79412C32.6183 5.79412 30.7407 8.32265 30.0772 11.8391H42.2891H42.2839ZM36.43 30.2554C26.7644 30.2554 22.0679 23.4938 22.0679 15.1405C22.0679 6.78711 26.9856 0.025592 36.43 0.025592C45.8745 0.025592 50.2418 6.78711 50.2418 15.1405C50.2418 15.8008 50.1852 16.7886 50.1852 16.7886H29.8508C30.18 21.1854 32.0628 24.4868 36.7027 24.4868C41.3426 24.4868 42.2274 20.09 42.2274 20.09H49.964C49.964 20.09 48.3076 30.2605 36.43 30.2605V30.2554ZM80.8848 0H90.571L84.8817 11.3221H77.68L80.8848 0Z"
        fill="currentColor"
      />
    </svg>
  ),
);
