import { Sport, SportControllerApi } from "@/api/generated";
import { RequestBase } from "@/types/common";
import { createEffect } from "effector";

type GetAllSports = (params: RequestBase<SportControllerApi>) => Promise<Sport[]>;

const getAllSportsFx = createEffect<GetAllSports>(async ({ controller }) => {
  try {
    const response = await controller.getAllSports();

    return response.data;
  } catch (error) {
    console.log("Error in getAllSportsFx", error);

    throw error;
  }
});

export { getAllSportsFx };
