import React from "react";

// hard to make it parametrised, for now left it as it is in disign
export const SkiingShadowIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g filter="url(#filter0_d_762_218)">
        <circle cx="20" cy="20" r="14" fill="#FDFDFD" />
      </g>
      <path
        d="M23 12.4993C23 13.3277 23.6713 14 24.4993 14C25.3283 14 26 13.3277 26 12.4993C26 11.672 25.3283 11 24.4993 11C23.6713 11 23 11.672 23 12.4993Z"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9626 24.1735C15.9626 24.1735 17.9188 21.9045 18.2668 21.4733C18.6143 21.042 18.5037 20.686 18.0317 20.3565C17.5748 20.0376 16.7979 19.6188 16.1109 19.1754C15.4311 18.7363 14.3678 17.3903 15.4907 15.9621C15.4907 15.9621 17.8731 12.5971 20.9227 13.0402C21.6477 13.1452 22.6984 13.6707 22.6984 14.6761C22.6984 15.6815 21.8105 17.6141 21.8105 17.6141C21.8105 17.6141 23.9814 18.788 24.4326 19.1258C25.3773 19.8323 25.1147 20.4704 23.8505 19.9737C21.7982 19.1686 20.5264 18.5567 20.3724 18.1247C20.1957 17.6284 20.3543 16.6012 20.3543 16.6012L18.3946 17.8608C19.1915 18.3857 19.8935 18.8523 20.097 19.0008C20.6385 19.3961 21.9273 20.4425 20.5701 21.9938C19.2414 23.5126 17.8178 25 17.8178 25"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 21L23.4097 28.7553C23.4097 28.7553 25.1002 29.5569 26 28.2583"
        stroke="#2F2F2F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 17L13 14" stroke="#2F2F2F" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <filter
          id="filter0_d_762_218"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_218" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_218" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
);
