import { CreateEventCard } from "@/components/card/create-event-card";

const EventCreate = () => {
  return (
    <div className="m-auto flex flex-col sm:my-12 md:px-4">
      <CreateEventCard />
    </div>
  );
};

export default EventCreate;
