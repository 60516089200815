import { Achivement } from "@/types/achivement";
import { ACHIEVEMENTS_ICON_MAP } from "@/utils/icons";
import { cn } from "@/utils/tailwind";
import { FC } from "react";

type AchievementsProps = {
  achievements: Achivement[];
  className?: string;
};

export const Achievements: FC<AchievementsProps> = ({ achievements, className }) => {
  const achivementIcons = achievements.map((achievement) => ACHIEVEMENTS_ICON_MAP[achievement]);

  return (
    <div className={cn("flex gap-5", className)}>
      {achivementIcons.map((Icon, index) => Icon && <Icon key={index} />)}
    </div>
  );
};
