import {
  AthleteControllerApi,
  AthleteInfo,
  GetAvatarCategoryEnum,
  Athlete as ResponseAthlete,
  UpdateAthleteRequest,
} from "@/api/generated";
import { getAvatarByCategoryAndIdUrl } from "@/hooks/avatar";
import { AthleteRequestParams } from "@/types/athlete";
import { WithAvatar } from "@/types/avatar";
import { RequestBase, RequestPayload } from "@/types/common";
import { createEffect } from "effector";

type GetAthlete = (
  params: RequestPayload<AthleteControllerApi, AthleteRequestParams>,
) => Promise<WithAvatar<AthleteInfo>>;
type GetAthleteMe = (params: RequestBase<AthleteControllerApi>) => Promise<WithAvatar<ResponseAthlete>>;
type UpdateAthlete = (
  params: RequestPayload<AthleteControllerApi, UpdateAthleteRequest>,
) => Promise<WithAvatar<AthleteInfo>>;

const getAthleteFx = createEffect<GetAthlete>(async ({ controller, payload }) => {
  try {
    const response = await controller.getAthlete(payload.athleteId);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, payload.athleteId);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in getAthleteFx", error);

    throw error;
  }
});

const getAthleteMeFx = createEffect<GetAthleteMe>(async ({ controller }) => {
  try {
    const response = await controller.athleteMe();
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in getAtheteMeFx");
    throw error;
  }
});

const updateAthleteFx = createEffect<UpdateAthlete>(async ({ controller, payload }) => {
  try {
    const response = await controller.updateAthlete(payload);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in updateAthleteFx");
    throw error;
  }
});

export { getAthleteFx, getAthleteMeFx, updateAthleteFx };
