import { Dropdown } from "@/components/dropdown";
import { DatePicker } from "@/components/rhf/date-picker";
import { Input } from "@/components/rhf/input";
import { LocationInput } from "@/components/rhf/location-input";
import { DEFAULT_LOCATION } from "@/consts/location";
import { useEventsSetSearchParams } from "@/hooks/event/list/use-events-set-search-params";
import { useLocationApi } from "@/hooks/location/use-location-api";
import { useLocationName } from "@/hooks/location/use-location-name";
import { useSports } from "@/hooks/sport/use-sports";
import { ArrowIcon } from "@/icons/arrow";
import { LocationIcon } from "@/icons/location";
import { SearchIcon } from "@/icons/search";
import { eventSearchSchema } from "@/schemas/event.schema";
import { EventSearchParams } from "@/types/event";
import { Button } from "@/ui-kit/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/ui-kit/collapsible";
import { Form } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const EventsSearchWithFiltersForm = () => {
  const sportDictionary = useSports();
  const { getLocationName } = useLocationApi();
  const setEventsSearchParams = useEventsSetSearchParams();
  const form = useForm<EventSearchParams>({
    resolver: zodResolver(eventSearchSchema),
  });

  const location = form.watch("location");

  useEffect(() => {
    if (location) {
      getLocationName(location);
    }
  }, [location]);

  const { data: locationName, isLoading } = useLocationName();

  const onSubmit = async (values: EventSearchParams) => {
    try {
      setEventsSearchParams(values);
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-4 mt-9">
          <div className="flex flex-1">
            <Input
              name="name"
              placeholder="Поиск"
              itemClassName="flex-1"
              controlElementClassName="border-r-0 rounded-r-none"
              startAdornment={<SearchIcon className="w-4 h-4 !top-0 !left-3" />}
            />
            <LocationInput
              name="location"
              valueLabel={locationName}
              isLoading={isLoading}
              itemClassName="flex-1"
              controlElementClassName="border-l-0 rounded-l-none"
              placeholder="Локация/Место"
              defaultMapCenterCoords={DEFAULT_LOCATION}
            />
          </div>
          <Button className="m-auto" type="submit">
            Поиск
          </Button>
        </div>
        <Collapsible className="group text-center mt-2">
          <CollapsibleTrigger>
            <ArrowIcon className="group-data-[state=open]:rotate-180 transition-transform" />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="flex  flex-wrap gap-4">
              <Dropdown
                isMulti
                name="sportIds"
                placeholder="Вид спорта"
                onChange={(selected) => {
                  console.log(selected);
                  // form.setValue("sportIds", selected.);
                }}
                options={Array.from(sportDictionary.values())}
                className="w-full md:w-1/4"
              />
              <div className="w-full md:w-1/4">
                <DatePicker name="date" placeholder="Дата" />
              </div>
              <Input name="distance" placeholder="Дистанция" itemClassName="w-full md:w-1/4" />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </form>
    </Form>
  );
};
