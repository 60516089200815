import { AthleteRoleEnum } from "@/api/generated";
import { Account } from "@/types/account";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { JwtToken } from "@/types/jwt-token";
import { OrganizationSummaryCard } from "@/types/organization";
import { SportLabelKey } from "@/types/sport";
import { createEvent, createStore, sample } from "effector";
import { jwtDecode } from "jwt-decode";
import { getAthleteMeFx } from "../athlete/effects";
import { getOrganizationMeFx } from "../organization/effects";
import { $sports } from "../sport/store";
import { loginFx, logoutFx, meFx } from "./effects";

const abbr = (value: string | undefined) => value?.at(0)?.toUpperCase() ?? "";

const hasToken = localStorage.getItem("accessToken") !== null

const ACCOUNT_INITIAL_STATE = {
  id: 0,
  email: "",
  password: "",
  emailConfirmed: false,
  role: AccountRole.ATHLETE,
};

const ACCOUNT_EXTENDED_INITIAL_STATE = {
  id: 0,
  firstName: "",
  lastName: "",
  avatar: "",
  role: AthleteRoleEnum.Athlete,
  location: {
    name: "",
    position: {
      latitude: 0,
      longitude: 0,
    },
  },
  isCoach: false,
  rating: 0,
  achievements: [],
  sports: [],
  email: "",
  emailConfirmed: false,
  sportIds: [],
  name: "",
};

const $account = createStore<Account>(ACCOUNT_INITIAL_STATE);
const $accountExtended = createStore<AthleteSummaryCard | OrganizationSummaryCard>(ACCOUNT_EXTENDED_INITIAL_STATE);
// derived store from account, will update automatically
const $accountRole = $account.map((account) => account.role);
const $accountAbbr = $accountExtended.map((account) =>
  account.role === AccountRole.ORGANIZATION
    ? abbr((account as OrganizationSummaryCard).name)
    : abbr((account as AthleteSummaryCard).firstName) + abbr((account as AthleteSummaryCard).lastName),
);
const $accountLoading = createStore<boolean>(false);

const $accountLoggedIn = createStore<boolean>(hasToken);

const setAccount = createEvent<Account>();
const setAccountRole = createEvent<AccountRole>();

$account.on(setAccount, (_, account) => account);
$account.on(setAccountRole, (state, role) => ({ ...state, role }));

$account.on(loginFx.doneData, (_, accessToken) => jwtDecode<JwtToken>(accessToken).account);
$account.on(logoutFx.doneData, () => ACCOUNT_INITIAL_STATE);

$accountLoading.on([loginFx.pending, meFx.pending], (_, pending) => pending);

$accountLoggedIn.on(loginFx.doneData, (_, accessToken) => !!accessToken);
$accountLoggedIn.on([loginFx.fail, logoutFx.doneData], () => false);

// TODO add sample to change the form

sample({
  clock: getAthleteMeFx.doneData,
  source: $sports,
  fn: (sports, athleteData) => {
    const athlete: AthleteSummaryCard = {
      ...athleteData,
      rating: 4,
      achievements: [],
      sports: athleteData.sportIds.map((sportId) => (sports.get(sportId)?.name as SportLabelKey) || ""),
    };
    return athlete;
  },
  target: [$account, $accountExtended],
});

sample({
  clock: getOrganizationMeFx.doneData,
  source: $sports,
  fn: (sports, organizationData) => {
    const organization: OrganizationSummaryCard = {
      ...organizationData,
      rating: 4,
      achievements: [],
      sports: organizationData.sportIds.map((sportId) => (sports.get(sportId)?.name as SportLabelKey) || ""),
    };
    return organization;
  },
  target: [$account, $accountExtended],
});



export {
  $account,
  $accountAbbr,
  $accountExtended,
  $accountLoading,
  $accountLoggedIn,
  $accountRole,
  setAccount,
  setAccountRole
};

