import { AthleteControllerApi, GetAvatarCategoryEnum } from "@/api/generated";
import { getAvatarByCategoryAndIdUrl } from "@/hooks/avatar";
import { AthleteSearchRequestParams, PaginatedResultAthleteSummaryWithAvatar } from "@/types/athlete";
import { RequestPayload } from "@/types/common";
import { SortablePageRequestParams } from "@/types/page";
import { createEffect } from "effector";

type SearchAthletes = (
  params: RequestPayload<AthleteControllerApi, SortablePageRequestParams<AthleteSearchRequestParams>>,
) => Promise<PaginatedResultAthleteSummaryWithAvatar>;

const searchAthletesFx = createEffect<SearchAthletes>(async ({ controller, payload }) => {
  try {
    const response = await controller.searchAthletes(
      payload.page,
      payload.size,
      payload.sort,
      payload.searchParams.latitude,
      payload.searchParams.longitude,
      payload.searchParams.sportIds,
      payload.searchParams.name,
    );

    const dataWithAvatar = await Promise.all(
      response.data.data.map(async (athlete) => ({
        ...athlete,
        avatar: await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
      })),
    );

    return {
      ...response.data,
      data: dataWithAvatar,
    };
  } catch (error) {
    console.log("Error in getAthletesFx", error);
    throw error;
  }
});

export { searchAthletesFx };
