import { EventControllerApi } from "@/api/generated";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import {
  Event,
  EventSearchParams,
  EventSearchRequestParams,
  ExternalEvent,
  NewEvent,
  NewExternalEvent,
} from "@/types/event";
import { SortablePageRequestParams } from "@/types/page";
import { useUnit } from "effector-react";
import { createEventFx, createExternalEventFx, deleteEventFx, getEventFx, updateEventFx, updateExternalEventFx } from "./effects";
import { searchEventsFx } from "./list/effects";

export const useEventApi = () => {
  const httpClient = useHttpClient();
  const controller = new EventControllerApi(undefined, undefined, httpClient);

  const getEventEffect = useUnit(getEventFx);
  const createEventEffect = useUnit(createEventFx);
  const createExternalEventEffect = useUnit(createExternalEventFx);
  const updateEventEffect = useUnit(updateEventFx);
  const updateExternalEventEffect = useUnit(updateExternalEventFx);
  const searchEventsEffect = useUnit(searchEventsFx);
  const deleteEventEffect = useUnit(deleteEventFx);

  const getEvent = async (eventId: number) => await getEventEffect({ controller, payload: { eventId } });

  const createEvent = async (event: NewEvent) => await createEventEffect({ controller, payload: { ...event } });

  const createExternalEvent = async (event: NewExternalEvent) =>
    await createExternalEventEffect({ controller, payload: { ...event } });

  const updateEvent = async (event: Event) => await updateEventEffect({ controller, payload: { ...event } });

  const updateExternalEvent = async (event: ExternalEvent) =>
    await updateExternalEventEffect({ controller, payload: { ...event } });

  const getEvents = async (params: SortablePageRequestParams<EventSearchParams>) => {
    const { page, size, sort, searchParams } = params;
    const payload: EventSearchRequestParams = {
      ...searchParams,
      latitude: params.searchParams.location?.latitude,
      longitude: params.searchParams.location?.longitude,
    };

    return await searchEventsEffect({
      controller,
      payload: { page, size, sort, searchParams: payload },
    });
  };

  const deleteEvent = async (eventId: number) => await deleteEventEffect({ controller, payload: { eventId } });

  return {
    createEvent,
    createExternalEvent,
    updateEvent,
    updateExternalEvent,
    getEvents,
    getEvent,
    deleteEvent,
  };
};
