import { AthleteControllerApi, FavoriteControllerApi, Meta } from "@/api/generated";
import { addFavoriteAthleteFx, getFavoriteAthletesFx, removeFavoriteAthleteFx } from '@/hooks/favorite/effects';
import { $httpClient } from '@/hooks/http-client/store';
import { $sports } from "@/hooks/sport/store";
import { AthleteListItemCard, AthleteSearchParams, AthleteSearchRequestParams } from "@/types/athlete";
import { RequestPayload } from '@/types/common';
import { SortablePageRequestParams } from '@/types/page';
import { createEvent, createStore, sample } from "effector";
import { createGate } from 'effector-react';
import { getAthleteTeamFx } from "../team";
import { searchAthletesFx } from "./effects";

const ATHLETE_LIST_INITIAL_STATE: AthleteListItemCard[] = [];

const ATHLETE_LIST_PAGE_META_INITIAL_STATE: Meta = { totalCount: 0, pageNumber: 1, pageSize: 10 };

const ATHLETE_SEARCH_PARAMS_INITIAL_STATE: AthleteSearchRequestParams = {
  sportIds: [],
  latitude: 0,
  longitude: 0,
};

const favoriteAthletesGate = createGate('favoriteAthletesGate');

const $athletes = createStore<AthleteListItemCard[]>(ATHLETE_LIST_INITIAL_STATE);
const $athletesPageMeta = createStore<Meta>(ATHLETE_LIST_PAGE_META_INITIAL_STATE);
const $athletesLoading = createStore<boolean>(false);
const $athletesSearchParams = createStore<AthleteSearchParams>(ATHLETE_SEARCH_PARAMS_INITIAL_STATE);

const setPageEvent = createEvent<number>();
const setPageSizeEvent = createEvent<number>();
const resetPageMetaEvent = createEvent();
const setSearchParams = createEvent<AthleteSearchParams>();

$athletesPageMeta.on(setPageEvent, (state, pageNumber) => ({ ...state, pageNumber }));
$athletesPageMeta.on(setPageSizeEvent, (state, pageSize) => ({ ...state, pageSize }));
$athletesPageMeta.on(resetPageMetaEvent, () => ATHLETE_LIST_PAGE_META_INITIAL_STATE);
$athletesSearchParams.on(setSearchParams, (_, params) => params);

$athletesLoading.on(searchAthletesFx, () => true);
$athletesLoading.on(searchAthletesFx.done, () => false);

sample({
  clock: searchAthletesFx.doneData,
  source: $sports,
  fn: (sports, data) =>
    data.data.map((athlete) => ({
      ...athlete,
      sports: athlete.sportIds.map((sportId) => sports.get(sportId)?.name || ""),
      isInFavorite: athlete.isInFavorite || false
    })),
  target: $athletes,
});

sample({
  clock: [getAthleteTeamFx.doneData, getFavoriteAthletesFx.doneData],
  source: { sports: $sports, onFavoritePage: favoriteAthletesGate.status },
  fn: ({ sports, onFavoritePage }, data) =>
    data.map((athleteData) => {
      const athlete: AthleteListItemCard = {
        ...athleteData,
        sports: athleteData.sportIds.map((sportId) => sports.get(sportId)?.name || ""),
        isInFavorite: onFavoritePage || athleteData.isInFavorite || false
      };

      return athlete;
    }),
  target: $athletes,
});

sample({
  clock: searchAthletesFx.doneData,
  fn: (data) => data.meta,
  target: $athletesPageMeta,
});

sample({
  clock: [addFavoriteAthleteFx.done, removeFavoriteAthleteFx.done],
  source: $athletes,
  fn: (athletes, { params }) =>
    athletes.map(
      (athlete) => athlete.id === params.payload.athleteId ?
        { ...athlete, isInFavorite: !athlete.isInFavorite } :
        athlete),
  target: $athletes,
});

// this part outplayed me, need to think how to make it better
sample({
  clock: [setPageEvent, setPageSizeEvent, setSearchParams],
  source: { searchParams: $athletesSearchParams, pageMeta: $athletesPageMeta, httpClient: $httpClient, onFavoritePage: favoriteAthletesGate.status },
  filter: ({ onFavoritePage }) => !onFavoritePage,
  fn: ({ searchParams, pageMeta, httpClient }) => {
    const controller = new AthleteControllerApi(undefined, undefined, httpClient);
    const params: AthleteSearchRequestParams = {
      latitude: searchParams.location?.latitude || 0,
      longitude: searchParams.location?.longitude || 0,
      sportIds: searchParams.sportIds,
      name: searchParams.name,
    };

    const requestPayload: RequestPayload<AthleteControllerApi, SortablePageRequestParams<AthleteSearchRequestParams>> = {
      controller, payload: { searchParams: params, page: pageMeta.pageNumber, size: pageMeta.pageSize, sort: [] }
    };

    return requestPayload;
  },
  target: searchAthletesFx,
});

sample({
  clock: [
    setPageEvent,
    setPageSizeEvent,
    setSearchParams,
    addFavoriteAthleteFx.doneData,
    removeFavoriteAthleteFx.doneData
  ],
  source: { searchParams: $athletesSearchParams, pageMeta: $athletesPageMeta, httpClient: $httpClient, onFavoritePage: favoriteAthletesGate.status },
  filter: ({ onFavoritePage }) => onFavoritePage,
  fn: ({ pageMeta, httpClient }) => {
    const controller = new FavoriteControllerApi(undefined, undefined, httpClient);

    const requestPayload: RequestPayload<FavoriteControllerApi, SortablePageRequestParams<void>> = {
      controller, payload: { page: pageMeta.pageNumber, size: pageMeta.pageSize, sort: [], searchParams: undefined }
    };

    return requestPayload;
  },
  target: getFavoriteAthletesFx,
});

export {
  $athletes,
  $athletesLoading,
  $athletesPageMeta,
  $athletesSearchParams,
  favoriteAthletesGate,
  resetPageMetaEvent,
  setPageEvent,
  setPageSizeEvent,
  setSearchParams
};

