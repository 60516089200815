import { passwordValidation } from "@/utils/regex";
import { z } from "zod";

export const registerSchema = z.object({
  email: z.string().email({ message: "Некорректный email" }).min(2),
  password: z.string().min(8, { message: "8 и более символов" }).regex(passwordValidation, {
    message: "Используйте строчные и прописные буквы, цифры и специальные символы",
  }),
  approved: z.boolean().refine((val) => val === true, {
    message: "Требуется ваше согласие прежде чем можно продолжить",
  }),
});
