import { Checkbox } from "@/components/rhf/checkbox";
import { DatePicker } from "@/components/rhf/date-picker";
import { Input } from "@/components/rhf/input";
import { LocationInput } from "@/components/rhf/location-input";
import { DEFAULT_LOCATION } from "@/consts/location";
import { useEventApi } from "@/hooks/event/use-event-api";
import { useResetLocationName } from "@/hooks/location";
import { useLocationApi } from "@/hooks/location/use-location-api";
import { useLocationName } from "@/hooks/location/use-location-name";
import { useSports } from "@/hooks/sport/use-sports";
import { Image } from "@/icons/image";
import { eventSchema, externalEventSchema } from "@/schemas/event.schema";
import { Event, ExternalEvent, NewEventFormData } from "@/types/event";
import { Button } from "@/ui-kit/button";
import { Collapsible, CollapsibleTrigger } from "@/ui-kit/collapsible";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CollapsibleContent } from "@radix-ui/react-collapsible";
import { ComponentProps, FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Dropdown } from "../dropdown";
import { RHFSelect } from "../rhf/select";

const FORMAT_OPTIONS: ComponentProps<typeof RHFSelect>["options"] = [
  { value: "online", label: "Онлайн" },
  { value: "offline", label: "Оффлайн" },
];

const TRAINING_OPTIONS: ComponentProps<typeof RHFSelect>["options"] = [
  { value: "training", label: "Тренировка" },
  { value: "run", label: "Гонка" },
];

type CreateEventFormProps = {
  external?: boolean;
  onSubmitCallback?: (id?: number) => void;
};

export const CreateEventForm: FC<CreateEventFormProps> = ({ external, onSubmitCallback }) => {
  const { createEvent, createExternalEvent } = useEventApi();
  const { getLocationName } = useLocationApi();
  const resetLocationName = useResetLocationName();
  const sportDictionary = useSports();
  const currentSchema = external ? externalEventSchema : eventSchema;
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<NewEventFormData>({
    resolver: zodResolver(currentSchema.omit({ id: true })),
    defaultValues: {
      isPublic: true,
    },
  });

  const location = form.watch("location");

  useEffect(() => {
    if (location) {
      getLocationName(location);
    } else {
      resetLocationName();
    }
  }, [location]);

  const { data: locationName, isLoading } = useLocationName();

  const onSubmit = async (values: NewEventFormData) => {
    try {
      const result = external ? await createExternalEvent(values as ExternalEvent) : await createEvent(values as Event);
      onSubmitCallback && onSubmitCallback(result?.id);
      // return navigate("/login");
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        {external && (
          <div className="mb-2">
            <Input
              name="externalEventUrl"
              controlElementClassName="mt-2"
              labelClassName="font-bold"
              placeholder="*Ссылка на событие"
            />
            <Input
              name="externalRegistrationUrl"
              controlElementClassName="mt-2"
              labelClassName="font-bold"
              placeholder="*Ссылка на регистрацию"
            />
          </div>
        )}
        <div className="flex relative">
          {form.getValues().avatar && (
            <Image
              src={form.getValues().avatar}
              className="absolute left-2/4 -translate-x-2/4 w-16 z-0 top-2/4 -translate-y-2/4"
              alt="Avatar"
            />
          )}
          <Input
            name="avatar"
            type="file"
            itemClassName="m-auto z-10 relative"
            labelClassName="font-bold"
            placeholder="Изображение события"
            onValueChange={(file) => {
              console.log("avatar prop updated with: ", file);
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            name="name"
            controlElementClassName="mt-2"
            itemClassName="flex-1"
            labelClassName="font-bold"
            placeholder="*Название"
          />
          <Dropdown
            name="sportId"
            placeholder="Вид спорта"
            className="mt-2 flex-1"
            options={Array.from(sportDictionary.values())}
            onChange={(selected) => {
              if (selected) {
                form.setValue("sportId", selected.value);
              } else {
                form.resetField("sportId");
              }
            }}
          />
        </div>
        <LocationInput
          name="location"
          valueLabel={locationName}
          isLoading={isLoading}
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
          defaultMapCenterCoords={DEFAULT_LOCATION}
        />
        <DatePicker name="date" placeholder="*Время" controlElementClassName="cursor-pointer" itemClassName="mt-2" />
        <Input name="description" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="Описание" />
        <Input name="socialMedia" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="Соцсети" />
        <Collapsible className="flex flex-col" open={isOpen} onOpenChange={setIsOpen}>
          <CollapsibleContent>
            <Input name="distance" controlElementClassName="mt-2" labelClassName="font-bold" placeholder="Дистанция" />
            <div className="flex gap-4 justify-center mt-2">
              <div className="flex-1 py-1 px-4 border border-blue-200 rounded-lg">
                <Checkbox
                  name="isPublic"
                  itemClassName="flex"
                  checked={!!form.getValues().isPublic}
                  onValueChange={(e) => {
                    form.setValue("isPublic", !!e);
                    form.trigger("isPublic");
                  }}
                >
                  <span className="ft1">Публичное</span>
                </Checkbox>
              </div>
              <div className="flex-1 py-1 px-4 border border-blue-200 rounded-lg">
                <Checkbox
                  name="public"
                  itemClassName="flex"
                  checked={!form.getValues().isPublic}
                  onValueChange={(e) => {
                    form.setValue("isPublic", !e);
                    form.trigger("isPublic");
                  }}
                >
                  <span className="ft1">Непубличное</span>
                </Checkbox>
              </div>
            </div>
            <RHFSelect
              name="format"
              controlElementClassName="w-full mt-2"
              labelClassName="font-bold"
              options={FORMAT_OPTIONS}
              placeholder="Формат занятий (онлайн/офлайн)"
            />
            <RHFSelect
              name="training"
              controlElementClassName="w-full mt-2"
              labelClassName="font-bold"
              options={TRAINING_OPTIONS}
              placeholder="Гонка/Тренировка"
            />
          </CollapsibleContent>
          <span className="ft1 mt-1">*Обязательные поля</span>
          <CollapsibleTrigger asChild className="mx-auto">
            <Button variant="linkButton" type="button" className="pt-2.5">
              {isOpen ? "Скрыть" : "Показать ещё"}
            </Button>
          </CollapsibleTrigger>
        </Collapsible>

        {form.formState.errors.root && (
          <FormMessage className="self-center">{form.formState.errors.root.message}</FormMessage>
        )}
        <div className="flex justify-center py-2.5">
          <Button type="submit">Создать событие</Button>
        </div>
      </form>
    </Form>
  );
};
