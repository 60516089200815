import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <main className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          {/* @ts-ignore */}
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
      <Footer />
    </main>
  );
};

export default ErrorPage;
