import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";
import { EventList } from "@/components/list/event-list";
import { Pagination } from "@/components/pagination";
import { useEventApi } from "@/hooks/event";
import { useEvents, useEventsPageMeta } from "@/hooks/event/list";
import { useEventsSetPageMeta } from "@/hooks/event/list/use-events-set-page-meta";
import { useEffect } from "react";

const FILTER_OPTIONS = [{ value: "all", label: "Все" }];

const Events = () => {
  const { data, isLoading } = useEvents();
  const { getEvents } = useEventApi();
  const { totalCount, pageNumber, pagesNum } = useEventsPageMeta();
  const { setPage } = useEventsSetPageMeta();

  useEffect(() => {
    getEvents({ page: 1, size: 10, searchParams: {} });
  }, []);

  const filterChangeHandler = (value: string) => {
    console.log(value);
  };

  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <div className="content-padding-64 md:content-padding-56 mt-12">
        <EventList
          data={data}
          isLoading={isLoading}
          total={totalCount}
          filterOptions={FILTER_OPTIONS}
          onFilterChange={filterChangeHandler}
        />
        {pagesNum > 1 && <Pagination total={pagesNum} onChange={setPage} current={pageNumber} />}
      </div>
    </div>
  );
};

export default Events;
