import React from "react";

export const EditIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="25"
      height="25"
      viewBox="0 0 37 37"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g filter="url(#filter0_d_2917_6794)">
        <path
          d="M30.2904 10.5009L26.4991 6.70968C26.0415 6.25201 25.4331 6 24.7859 6C24.1387 6 23.5303 6.25201 23.0726 6.70967L7.20249 22.5798C7.05185 22.7304 6.95475 22.9263 6.92613 23.1374L6.00885 29.8952C5.96797 30.1966 6.07018 30.4997 6.28526 30.7148C6.4691 30.8986 6.7173 31 6.97378 31C7.01729 31 7.06108 30.9971 7.10483 30.9911L13.8626 30.0739C14.0737 30.0452 14.2696 29.9482 14.4202 29.7975L30.2904 13.9275C30.748 13.4698 31 12.8614 31 12.2142C31 11.567 30.748 10.9585 30.2904 10.5009ZM13.2752 28.1881L8.11089 28.889L8.81189 23.7247L20.0579 12.4788L24.5212 16.9421L13.2752 28.1881ZM28.9131 12.5502L25.8984 15.5649L21.4351 11.1016L24.4498 8.0869C24.5709 7.96577 24.7123 7.94767 24.7859 7.94767C24.8594 7.94767 25.0008 7.96577 25.122 8.0869L28.9132 11.8781C29.0343 11.9992 29.0524 12.1407 29.0524 12.2142C29.0524 12.2877 29.0343 12.4291 28.9131 12.5502Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
);
