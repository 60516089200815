/* tslint:disable */
/* eslint-disable */
/**
 * Let\'s Sport DEV API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountLike
 */
export interface AccountLike {
    /**
     * 
     * @type {string}
     * @memberof AccountLike
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof AccountLike
     */
    'location': Location;
    /**
     * 
     * @type {number}
     * @memberof AccountLike
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountLike
     */
    'role': AccountLikeRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountLike
     */
    'moderationStatus': AccountLikeModerationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountLike
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountLike
     */
    'emailConfirmed': boolean;
}

export const AccountLikeRoleEnum = {
    Athlete: 'ATHLETE',
    Organization: 'ORGANIZATION',
    Coach: 'COACH',
    Admin: 'ADMIN'
} as const;

export type AccountLikeRoleEnum = typeof AccountLikeRoleEnum[keyof typeof AccountLikeRoleEnum];
export const AccountLikeModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type AccountLikeModerationStatusEnum = typeof AccountLikeModerationStatusEnum[keyof typeof AccountLikeModerationStatusEnum];

/**
 * 
 * @export
 * @interface Athlete
 */
export interface Athlete {
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Athlete
     */
    'emailConfirmed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof Athlete
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof Athlete
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Athlete
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Athlete
     */
    'isCoach': boolean;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'moderationStatus': AthleteModerationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'role': AthleteRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'name': string;
}

export const AthleteModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type AthleteModerationStatusEnum = typeof AthleteModerationStatusEnum[keyof typeof AthleteModerationStatusEnum];
export const AthleteRoleEnum = {
    Athlete: 'ATHLETE',
    Organization: 'ORGANIZATION',
    Coach: 'COACH',
    Admin: 'ADMIN'
} as const;

export type AthleteRoleEnum = typeof AthleteRoleEnum[keyof typeof AthleteRoleEnum];

/**
 * 
 * @export
 * @interface AthleteInfo
 */
export interface AthleteInfo {
    /**
     * 
     * @type {number}
     * @memberof AthleteInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AthleteInfo
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteInfo
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof AthleteInfo
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof AthleteInfo
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AthleteInfo
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AthleteInfo
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteInfo
     */
    'isCoach': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteInfo
     */
    'isInTeam'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteInfo
     */
    'isInFavorite'?: boolean;
}
/**
 * 
 * @export
 * @interface AthleteSummary
 */
export interface AthleteSummary {
    /**
     * 
     * @type {number}
     * @memberof AthleteSummary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AthleteSummary
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteSummary
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof AthleteSummary
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof AthleteSummary
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteSummary
     */
    'isCoach': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteSummary
     */
    'isInFavorite'?: boolean;
}
/**
 * 
 * @export
 * @interface Avatar
 */
export interface Avatar {
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    'data'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Avatar
     */
    'remove'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAthleteRequest
 */
export interface CreateAthleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'lastName': string;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof CreateAthleteRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAthleteRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAthleteRequest
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Avatar}
     * @memberof CreateAthleteRequest
     */
    'avatar'?: Avatar;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAthleteRequest
     */
    'isCoach'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateEventRequest
 */
export interface CreateOrUpdateEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEventRequest
     */
    'sportId': number;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof CreateOrUpdateEventRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEventRequest
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateEventRequest
     */
    'isPublic': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateEventRequest
     */
    'socialMedia'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'training'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateExternalEventRequest
 */
export interface CreateOrUpdateExternalEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'sportId': number;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'isPublic': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'socialMedia'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'training'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'externalEventUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'externalRegistrationUrl': string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdatePositionRequest
 */
export interface CreateOrUpdatePositionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdatePositionRequest
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdatePositionRequest
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'name': string;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof CreateOrganizationRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateOrganizationRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Avatar}
     * @memberof CreateOrganizationRequest
     */
    'avatar'?: Avatar;
}
/**
 * 
 * @export
 * @interface EmailExistsRequest
 */
export interface EmailExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailExistsRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ErrorResult
 */
export interface ErrorResult {
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'creatorId': number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof Event
     */
    'location': Location;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'sportId': number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'isPublic': boolean;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'externalEventUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'externalRegistrationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'moderationStatus': EventModerationStatusEnum;
}

export const EventModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type EventModerationStatusEnum = typeof EventModerationStatusEnum[keyof typeof EventModerationStatusEnum];

/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof EventInfo
     */
    'location': Location;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    'sportId': number;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isPublic': boolean;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'externalEventUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'externalRegistrationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'moderationStatus': EventInfoModerationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isInFavorite'?: boolean;
}

export const EventInfoModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type EventInfoModerationStatusEnum = typeof EventInfoModerationStatusEnum[keyof typeof EventInfoModerationStatusEnum];

/**
 * 
 * @export
 * @interface EventSummary
 */
export interface EventSummary {
    /**
     * 
     * @type {number}
     * @memberof EventSummary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventSummary
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof EventSummary
     */
    'location': Location;
    /**
     * 
     * @type {number}
     * @memberof EventSummary
     */
    'sportId': number;
    /**
     * 
     * @type {string}
     * @memberof EventSummary
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof EventSummary
     */
    'isInFavorite'?: boolean;
}
/**
 * 
 * @export
 * @interface FeedItem
 */
export interface FeedItem {
    /**
     * 
     * @type {number}
     * @memberof FeedItem
     */
    'accountId': number;
    /**
     * 
     * @type {number}
     * @memberof FeedItem
     */
    'eventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedItem
     */
    'type': FeedItemTypeEnum;
    /**
     * 
     * @type {FeedItemDetails}
     * @memberof FeedItem
     */
    'details': FeedItemDetails;
    /**
     * 
     * @type {string}
     * @memberof FeedItem
     */
    'createdAt': string;
}

export const FeedItemTypeEnum = {
    AttendEvent: 'ATTEND_EVENT',
    AttendedEvent: 'ATTENDED_EVENT',
    AddEvent: 'ADD_EVENT',
    UpdateEvent: 'UPDATE_EVENT',
    AddToTeam: 'ADD_TO_TEAM',
    AddCoach: 'ADD_COACH',
    AddToFavoriteEvent: 'ADD_TO_FAVORITE_EVENT'
} as const;

export type FeedItemTypeEnum = typeof FeedItemTypeEnum[keyof typeof FeedItemTypeEnum];

/**
 * 
 * @export
 * @interface FeedItemDetails
 */
export interface FeedItemDetails {
    /**
     * 
     * @type {string}
     * @memberof FeedItemDetails
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof FeedItemDetails
     */
    'eventName'?: string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'name'?: string;
    /**
     * 
     * @type {Position}
     * @memberof Location
     */
    'position': Position;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'emailConfirmed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof Organization
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof Organization
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'moderationStatus': OrganizationModerationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'role': OrganizationRoleEnum;
}

export const OrganizationModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type OrganizationModerationStatusEnum = typeof OrganizationModerationStatusEnum[keyof typeof OrganizationModerationStatusEnum];
export const OrganizationRoleEnum = {
    Athlete: 'ATHLETE',
    Organization: 'ORGANIZATION',
    Coach: 'COACH',
    Admin: 'ADMIN'
} as const;

export type OrganizationRoleEnum = typeof OrganizationRoleEnum[keyof typeof OrganizationRoleEnum];

/**
 * 
 * @export
 * @interface PaginatedResultAthlete
 */
export interface PaginatedResultAthlete {
    /**
     * 
     * @type {Array<Athlete>}
     * @memberof PaginatedResultAthlete
     */
    'data': Array<Athlete>;
    /**
     * 
     * @type {Meta}
     * @memberof PaginatedResultAthlete
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface PaginatedResultAthleteSummary
 */
export interface PaginatedResultAthleteSummary {
    /**
     * 
     * @type {Array<AthleteSummary>}
     * @memberof PaginatedResultAthleteSummary
     */
    'data': Array<AthleteSummary>;
    /**
     * 
     * @type {Meta}
     * @memberof PaginatedResultAthleteSummary
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface PaginatedResultEvent
 */
export interface PaginatedResultEvent {
    /**
     * 
     * @type {Array<Event>}
     * @memberof PaginatedResultEvent
     */
    'data': Array<Event>;
    /**
     * 
     * @type {Meta}
     * @memberof PaginatedResultEvent
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface PaginatedResultEventSummary
 */
export interface PaginatedResultEventSummary {
    /**
     * 
     * @type {Array<EventSummary>}
     * @memberof PaginatedResultEventSummary
     */
    'data': Array<EventSummary>;
    /**
     * 
     * @type {Meta}
     * @memberof PaginatedResultEventSummary
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface ReverseLocationResult
 */
export interface ReverseLocationResult {
    /**
     * 
     * @type {string}
     * @memberof ReverseLocationResult
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Sport
 */
export interface Sport {
    /**
     * 
     * @type {number}
     * @memberof Sport
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sport
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAthleteRequest
 */
export interface UpdateAthleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAthleteRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAthleteRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAthleteRequest
     */
    'lastName': string;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof UpdateAthleteRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAthleteRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAthleteRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAthleteRequest
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Avatar}
     * @memberof UpdateAthleteRequest
     */
    'avatar'?: Avatar;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'name': string;
    /**
     * 
     * @type {CreateOrUpdatePositionRequest}
     * @memberof UpdateOrganizationRequest
     */
    'location': CreateOrUpdatePositionRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateOrganizationRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Avatar}
     * @memberof UpdateOrganizationRequest
     */
    'avatar'?: Avatar;
}

/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsByEmail: async (emailExistsRequest: EmailExistsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailExistsRequest' is not null or undefined
            assertParamExists('existsByEmail', 'emailExistsRequest', emailExistsRequest)
            const localVarPath = `/api/account/email/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailExistsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/api/account/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAthlete: async (createAthleteRequest: CreateAthleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAthleteRequest' is not null or undefined
            assertParamExists('registerAthlete', 'createAthleteRequest', createAthleteRequest)
            const localVarPath = `/api/account/register/athlete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAthleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerOrganization: async (createOrganizationRequest: CreateOrganizationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            assertParamExists('registerOrganization', 'createOrganizationRequest', createOrganizationRequest)
            const localVarPath = `/api/account/register/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountLike>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.accountMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsByEmail(emailExistsRequest: EmailExistsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsByEmail(emailExistsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.existsByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAthlete(createAthleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.registerAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerOrganization(createOrganizationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.registerOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe(options?: any): AxiosPromise<AccountLike> {
            return localVarFp.accountMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsByEmail(emailExistsRequest: EmailExistsRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.existsByEmail(emailExistsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: any): AxiosPromise<Athlete> {
            return localVarFp.registerAthlete(createAthleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.registerOrganization(createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountMe(options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).accountMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailExistsRequest} emailExistsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public existsByEmail(emailExistsRequest: EmailExistsRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).existsByEmail(emailExistsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAthleteRequest} createAthleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).registerAthlete(createAthleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrganizationRequest} createOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).registerOrganization(createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AthleteAdminControllerApi - axios parameter creator
 * @export
 */
export const AthleteAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAthlete: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('adminDeleteAthlete', 'athleteId', athleteId)
            const localVarPath = `/api/admin/athlete/{athleteId}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAthlete: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('adminGetAthlete', 'athleteId', athleteId)
            const localVarPath = `/api/admin/athlete/{athleteId}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {AdminSearchAthletesModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSearchAthletes: async (page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, moderationStatus?: AdminSearchAthletesModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/athlete/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (moderationStatus !== undefined) {
                localVarQueryParameter['moderationStatus'] = moderationStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {AdminUpdateAthleteModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAthleteModerationStatus: async (athleteId: number, moderationStatus: AdminUpdateAthleteModerationStatusModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('adminUpdateAthleteModerationStatus', 'athleteId', athleteId)
            // verify required parameter 'moderationStatus' is not null or undefined
            assertParamExists('adminUpdateAthleteModerationStatus', 'moderationStatus', moderationStatus)
            const localVarPath = `/api/admin/athlete/{athleteId}/moderation/{moderationStatus}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)))
                .replace(`{${"moderationStatus"}}`, encodeURIComponent(String(moderationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthleteAdminControllerApi - functional programming interface
 * @export
 */
export const AthleteAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthleteAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteAthlete(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteAthlete(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteAdminControllerApi.adminDeleteAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAthlete(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAthlete(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteAdminControllerApi.adminGetAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {AdminSearchAthletesModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSearchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, moderationStatus?: AdminSearchAthletesModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultAthlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSearchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteAdminControllerApi.adminSearchAthletes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {AdminUpdateAthleteModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAthleteModerationStatus(athleteId: number, moderationStatus: AdminUpdateAthleteModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAthleteModerationStatus(athleteId, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteAdminControllerApi.adminUpdateAthleteModerationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AthleteAdminControllerApi - factory interface
 * @export
 */
export const AthleteAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthleteAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAthlete(athleteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteAthlete(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAthlete(athleteId: number, options?: any): AxiosPromise<Athlete> {
            return localVarFp.adminGetAthlete(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {AdminSearchAthletesModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSearchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, moderationStatus?: AdminSearchAthletesModerationStatusEnum, options?: any): AxiosPromise<PaginatedResultAthlete> {
            return localVarFp.adminSearchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, moderationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {AdminUpdateAthleteModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAthleteModerationStatus(athleteId: number, moderationStatus: AdminUpdateAthleteModerationStatusModerationStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateAthleteModerationStatus(athleteId, moderationStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthleteAdminControllerApi - object-oriented interface
 * @export
 * @class AthleteAdminControllerApi
 * @extends {BaseAPI}
 */
export class AthleteAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteAdminControllerApi
     */
    public adminDeleteAthlete(athleteId: number, options?: RawAxiosRequestConfig) {
        return AthleteAdminControllerApiFp(this.configuration).adminDeleteAthlete(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteAdminControllerApi
     */
    public adminGetAthlete(athleteId: number, options?: RawAxiosRequestConfig) {
        return AthleteAdminControllerApiFp(this.configuration).adminGetAthlete(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [locationLatitude] 
     * @param {number} [locationLongitude] 
     * @param {Array<number>} [sportIds] 
     * @param {string} [name] 
     * @param {AdminSearchAthletesModerationStatusEnum} [moderationStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteAdminControllerApi
     */
    public adminSearchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, moderationStatus?: AdminSearchAthletesModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return AthleteAdminControllerApiFp(this.configuration).adminSearchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} athleteId 
     * @param {AdminUpdateAthleteModerationStatusModerationStatusEnum} moderationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteAdminControllerApi
     */
    public adminUpdateAthleteModerationStatus(athleteId: number, moderationStatus: AdminUpdateAthleteModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return AthleteAdminControllerApiFp(this.configuration).adminUpdateAthleteModerationStatus(athleteId, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminSearchAthletesModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminSearchAthletesModerationStatusEnum = typeof AdminSearchAthletesModerationStatusEnum[keyof typeof AdminSearchAthletesModerationStatusEnum];
/**
 * @export
 */
export const AdminUpdateAthleteModerationStatusModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminUpdateAthleteModerationStatusModerationStatusEnum = typeof AdminUpdateAthleteModerationStatusModerationStatusEnum[keyof typeof AdminUpdateAthleteModerationStatusModerationStatusEnum];


/**
 * AthleteControllerApi - axios parameter creator
 * @export
 */
export const AthleteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        athleteMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/athlete/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthlete: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getAthlete', 'athleteId', athleteId)
            const localVarPath = `/api/athlete/{athleteId}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAthletes: async (page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/athlete/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAthleteRequest} updateAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAthlete: async (updateAthleteRequest: UpdateAthleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAthleteRequest' is not null or undefined
            assertParamExists('updateAthlete', 'updateAthleteRequest', updateAthleteRequest)
            const localVarPath = `/api/athlete/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAthleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthleteControllerApi - functional programming interface
 * @export
 */
export const AthleteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthleteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async athleteMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.athleteMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.athleteMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthlete(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AthleteInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthlete(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.getAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultAthleteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.searchAthletes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAthleteRequest} updateAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAthlete(updateAthleteRequest: UpdateAthleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAthlete(updateAthleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.updateAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AthleteControllerApi - factory interface
 * @export
 */
export const AthleteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthleteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        athleteMe(options?: any): AxiosPromise<Athlete> {
            return localVarFp.athleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthlete(athleteId: number, options?: any): AxiosPromise<AthleteInfo> {
            return localVarFp.getAthlete(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, options?: any): AxiosPromise<PaginatedResultAthleteSummary> {
            return localVarFp.searchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAthleteRequest} updateAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAthlete(updateAthleteRequest: UpdateAthleteRequest, options?: any): AxiosPromise<Athlete> {
            return localVarFp.updateAthlete(updateAthleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthleteControllerApi - object-oriented interface
 * @export
 * @class AthleteControllerApi
 * @extends {BaseAPI}
 */
export class AthleteControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public athleteMe(options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).athleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public getAthlete(athleteId: number, options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).getAthlete(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [locationLatitude] 
     * @param {number} [locationLongitude] 
     * @param {Array<number>} [sportIds] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public searchAthletes(page?: number, size?: number, sort?: Array<string>, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, name?: string, options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).searchAthletes(page, size, sort, locationLatitude, locationLongitude, sportIds, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAthleteRequest} updateAthleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public updateAthlete(updateAthleteRequest: UpdateAthleteRequest, options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).updateAthlete(updateAthleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AthleteTeamControllerApi - axios parameter creator
 * @export
 */
export const AthleteTeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAthleteToTeam: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('addAthleteToTeam', 'athleteId', athleteId)
            const localVarPath = `/api/athlete/{athleteId}/team`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAthleteFromTeam: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('deleteAthleteFromTeam', 'athleteId', athleteId)
            const localVarPath = `/api/athlete/{athleteId}/team`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteTeam: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/athlete/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthleteTeamControllerApi - functional programming interface
 * @export
 */
export const AthleteTeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthleteTeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAthleteToTeam(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAthleteToTeam(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteTeamControllerApi.addAthleteToTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAthleteFromTeam(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAthleteFromTeam(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteTeamControllerApi.deleteAthleteFromTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthleteTeam(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AthleteSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthleteTeam(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteTeamControllerApi.getAthleteTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AthleteTeamControllerApi - factory interface
 * @export
 */
export const AthleteTeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthleteTeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAthleteToTeam(athleteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.addAthleteToTeam(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAthleteFromTeam(athleteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAthleteFromTeam(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteTeam(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<AthleteSummary>> {
            return localVarFp.getAthleteTeam(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthleteTeamControllerApi - object-oriented interface
 * @export
 * @class AthleteTeamControllerApi
 * @extends {BaseAPI}
 */
export class AthleteTeamControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteTeamControllerApi
     */
    public addAthleteToTeam(athleteId: number, options?: RawAxiosRequestConfig) {
        return AthleteTeamControllerApiFp(this.configuration).addAthleteToTeam(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteTeamControllerApi
     */
    public deleteAthleteFromTeam(athleteId: number, options?: RawAxiosRequestConfig) {
        return AthleteTeamControllerApiFp(this.configuration).deleteAthleteFromTeam(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteTeamControllerApi
     */
    public getAthleteTeam(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return AthleteTeamControllerApiFp(this.configuration).getAthleteTeam(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AvatarControllerApi - axios parameter creator
 * @export
 */
export const AvatarControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatar: async (category: GetAvatarCategoryEnum, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getAvatar', 'category', category)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatar', 'id', id)
            const localVarPath = `/api/avatar/{category}/{id}`
                .replace(`{${"category"}}`, encodeURIComponent(String(category)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvatarControllerApi - functional programming interface
 * @export
 */
export const AvatarControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvatarControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatar(category: GetAvatarCategoryEnum, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatar(category, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvatarControllerApi.getAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AvatarControllerApi - factory interface
 * @export
 */
export const AvatarControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvatarControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatar(category: GetAvatarCategoryEnum, id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAvatar(category, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvatarControllerApi - object-oriented interface
 * @export
 * @class AvatarControllerApi
 * @extends {BaseAPI}
 */
export class AvatarControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAvatarCategoryEnum} category 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarControllerApi
     */
    public getAvatar(category: GetAvatarCategoryEnum, id: number, options?: RawAxiosRequestConfig) {
        return AvatarControllerApiFp(this.configuration).getAvatar(category, id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAvatarCategoryEnum = {
    Account: 'ACCOUNT',
    Event: 'EVENT'
} as const;
export type GetAvatarCategoryEnum = typeof GetAvatarCategoryEnum[keyof typeof GetAvatarCategoryEnum];


/**
 * EventAdminControllerApi - axios parameter creator
 * @export
 */
export const EventAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminDeleteEvent', 'eventId', eventId)
            const localVarPath = `/api/admin/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {AdminGetAllEventsModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEvents: async (page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, moderationStatus?: AdminGetAllEventsModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/event/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }

            if (moderationStatus !== undefined) {
                localVarQueryParameter['moderationStatus'] = moderationStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminGetEvent', 'eventId', eventId)
            const localVarPath = `/api/admin/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {AdminUpdateEventModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateEventModerationStatus: async (eventId: number, moderationStatus: AdminUpdateEventModerationStatusModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminUpdateEventModerationStatus', 'eventId', eventId)
            // verify required parameter 'moderationStatus' is not null or undefined
            assertParamExists('adminUpdateEventModerationStatus', 'moderationStatus', moderationStatus)
            const localVarPath = `/api/admin/event/{eventId}/moderation/{moderationStatus}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"moderationStatus"}}`, encodeURIComponent(String(moderationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventAdminControllerApi - functional programming interface
 * @export
 */
export const EventAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminDeleteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {AdminGetAllEventsModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, moderationStatus?: AdminGetAllEventsModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminGetAllEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminGetEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {AdminUpdateEventModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateEventModerationStatus(eventId: number, moderationStatus: AdminUpdateEventModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateEventModerationStatus(eventId, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminUpdateEventModerationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventAdminControllerApi - factory interface
 * @export
 */
export const EventAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {AdminGetAllEventsModerationStatusEnum} [moderationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, moderationStatus?: AdminGetAllEventsModerationStatusEnum, options?: any): AxiosPromise<PaginatedResultEvent> {
            return localVarFp.adminGetAllEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, moderationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEvent(eventId: number, options?: any): AxiosPromise<Event> {
            return localVarFp.adminGetEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {AdminUpdateEventModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateEventModerationStatus(eventId: number, moderationStatus: AdminUpdateEventModerationStatusModerationStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateEventModerationStatus(eventId, moderationStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventAdminControllerApi - object-oriented interface
 * @export
 * @class EventAdminControllerApi
 * @extends {BaseAPI}
 */
export class EventAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminDeleteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminDeleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [name] 
     * @param {number} [locationLatitude] 
     * @param {number} [locationLongitude] 
     * @param {Array<number>} [sportIds] 
     * @param {string} [date] 
     * @param {number} [distance] 
     * @param {AdminGetAllEventsModerationStatusEnum} [moderationStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, moderationStatus?: AdminGetAllEventsModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminGetAllEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminGetEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminGetEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {AdminUpdateEventModerationStatusModerationStatusEnum} moderationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminUpdateEventModerationStatus(eventId: number, moderationStatus: AdminUpdateEventModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminUpdateEventModerationStatus(eventId, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminGetAllEventsModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminGetAllEventsModerationStatusEnum = typeof AdminGetAllEventsModerationStatusEnum[keyof typeof AdminGetAllEventsModerationStatusEnum];
/**
 * @export
 */
export const AdminUpdateEventModerationStatusModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminUpdateEventModerationStatusModerationStatusEnum = typeof AdminUpdateEventModerationStatusModerationStatusEnum[keyof typeof AdminUpdateEventModerationStatusModerationStatusEnum];


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createOrUpdateEventRequest: CreateOrUpdateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateEventRequest' is not null or undefined
            assertParamExists('createEvent', 'createOrUpdateEventRequest', createOrUpdateEventRequest)
            const localVarPath = `/api/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalEvent: async (createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateExternalEventRequest' is not null or undefined
            assertParamExists('createExternalEvent', 'createOrUpdateExternalEventRequest', createOrUpdateExternalEventRequest)
            const localVarPath = `/api/event/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEvent', 'eventId', eventId)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEvent', 'eventId', eventId)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/event/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateEvent', 'eventId', eventId)
            // verify required parameter 'createOrUpdateEventRequest' is not null or undefined
            assertParamExists('updateEvent', 'createOrUpdateEventRequest', createOrUpdateEventRequest)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalEvent: async (eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateExternalEvent', 'eventId', eventId)
            // verify required parameter 'createOrUpdateExternalEventRequest' is not null or undefined
            assertParamExists('updateExternalEvent', 'createOrUpdateExternalEventRequest', createOrUpdateExternalEventRequest)
            const localVarPath = `/api/event/external/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createOrUpdateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.createEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExternalEvent(createOrUpdateExternalEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.createExternalEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.deleteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.getEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultEventSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.searchEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(eventId, createOrUpdateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.updateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.updateExternalEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.createEvent(createOrUpdateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.createExternalEvent(createOrUpdateExternalEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(eventId: number, options?: any): AxiosPromise<EventInfo> {
            return localVarFp.getEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [locationLatitude] 
         * @param {number} [locationLongitude] 
         * @param {Array<number>} [sportIds] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, options?: any): AxiosPromise<PaginatedResultEventSummary> {
            return localVarFp.searchEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.updateEvent(eventId, createOrUpdateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).createEvent(createOrUpdateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).createExternalEvent(createOrUpdateExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public deleteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).deleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [name] 
     * @param {number} [locationLatitude] 
     * @param {number} [locationLongitude] 
     * @param {Array<number>} [sportIds] 
     * @param {string} [date] 
     * @param {number} [distance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public searchEvents(page?: number, size?: number, sort?: Array<string>, name?: string, locationLatitude?: number, locationLongitude?: number, sportIds?: Array<number>, date?: string, distance?: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).searchEvents(page, size, sort, name, locationLatitude, locationLongitude, sportIds, date, distance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).updateEvent(eventId, createOrUpdateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FavoriteControllerApi - axios parameter creator
 * @export
 */
export const FavoriteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteAthlete: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('addFavoriteAthlete', 'athleteId', athleteId)
            const localVarPath = `/api/favorite/athlete/{athleteId}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('addFavoriteEvent', 'eventId', eventId)
            const localVarPath = `/api/favorite/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteAthlete: async (athleteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('deleteFavoriteAthlete', 'athleteId', athleteId)
            const localVarPath = `/api/favorite/athlete/{athleteId}`
                .replace(`{${"athleteId"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteFavoriteEvent', 'eventId', eventId)
            const localVarPath = `/api/favorite/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteAthletes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favorite/athlete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteEvents: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favorite/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FavoriteControllerApi - functional programming interface
 * @export
 */
export const FavoriteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FavoriteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoriteAthlete(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteAthlete(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.addFavoriteAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoriteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.addFavoriteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteAthlete(athleteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteAthlete(athleteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.deleteFavoriteAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.deleteFavoriteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteAthletes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AthleteSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteAthletes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.getFavoriteAthletes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteEvents(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteEvents(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FavoriteControllerApi.getFavoriteEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FavoriteControllerApi - factory interface
 * @export
 */
export const FavoriteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FavoriteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteAthlete(athleteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.addFavoriteAthlete(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.addFavoriteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} athleteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteAthlete(athleteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFavoriteAthlete(athleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFavoriteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteAthletes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<AthleteSummary>> {
            return localVarFp.getFavoriteAthletes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteEvents(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<EventSummary>> {
            return localVarFp.getFavoriteEvents(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FavoriteControllerApi - object-oriented interface
 * @export
 * @class FavoriteControllerApi
 * @extends {BaseAPI}
 */
export class FavoriteControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public addFavoriteAthlete(athleteId: number, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).addFavoriteAthlete(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public addFavoriteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).addFavoriteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} athleteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public deleteFavoriteAthlete(athleteId: number, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).deleteFavoriteAthlete(athleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public deleteFavoriteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).deleteFavoriteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public getFavoriteAthletes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).getFavoriteAthletes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteControllerApi
     */
    public getFavoriteEvents(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FavoriteControllerApiFp(this.configuration).getFavoriteEvents(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeedControllerApi - axios parameter creator
 * @export
 */
export const FeedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [lastCreatedAt] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeed: async (lastCreatedAt?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastCreatedAt !== undefined) {
                localVarQueryParameter['lastCreatedAt'] = (lastCreatedAt as any instanceof Date) ?
                    (lastCreatedAt as any).toISOString() :
                    lastCreatedAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedControllerApi - functional programming interface
 * @export
 */
export const FeedControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [lastCreatedAt] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeed(lastCreatedAt?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeed(lastCreatedAt, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedControllerApi.getFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeedControllerApi - factory interface
 * @export
 */
export const FeedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [lastCreatedAt] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeed(lastCreatedAt?: string, limit?: number, options?: any): AxiosPromise<Array<FeedItem>> {
            return localVarFp.getFeed(lastCreatedAt, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedControllerApi - object-oriented interface
 * @export
 * @class FeedControllerApi
 * @extends {BaseAPI}
 */
export class FeedControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [lastCreatedAt] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getFeed(lastCreatedAt?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return FeedControllerApiFp(this.configuration).getFeed(lastCreatedAt, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationControllerApi - axios parameter creator
 * @export
 */
export const LocationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns location name by position
         * @param {Position} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverse: async (position: Position, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'position' is not null or undefined
            assertParamExists('reverse', 'position', position)
            const localVarPath = `/api/location/reverse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(position, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationControllerApi - functional programming interface
 * @export
 */
export const LocationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns location name by position
         * @param {Position} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reverse(position: Position, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReverseLocationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reverse(position, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationControllerApi.reverse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationControllerApi - factory interface
 * @export
 */
export const LocationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns location name by position
         * @param {Position} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverse(position: Position, options?: any): AxiosPromise<ReverseLocationResult> {
            return localVarFp.reverse(position, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationControllerApi - object-oriented interface
 * @export
 * @class LocationControllerApi
 * @extends {BaseAPI}
 */
export class LocationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Returns location name by position
     * @param {Position} position 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public reverse(position: Position, options?: RawAxiosRequestConfig) {
        return LocationControllerApiFp(this.configuration).reverse(position, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationAdminControllerApi - axios parameter creator
 * @export
 */
export const OrganizationAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteOrganization: async (organizationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminDeleteOrganization', 'organizationId', organizationId)
            const localVarPath = `/api/admin/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllOrganizations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOrganization: async (organizationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminGetOrganization', 'organizationId', organizationId)
            const localVarPath = `/api/admin/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {AdminUpdateOrganizationModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateOrganizationModerationStatus: async (organizationId: number, moderationStatus: AdminUpdateOrganizationModerationStatusModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminUpdateOrganizationModerationStatus', 'organizationId', organizationId)
            // verify required parameter 'moderationStatus' is not null or undefined
            assertParamExists('adminUpdateOrganizationModerationStatus', 'moderationStatus', moderationStatus)
            const localVarPath = `/api/admin/organization/{organizationId}/status/{moderationStatus}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"moderationStatus"}}`, encodeURIComponent(String(moderationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationAdminControllerApi - functional programming interface
 * @export
 */
export const OrganizationAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteOrganization(organizationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteOrganization(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationAdminControllerApi.adminDeleteOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllOrganizations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationAdminControllerApi.adminGetAllOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOrganization(organizationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOrganization(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationAdminControllerApi.adminGetOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {AdminUpdateOrganizationModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateOrganizationModerationStatus(organizationId: number, moderationStatus: AdminUpdateOrganizationModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateOrganizationModerationStatus(organizationId, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationAdminControllerApi.adminUpdateOrganizationModerationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationAdminControllerApi - factory interface
 * @export
 */
export const OrganizationAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteOrganization(organizationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.adminGetAllOrganizations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOrganization(organizationId: number, options?: any): AxiosPromise<Organization> {
            return localVarFp.adminGetOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {AdminUpdateOrganizationModerationStatusModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateOrganizationModerationStatus(organizationId: number, moderationStatus: AdminUpdateOrganizationModerationStatusModerationStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateOrganizationModerationStatus(organizationId, moderationStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationAdminControllerApi - object-oriented interface
 * @export
 * @class OrganizationAdminControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAdminControllerApi
     */
    public adminDeleteOrganization(organizationId: number, options?: RawAxiosRequestConfig) {
        return OrganizationAdminControllerApiFp(this.configuration).adminDeleteOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAdminControllerApi
     */
    public adminGetAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return OrganizationAdminControllerApiFp(this.configuration).adminGetAllOrganizations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAdminControllerApi
     */
    public adminGetOrganization(organizationId: number, options?: RawAxiosRequestConfig) {
        return OrganizationAdminControllerApiFp(this.configuration).adminGetOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {AdminUpdateOrganizationModerationStatusModerationStatusEnum} moderationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAdminControllerApi
     */
    public adminUpdateOrganizationModerationStatus(organizationId: number, moderationStatus: AdminUpdateOrganizationModerationStatusModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return OrganizationAdminControllerApiFp(this.configuration).adminUpdateOrganizationModerationStatus(organizationId, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminUpdateOrganizationModerationStatusModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminUpdateOrganizationModerationStatusModerationStatusEnum = typeof AdminUpdateOrganizationModerationStatusModerationStatusEnum[keyof typeof AdminUpdateOrganizationModerationStatusModerationStatusEnum];


/**
 * OrganizationControllerApi - axios parameter creator
 * @export
 */
export const OrganizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (organizationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganization', 'organizationId', organizationId)
            const localVarPath = `/api/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (updateOrganizationRequest: UpdateOrganizationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationRequest' is not null or undefined
            assertParamExists('updateOrganization', 'updateOrganizationRequest', updateOrganizationRequest)
            const localVarPath = `/api/organization/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
export const OrganizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(organizationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationControllerApi.getOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationControllerApi.organizationMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(updateOrganizationRequest: UpdateOrganizationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(updateOrganizationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationControllerApi.updateOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationControllerApi - factory interface
 * @export
 */
export const OrganizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(organizationId: number, options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationMe(options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(updateOrganizationRequest: UpdateOrganizationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganization(updateOrganizationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getOrganization(organizationId: number, options?: RawAxiosRequestConfig) {
        return OrganizationControllerApiFp(this.configuration).getOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public organizationMe(options?: RawAxiosRequestConfig) {
        return OrganizationControllerApiFp(this.configuration).organizationMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationRequest} updateOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public updateOrganization(updateOrganizationRequest: UpdateOrganizationRequest, options?: RawAxiosRequestConfig) {
        return OrganizationControllerApiFp(this.configuration).updateOrganization(updateOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SportControllerApi - axios parameter creator
 * @export
 */
export const SportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSports: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SportControllerApi - functional programming interface
 * @export
 */
export const SportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSports(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSports(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SportControllerApi.getAllSports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SportControllerApi - factory interface
 * @export
 */
export const SportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSports(options?: any): AxiosPromise<Array<Sport>> {
            return localVarFp.getAllSports(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SportControllerApi - object-oriented interface
 * @export
 * @class SportControllerApi
 * @extends {BaseAPI}
 */
export class SportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportControllerApi
     */
    public getAllSports(options?: RawAxiosRequestConfig) {
        return SportControllerApiFp(this.configuration).getAllSports(options).then((request) => request(this.axios, this.basePath));
    }
}



