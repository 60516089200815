"use client";

import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { cn } from "@/utils/tailwind";
import { ComponentProps, FC, HTMLAttributes, ReactElement } from "react";

interface ButtonWithDialogProps extends HTMLAttributes<HTMLElement> {
  buttonContent: string | ReactElement;
  buttonVariant: ComponentProps<typeof Button>["variant"];
  buttonSize?: ComponentProps<typeof Button>["size"];
  dialogTitle: string;
  dialogDescription?: string;
  actionElement?: ReactElement[];
}

const ButtonWithDialog: FC<ButtonWithDialogProps> = ({
  className,
  buttonContent,
  buttonVariant,
  buttonSize = "md",
  dialogTitle,
  dialogDescription,
  actionElement: actionElements,
  ...other
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={buttonVariant} size={buttonSize} className={cn(className)} {...other}>
          {buttonContent}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[32rem] md:max-w-[44rem]">
        <DialogHeader>
          <DialogTitle className="h4">{dialogTitle}</DialogTitle>
          {dialogDescription && <DialogDescription>{dialogDescription}</DialogDescription>}
        </DialogHeader>
        <DialogFooter className="flex">
          {actionElements?.map((element) => <DialogClose asChild>{element}</DialogClose>)}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ButtonWithDialog;
