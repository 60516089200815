import { OrganizationControllerApi } from "@/api/generated";
import { Organization } from "@/types/organization";
import { useUnit } from "effector-react";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { getOrganizationFx, getOrganizationMeFx, updateOrganizationFx } from "./effects";

export const useOrganizationApi = () => {
  const httpClient = useHttpClient();
  const controller = new OrganizationControllerApi(undefined, undefined, httpClient);

  const getOrganizationEffect = useUnit(getOrganizationFx);
  const getOrganizationMeEffect = useUnit(getOrganizationMeFx);
  const updateOrganizationEffect = useUnit(updateOrganizationFx);

  const getOrganization = async (organizationId: number) =>
    await getOrganizationEffect({ controller, payload: { organizationId } });

  const getOrganizationMe = async () => await getOrganizationMeEffect({ controller });

  const updateOrganization = async (organization: Organization) =>
    await updateOrganizationEffect({ controller, payload: { ...organization } });

  return {
    getOrganization,
    getOrganizationMe,
    updateOrganization,
  };
};
