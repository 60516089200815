import { Locale } from "@/types/locale";
import { createEvent, createStore } from "effector";

const $locale = createStore<Locale>("ru");

const setLocale = createEvent<Locale>();

$locale.on(setLocale, (_, locale) => locale);

export { $locale, setLocale };
