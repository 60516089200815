import React from "react";

export const InstagramIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M15.0002 1.2002C7.38577 1.2002 1.2002 7.38577 1.2002 15.0002C1.2002 22.6146 7.38577 28.8002 15.0002 28.8002C22.6146 28.8002 28.8002 22.6146 28.8002 15.0002C28.8002 7.38577 22.6146 1.2002 15.0002 1.2002ZM15.0002 2.4002C21.9661 2.4002 27.6002 8.0343 27.6002 15.0002C27.6002 21.9661 21.9661 27.6002 15.0002 27.6002C8.0343 27.6002 2.4002 21.9661 2.4002 15.0002C2.4002 8.0343 8.0343 2.4002 15.0002 2.4002Z"
        fill="currentColor"
      />
      <path
        d="M11.7601 7.08008C9.17977 7.08008 7.08008 9.17977 7.08008 11.7601V18.2401C7.08008 20.8204 9.17977 22.9201 11.7601 22.9201H18.2401C20.8204 22.9201 22.9201 20.8204 22.9201 18.2401V11.7601C22.9201 9.17977 20.8204 7.08008 18.2401 7.08008H11.7601ZM11.7601 7.80008H18.2401C20.4312 7.80008 22.2001 9.56894 22.2001 11.7601V18.2401C22.2001 20.4312 20.4312 22.2001 18.2401 22.2001H11.7601C9.56894 22.2001 7.80008 20.4312 7.80008 18.2401V11.7601C7.80008 9.56894 9.56894 7.80008 11.7601 7.80008ZM19.3201 9.96008C19.1291 9.96008 18.946 10.0359 18.811 10.171C18.6759 10.306 18.6001 10.4891 18.6001 10.6801C18.6001 10.871 18.6759 11.0542 18.811 11.1892C18.946 11.3242 19.1291 11.4001 19.3201 11.4001C19.511 11.4001 19.6942 11.3242 19.8292 11.1892C19.9642 11.0542 20.0401 10.871 20.0401 10.6801C20.0401 10.4891 19.9642 10.306 19.8292 10.171C19.6942 10.0359 19.511 9.96008 19.3201 9.96008ZM15.0001 11.0401C12.8173 11.0401 11.0401 12.8173 11.0401 15.0001C11.0401 17.1829 12.8173 18.9601 15.0001 18.9601C17.1829 18.9601 18.9601 17.1829 18.9601 15.0001C18.9601 12.8173 17.1829 11.0401 15.0001 11.0401ZM15.0001 11.7601C16.7937 11.7601 18.2401 13.2064 18.2401 15.0001C18.2401 16.7937 16.7937 18.2401 15.0001 18.2401C13.2064 18.2401 11.7601 16.7937 11.7601 15.0001C11.7601 13.2064 13.2064 11.7601 15.0001 11.7601Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  ),
);
