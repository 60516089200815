import { LocationControllerApi, Position } from "@/api/generated";
import { useUnit } from "effector-react";
import { useHttpClient } from "@/hooks/http-client/use-http-client";
import { getLocationNameFx } from "./effects";

export const useLocationApi = () => {
  const httpClient = useHttpClient();
  const controller = new LocationControllerApi(undefined, undefined, httpClient);

  const getLocationNameEffect = useUnit(getLocationNameFx);

  const getLocationName = async (position: Position) => await getLocationNameEffect({ controller, payload: position });

  return {
    getLocationName,
  };
};
