import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { Textarea } from "@/ui-kit/text-area";
import { FC } from "react";

type AthleteSendMessageDialogProps = {
  disabled?: boolean;
};

export const AthleteSendMessageDialog: FC<AthleteSendMessageDialogProps> = ({ disabled = false }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled}>Написать</Button>
      </DialogTrigger>
      <DialogContent className="px-14 pt-10 pb-6 max-w-[32rem] md:max-w-[44rem]">
        <DialogHeader className="mt-8 mb-16">
          <DialogTitle className="h4 text-center">Отправить пользователю сообщение</DialogTitle>
        </DialogHeader>
        <Textarea placeholder="Сообщение" />
        <DialogFooter className="flex gap-2 md:justify-center">
          <DialogClose asChild>
            <Button>Отправить</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outlineMain">Отмена</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
