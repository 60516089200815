import { AthleteControllerApi, AthleteRoleEnum } from "@/api/generated";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { createStore, sample } from "effector";
import { $httpClient } from '../http-client/store';
import { $sports } from "../sport/store";
import { getAthleteFx } from "./effects";
import { $athletes, $athletesLoading, $athletesPageMeta, $athletesSearchParams } from "./list/store";
import { addAthleteToTeamFx, removeAthleteFromTeamFx } from './team';

const ATHLETE_INITIAL_STATE: AthleteSummaryCard = {
  id: 0,
  firstName: "",
  lastName: "",
  avatar: "",
  role: AccountRole.ATHLETE,
  location: {
    name: "",
    position: {
      latitude: 0,
      longitude: 0,
    },
  },
  isCoach: false,
  rating: 0,
  achievements: [],
  sports: [],
  sportIds: []
};

const $athlete = createStore<AthleteSummaryCard>(ATHLETE_INITIAL_STATE);
const $athleteLoading = createStore<boolean>(false);

$athleteLoading.on(getAthleteFx.pending, (_, pending) => pending);

sample({
  clock: getAthleteFx.doneData,
  source: $sports,
  fn: (sports, data) =>
    ({
      ...data,
      sports: data.sportIds.map((sportId) => sports.get(sportId)?.name || ""),
      role: data.isCoach ? AthleteRoleEnum.Coach : AthleteRoleEnum.Athlete,
      rating: 4,
      achievements: ["Bronze", "Silver", "Gold", "Regular"],
    }) as AthleteSummaryCard, // Probably here is typing mistake, need to verify that
  target: $athlete,
});

sample({
  clock: [addAthleteToTeamFx.doneData, removeAthleteFromTeamFx.doneData],
  source: { httpClient: $httpClient, athlete: $athlete },
  fn: ({ httpClient, athlete }) => {
    // The same code goes in api layer, think how to avoid duplication
    const controller = new AthleteControllerApi(undefined, undefined, httpClient);

    return { controller, payload: { athleteId: athlete.id } }
  },
  target: getAthleteFx
})

// TODO connect $athleteSearchParams changes with searchAthletesFx

export { $athlete, $athleteLoading, $athletes, $athletesLoading, $athletesPageMeta, $athletesSearchParams };
