import { AvatarControllerApiAxiosParamCreator, GetAvatarCategoryEnum } from "@/api/generated";

const urlParamsCreator = AvatarControllerApiAxiosParamCreator();

const getAvatarByCategoryAndIdUrl = async (category: GetAvatarCategoryEnum, id: number): Promise<string> => {
  if (!urlParamsCreator) {
    console.log("URL Params Creator not initialized");
    return "";
  }
  const urlParams = await urlParamsCreator.getAvatar(category, id);
  return process.env.REACT_APP_BASE_URL + urlParams.url;
};

export { getAvatarByCategoryAndIdUrl };
