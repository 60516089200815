import { FacebookLoginIcon } from "@/icons/facebook-login";
import { GoogleLoginIcon } from "@/icons/google-login";
import { VkLoginIcon } from "@/icons/vk-login";
import { Button } from "@/ui-kit/button";

export const AuthProviders = () => {
  return (
    <div className="flex gap-8 justify-center">
      <Button variant="ghost" size="icon">
        <GoogleLoginIcon />
      </Button>
      <Button variant="ghost" size="icon">
        <FacebookLoginIcon />
      </Button>
      <Button variant="ghost" size="icon">
        <VkLoginIcon />
      </Button>
    </div>
  );
};
