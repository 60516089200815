import { AthleteSummary } from "@/components/athlete-summary";
import { useAthlete } from "@/hooks/athlete/use-athlete";
import { useAthleteApi } from "@/hooks/athlete/use-athlete-api";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Athlete = () => {
  const { id } = useParams();
  const data = useAthlete();
  const { getAthlete } = useAthleteApi();

  useEffect(() => {
    if (id) {
      getAthlete(parseInt(id));
    }
  }, [id]);

  return (
    <div className="bg-white w-full content-padding-64">
      <AthleteSummary data={data} isLoading={false} />
    </div>
  );
};

export default Athlete;
