import { AxiosInstance } from "axios";
import { createStore } from "effector";

import axios, { AxiosError } from "axios";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (err: AxiosError<{ message: string }>) => {
    console.log("err: ", err);
    try {
      const token = localStorage.getItem("accessToken");

      if (token) {
        if (
          err.response?.status === 403 ||
          err.response?.status === 401 ||
          (err.response?.status === 500 && err.response.data?.message?.includes("JWT expired")) ||
          err.code === "ERR_NETWORK"
        ) {
          localStorage.removeItem("accessToken");
          window.location.reload();
        }
      }
    } catch (e) {
      console.log("Something went wrong: ", e);
    } finally {
      // Throw error here, to handle it upper in promise chain e.g in useEvent hook
      throw err;
    }
  },
);

const $httpClient = createStore<AxiosInstance>(axiosInstance);

export { $httpClient };
