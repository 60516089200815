import React from "react";

export const GoogleLoginIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g clipPath="url(#clip0_1589_7377)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.91366 22.9995C9.91366 21.5056 10.1616 20.0732 10.6047 18.7298L2.85302 12.8105C1.34221 15.8778 0.491211 19.3343 0.491211 22.9995C0.491211 26.6619 1.34149 30.1159 2.84979 33.1814L10.5972 27.2506C10.1584 25.9134 9.91366 24.4863 9.91366 22.9995Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5234 9.40916C26.7689 9.40916 29.7004 10.5592 32.0036 12.4408L38.7038 5.75C34.6209 2.19542 29.3863 0 23.5234 0C14.4212 0 6.5983 5.20519 2.85254 12.811L10.6039 18.7303C12.39 13.3087 17.4813 9.40916 23.5234 9.40916Z"
          fill="#EA4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5234 36.5906C17.4816 36.5906 12.3904 32.6911 10.6043 27.2695L2.85254 33.1877C6.5983 40.7946 14.4212 45.9998 23.5234 45.9998C29.1412 45.9998 34.5048 44.0049 38.5302 40.2674L31.1724 34.5792C29.0963 35.887 26.4818 36.5906 23.5234 36.5906Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.5089 23C45.5089 21.6409 45.2994 20.1772 44.9853 18.8184H23.5234V27.7046H35.8773C35.2595 30.7345 33.5784 33.0636 31.1724 34.5795L38.5302 40.2677C42.7586 36.3433 45.5089 30.497 45.5089 23Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_7377">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
);
