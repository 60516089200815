import { EmptyListPlaceholder } from "@/components/list/empty-list-placeholder";
import { EventList } from "@/components/list/event-list";
import { Pagination } from "@/components/pagination";
import { useEvents, useEventsPageMeta, useEventsResetPageMeta, useEventsSetPageMeta } from "@/hooks/event/list";
import { useFavoriteEventsGate } from "@/hooks/event/list/use-favorite-events-gate";
import { useFavoriteApi } from "@/hooks/favorite";
import { useEffect } from "react";

const FILTER_OPTIONS = [
  { value: "all", label: "Избранные" },
  { value: "created", label: "Добавленные мной" },
  { value: "joined", label: "Учавствую" },
];

const ProfileEvents = () => {
  const { data, isLoading } = useEvents();
  const { pageNumber, pagesNum, pageSize } = useEventsPageMeta();
  const { setPage } = useEventsSetPageMeta();
  const { getFavoriteEvents } = useFavoriteApi();
  const resetPageMeta = useEventsResetPageMeta();
  const isNoData = data.length === 0 && pageNumber === 1;

  useFavoriteEventsGate();

  useEffect(() => {
    getFavoriteEvents({ page: pageNumber, size: pageSize, searchParams: undefined });

    return () => resetPageMeta();
  }, []);

  const filterChangeHandler = (value: string) => {
    console.log(value);
  };

  return (
    <div className="mt-6 -mx-10">
      {isNoData ? (
        <EmptyListPlaceholder title="У вас пока нет добавленных событий" buttonTitle="Поиск события" link="/events" />
      ) : (
        <EventList
          data={data}
          isLoading={isLoading}
          filterOptions={FILTER_OPTIONS}
          onFilterChange={filterChangeHandler}
        />
      )}
      {pagesNum > 1 && <Pagination total={pagesNum} onChange={setPage} current={pageNumber} />}
    </div>
  );
};

export default ProfileEvents;
