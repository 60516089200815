import { SelectContent, SelectItem, SelectTrigger, SelectValue, Select as UIKitSelect } from "@/ui-kit/select";
import { cn } from "@/utils/tailwind";
import { FC } from "react";

type Option = {
  value: string;
  label: string;
};

type SelectProps = {
  placeholder: string;
  options: Option[];
  defaultValue?: string;
  onValueChange: (value: string) => void;
  className?: string;
};

export const Select: FC<SelectProps> = ({ onValueChange, defaultValue, options, placeholder, className }) => {
  return (
    <UIKitSelect onValueChange={onValueChange} defaultValue={defaultValue}>
      <SelectTrigger className={cn("w-[180px] ", className)}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </UIKitSelect>
  );
};
