import { Image } from "@/icons/image";
import { LikeIcon } from "@/icons/like";
import { LocationIcon } from "@/icons/location";
import { Sport } from "@/types/sport";
import { Button } from "@/ui-kit/button";
import { Card } from "@/ui-kit/card";
import { SPORT_ICON_SHADOW_MAP } from "@/utils/icons";
import { cn } from "@/utils/tailwind";
import { FC } from "react";
import { Link } from "react-router-dom";

type EventCardProps = {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  location: string;
  sports: string[];
  buttonText: string;
  isInFavorite: boolean;
  className?: string;
  onClickHandler?: () => void;
  onLikeHandler?: () => void;
};

export const AthleteCard: FC<EventCardProps> = ({
  id,
  avatar,
  firstName,
  lastName,
  location,
  sports,
  isInFavorite,
  buttonText,
  className,
  onLikeHandler,
}) => {
  const sportIcons = sports.map((sport) => SPORT_ICON_SHADOW_MAP[sport as Sport] || null);

  return (
    <Card
      className={cn(
        "min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl overflow-hidden group",
        className,
      )}
    >
      <div className="relative flex-1">
        <Image src={avatar} alt="Event Card" className="w-full max-w-unset h-full object-cover" />
        <div className="absolute top-2 right-2">{sportIcons.map((Icon, key) => Icon && <Icon key={key} />)}</div>
        <div className="absolute bottom-0 w-full">
          <section className="z-10 flex flex-col items-center flex-1 p-4 -mt-20 transition-colors bg-gradient-to-b from-transparent to-black from-5% to-36% from-36% to-58% to-72% group-hover:bg-gradient-to-t group-hover:gradient-blue-stops-card">
            <h6 className="h6 text-white mt-12 flex flex-col text-center">
              <span>{firstName}</span>
              <span>{lastName}</span>
            </h6>
            <p className="ft1 flex mt-2 text-white">
              <LocationIcon />
              <span className="ml-1">{location}</span>
            </p>
            <div className="mt-auto gap-2 flex items-center justify-between flex-wrap-reverse">
              <Button className="mt-4" asChild>
                <Link to={`/athletes/${id}`}>{buttonText}</Link>
              </Button>
              <Button className="mt-4 hover:bg-transparent" variant="ghost" size="icon" onClick={onLikeHandler}>
                <LikeIcon
                  className={cn(
                    "transition-colors",
                    isInFavorite ? "hover:fill-transparent fill-black" : "hover:fill-black",
                  )}
                />
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Card>
  );
};
