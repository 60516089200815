import { useAccountExtended } from "@/hooks/account";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { OrganizationSummaryCard } from "@/types/organization";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { EditAthleteMainProfileForm } from "../forms/edit-athlete-main-profile-form";
import { EditOrganizationMainProfileForm } from "../forms/edit-organization-main-profile-form";

export const MainProfileEditCard = () => {
  const { data: account } = useAccountExtended();

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] my-8 mx-auto rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать профиль</CardTitle>
      </CardHeader>
      <CardContent>
        {account ? (
          account.role === AccountRole.ORGANIZATION ? (
            <EditOrganizationMainProfileForm defaultValues={account as OrganizationSummaryCard} />
          ) : (
            <EditAthleteMainProfileForm defaultValues={account as AthleteSummaryCard} />
          )
        ) : (
          <div>Данные не загружены</div>
        )}
      </CardContent>
    </Card>
  );
};
