import { CheckboxWithText } from "@/components/checkbox-with-text";
import { Input } from "@/components/rhf/input";
import { useAccountApi } from "@/hooks/account/use-account-api";
import { LockIcon } from "@/icons/lock";
import { MessageIcon } from "@/icons/message";
import { loginSchema } from "@/schemas/login.schema";
import { LoginFormData } from "@/types/login";
import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosResponse } from "axios";
import { FC, HTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface LoginFormProps extends HTMLAttributes<HTMLElement> {
  callbackUrl?: string;
  error?: string;
}

const LoginForm: FC<LoginFormProps> = () => {
  const { login } = useAccountApi();
  const navigate = useNavigate();
  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (values: LoginFormData) => {
    try {
      await login(values);
      navigate("/events");
    } catch (error: unknown) {
      if ((error as AxiosResponse)?.status === 403) {
        form.setError("root", { message: "Неверный логин или пароль" });
      } else {
        form.setError("root", { message: "Произошла ошибка, попробуйте позже или обратитесь к администратору" });
      }
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          name="email"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Email"
          startAdornment={<MessageIcon className="text-primary" />}
        />
        <Input
          withVisibilityToggle
          name="password"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Введите пароль"
          startAdornment={<LockIcon className="text-primary" />}
        />
        <div className="flex justify-between ft1">
          <CheckboxWithText children="Запомнить меня" name="remember" />
          <Button type="button" variant="ghost" size="sm" className="ft1">
            Забыли пароль?
          </Button>
        </div>
        {form.formState.errors.root && (
          <FormMessage className="self-center">{form.formState.errors.root.message}</FormMessage>
        )}
        <div className="flex justify-center">
          <Button type="submit">Войти</Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
