import { EventCreatedDialog } from "@/components/dialog";
import { CreateEventForm } from "@/components/forms/create-event";
import { Tabs } from "@/components/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { useState } from "react";

export const CreateEventCard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [eventId, setEventId] = useState<number | null>(null);

  const onSubmitHandler = (eventId?: number) => {
    setEventId(eventId || null);
    setOpenDialog(true);
  };

  const tabs = [
    {
      value: "new",
      label: "Новое",
      content: <CreateEventForm onSubmitCallback={onSubmitHandler} />,
    },
    {
      value: "external",
      label: "Существующее",
      content: <CreateEventForm external onSubmitCallback={onSubmitHandler} />,
    },
  ];

  return (
    <>
      <EventCreatedDialog open={openDialog} onOpenChange={setOpenDialog} eventId={eventId} />
      <Card className="min-w-[300px] w-screen max-w-[500px] sm:m-8 rounded-none sm:rounded-2xl">
        <CardHeader>
          <CardTitle>Создать событие</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="new" tabs={tabs} />
        </CardContent>
      </Card>
    </>
  );
};
