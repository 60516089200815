import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

const provider = new OpenStreetMapProvider();

const LocationSearchControl = (props: any) => {
  const map = useMap();

  useEffect(() => {
    const searchControl = GeoSearchControl({
      provider: provider,
      showMarker: false,
      notFoundMessage: "К сожалению адрес не найден, попробуйте ещё раз",
      ...props,
    });

    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
  }, []);

  return null;
};
export default LocationSearchControl;
