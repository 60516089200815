import { ERROR_MESSAGE } from "@/consts/errors";
import { cutBase64Header, notEmpty } from "@/schemas/utils";
import isISODate from "is-iso-date";
import { z } from "zod";

const eventSchema = z.object({
  id: z.number(),
  name: z.string().pipe(notEmpty),
  sportId: z.number(),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  date: z.string().refine(isISODate, { message: ERROR_MESSAGE.invalidDate }).default(""),
  description: z.string().optional(),
  avatar: z.string().transform((val) => val.startsWith("data:image/") ? cutBase64Header(val) : val).optional(),
  isPublic: z.boolean(),
  distance: z.coerce.number().optional(),
  socialMedia: z.array(z.string()).optional(),
  format: z.string().optional(),
  training: z.string().optional(),
});

const externalEventSchema = eventSchema.extend({
  externalEventUrl: z.string(),
  externalRegistrationUrl: z.string(),
});

const eventSearchSchema = z.object({
  name: z.string().optional(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .optional(),
  sportIds: z.array(z.number()).optional(),
  date: z.string().refine(isISODate, { message: ERROR_MESSAGE.invalidDate }).optional(),
  distance: z.coerce
    .number()
    .refine((val) => val >= 0, { message: ERROR_MESSAGE.invalidDistance })
    .optional(),
});

export { eventSchema, eventSearchSchema, externalEventSchema };

