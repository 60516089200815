import React from "react";

export const StarIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      stroke="#FF7712"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M16.8415 3.43737L20.1315 10.2031C20.2685 10.4841 20.5325 10.6788 20.8385 10.7243L28.1955 11.8093C28.9655 11.9226 29.2725 12.8827 28.7155 13.4339L23.3915 18.7C23.1705 18.919 23.0695 19.2338 23.1215 19.5428L24.3785 26.979C24.5095 27.7569 23.7055 28.3507 23.0165 27.9826L16.4365 24.4718C16.1635 24.3256 15.8365 24.3256 15.5635 24.4718L8.98352 27.9826C8.29552 28.3497 7.49052 27.7569 7.62152 26.979L8.87852 19.5428C8.93052 19.2338 8.82952 18.919 8.60852 18.7L3.28452 13.4339C2.72752 12.8827 3.03452 11.9226 3.80452 11.8093L11.1615 10.7243C11.4675 10.6788 11.7315 10.4841 11.8685 10.2031L15.1585 3.43737C15.5025 2.72921 16.4965 2.72921 16.8415 3.43737Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
