import { EventControllerApi, EventInfo, GetAvatarCategoryEnum } from "@/api/generated";
import { getAvatarByCategoryAndIdUrl } from "@/hooks/avatar";
import { WithAvatar } from '@/types/avatar';
import { RequestPayload } from "@/types/common";
import { Event, EventRequestParams, ExternalEvent, NewEvent, NewExternalEvent } from "@/types/event";
import { createEffect } from "effector";

type GetEvent = (params: RequestPayload<EventControllerApi, EventRequestParams>) => Promise<WithAvatar<EventInfo>>;
type CreateEvent = (params: RequestPayload<EventControllerApi, NewEvent>) => Promise<WithAvatar<EventInfo>>;
type CreateExternalEvent = (
  params: RequestPayload<EventControllerApi, NewExternalEvent>,
) => Promise<WithAvatar<EventInfo>>;
type UpdateEvent = (params: RequestPayload<EventControllerApi, Event>) => Promise<WithAvatar<EventInfo>>;
type UpdateExternalEvent = (
  params: RequestPayload<EventControllerApi, ExternalEvent>,
) => Promise<WithAvatar<EventInfo>>;
type DeleteEvent = (params: RequestPayload<EventControllerApi, EventRequestParams>) => Promise<void>;

const getEventFx = createEffect<GetEvent>(async ({ controller, payload }) => {
  try {
    const response = await controller.getEvent(payload.eventId);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, payload.eventId);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in getAthleteFx", error);

    throw error;
  }
});

const createEventFx = createEffect<CreateEvent>(async ({ controller, payload }) => {
  try {
    // will be changed on backend 
    const response = await controller.createEvent(payload);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in createEventFx", error);
    throw error;
  }
});

const createExternalEventFx = createEffect<CreateExternalEvent>(async ({ controller, payload }) => {
  try {
    const response = await controller.createExternalEvent(payload);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in createExternalEventFx", error);
    throw error;
  }
});

const updateEventFx = createEffect<UpdateEvent>(async ({ controller, payload }) => {
  try {
    const response = await controller.updateEvent(payload.id, payload);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in updateEventFx", error);
    throw error;
  }
});

const updateExternalEventFx = createEffect<UpdateExternalEvent>(async ({ controller, payload }) => {
  try {
    // will be changed on backend
    const response = await controller.updateEvent(payload.id, payload);
    const avatarUrl = await getAvatarByCategoryAndIdUrl(GetAvatarCategoryEnum.Event, response.data.id);

    return { ...response.data, avatar: avatarUrl };
  } catch (error) {
    console.log("Error in updateExternalEventFx", error);
    throw error;
  }
});

const deleteEventFx = createEffect<DeleteEvent>(async ({ controller, payload }) => {
  try {
    await controller.deleteEvent(payload.eventId);
  } catch (error) {
    console.log("Error in deleteEventFx", error);
    throw error;
  }
});

export {
  createEventFx,
  createExternalEventFx,
  deleteEventFx,
  getEventFx,
  updateEventFx,
  updateExternalEventFx
};

