import { Skeleton } from "@/ui-kit/skeleton";
import { FC } from "react";

type SkeletonListProps = {
  length?: number;
};

export const SkeletonList: FC<SkeletonListProps> = ({ length = 5 }) => {
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <Skeleton
          key={index}
          className="min-w-48 w-screen max-w-[282px] mx-1 mt-2 min-h-96 h-screen max-h-[500px] rounded-2xl"
        />
      ))}
    </>
  );
};
