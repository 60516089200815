import { EditEventCard } from "@/components/card/edit-event-card";
import { useEvent, useEventApi } from "@/hooks/event";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EventEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useEvent();
  const { getEvent } = useEventApi();

  useEffect(() => {
    if (id) {
      getEvent(parseInt(id));
    }
  }, [id]);

  return (
    <div className="m-auto flex flex-col my-12 px-4">
      <EditEventCard data={data} isLoading={isLoading} />
    </div>
  );
};

export default EventEdit;
