import React from "react";

export const CloudIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="1509"
      height="655"
      viewBox="0 0 1509 655"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g filter="url(#filter0_d_557_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M866.956 529.837C583.268 512.566 135.294 635.508 44.3872 436.193C-47.7158 234.255 242.087 46.6912 561.211 62.6325C703.618 69.7461 1007.84 95.1663 1138 136C1189 152 1551.43 257.759 1457.01 410.494C1363.55 561.671 1099.31 543.983 866.956 529.837Z"
          fill="url(#paint0_linear_557_2)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M877.747 515.64C584.897 497.889 126.556 649.941 32.902 441.993C-61.9849 231.309 234.277 28.8117 563.71 45.1405C710.718 52.4272 1133.5 162 1160.5 175.5C1171.5 185 1569.13 271.991 1471.5 431.5C1374.87 589.382 1117.6 530.179 877.747 515.64Z"
        stroke="#0B00BE"
      />
      <defs>
        <filter
          id="filter0_d_557_2"
          x="2.05078"
          y="34.6842"
          width="1505.58"
          height="550.013"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="3" />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.458333 0 0 0 0 0.458333 0 0 0 0 0.458333 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_2" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_2" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_557_2"
          x1="730.395"
          y1="587.833"
          x2="773.517"
          y2="78.0873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A37CFF" />
          <stop offset="1" stopColor="#4D0EB4" />
        </linearGradient>
      </defs>
    </svg>
  ),
);
