import React from "react";

export const LikeIcon = React.forwardRef(
  ({ ...other }: React.SVGAttributes<SVGElement>, ref: React.ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="transparent"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_2315_10766)">
        <path
          d="M25.9076 3.39242L25.9011 3.386L25.8946 3.37969C24.2155 1.76055 21.9381 1.31747 19.7938 1.74364C18.0386 2.09247 16.3292 3.02691 15.0004 4.4326C12.0794 1.36155 7.17756 0.426936 4.01903 3.36764L3.99906 3.38623L3.98013 3.40589C0.955627 6.54672 1.35339 11.3795 4.4068 14.3199L4.40676 14.3199L4.41535 14.328L12.8042 22.2175L12.8292 22.2425C13.1737 22.587 13.4877 22.9009 13.7631 23.1228C14.0372 23.3434 14.4508 23.6254 14.9891 23.6254C15.5379 23.6254 15.9485 23.334 16.2172 23.1049C16.4818 22.8794 16.7777 22.5617 17.0963 22.2196L17.1096 22.2053L25.4809 14.3323C25.4817 14.3316 25.4826 14.3308 25.4834 14.33C28.6499 11.3873 29.051 6.5359 25.9076 3.39242Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2315_10766">
          <rect width="30" height="25" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
);
